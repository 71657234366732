import React, { useState } from "react";
import { MdOutlineEmail } from "react-icons/md";
import supabase from "../config/supabaseClient";
import swal from "sweetalert2";

const Feedbackmessage = () => {
  const [showFeedbackBox, setShowFeedbackBox] = useState(false);
  const [feedbackType, setFeedbackType] = useState("suggestion");
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleFeedbackTypeChange = (e) => {
    setFeedbackType(e.target.value);
  };

  const handleFeedbackMessageChange = (e) => {
    setFeedbackMessage(e.target.value);
  };

  const handleSubmit = async () => {
    if (feedbackType && feedbackMessage) {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session:", sessionError);
        return;
      }

      if (!sessionData.session) {
        swal.fire("Please login to submit feedback");
        return;
      }
      const userId = sessionData.session.user.id;
      console.log(userId);

      const { error: feedbackError } = await supabase.from("feedback").insert([
        {
          user_id: userId,
          feedback_type: feedbackType,
          feedback: feedbackMessage,
        },
      ]);
      if (feedbackError) {
        console.error("Error submitting feedback:", feedbackError);
      } else {
        swal.fire({
          title: "Feedback submitted",
          text: `Type: ${feedbackType}\nMessage: ${feedbackMessage}`,
          icon: "success",
        });
      }

      // Reset feedback fields after submission
      setFeedbackType("suggestion");
      setFeedbackMessage("");
      setShowFeedbackBox(false); // Optionally close the feedback box after submission
    } else {
      swal.fire("Please provide feedback");
    }
  };

  return (
    <div className="fixed flex flex-col items-center justify-center bottom-0 right-0 m-4 z-50">
      {" "}
      {/* Changed z value to 50 for highest visibility */}
      <a href="mailto:support@uni-bridge.com">
        <MdOutlineEmail className="w-10 h-10 p-2 text-white text-center rounded-full bg-blue-600 hover:scale-110 transition duration-300" />
      </a>
      <button
        onClick={() => setShowFeedbackBox(true)}
        className="flex items-center font-semibold text-sm bg-white bg-opacity-30 py-1 px-2 my-2 rounded hover:scale-105 transition duration-300"
      >
        Feedback
      </button>
      {showFeedbackBox && (
        <div className="mt-4 p-4 border rounded bg-white shadow-lg min-w-sm">
          <button
            onClick={() => setShowFeedbackBox(false)}
            className="mb-4 flex justify-end font-semibold text-purple-500"
          >
            Close
          </button>
          {/* <div className="flex items-center"> */}
          <label className="mb-2 font-medium text-secondary-200">
            Feedback type
          </label>
          <select
            className="w-full mb-2 p-2 border rounded"
            onChange={handleFeedbackTypeChange}
            placeholder="Select Feedback Type"
          >
            <option value="suggestion">Suggestion</option>
            <option value="issue">Issue</option>
            <option value="compliment">Compliment</option>
          </select>

          <textarea
            placeholder="Your feedback here..."
            className="w-full h-32 p-2 border rounded"
            onChange={handleFeedbackMessageChange}
          />
          <button
            onClick={handleSubmit}
            className="bg-primary text-white px-4 py-2 my-4 rounded-md"
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default Feedbackmessage;
