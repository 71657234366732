import React from "react";
// import cvImage from "../../assets/images/For_CV.jpg";
import CV_canva from "../../assets/images/ForResources/Resources_Cv_Canva.png";
import Tick from "../../assets/images/Tick.svg";
function HowtowriteCV() {
  return (
    <div className=" pt-20  min-h-screen md:p-6">
      <div className="mx-2 p-8 md:mx-12">
        <div className="md:min-h-72 md:my-16 bg-primary rounded-xl  shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 rounded-xl transform translate-y-4 translate-x-4"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white  text-center mb-6 relative z-10">
            HOW TO WRITE A CV
          </h1>
          {/* Section 1: Introduction */}
          <section className="my-8 relative z-10">
            <div className="text-white px-8 md:px-20 text-base md:text-lg font-medium">
              A well-crafted CV (Curriculum Vitae) is your first impression to
              potential universities or employers. It effectively showcases your
              skills, experience, and qualifications and provides a structured
              overview of your professional background. <br />
              <div className="py-6">
                However, a CV varies greatly depending on your vocation. But, we
                highlight a generic CV format, which is a great starting point.
                Remember, there is never only one CV for one person! Let’s see
                what it takes:
              </div>
            </div>
          </section>
        </div>
        {/* Section 3: Tips */}
        <section className="my-8 ">
          <h2 className="text-2xl text-secondary-200 md:text-3xl font-bold  mb-4">
            Steps to Craft an Effective CV
          </h2>
          <ul className="pl-6 text-gray-600 md:w-2/3 mx-auto">
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 1{" "}
                </span>{" "}
                Design a Skeleton CV
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                A skeleton CV is a CV that is drafted at first, and muscles,
                veins, and skin can be added on later! Maintaining a skeleton CV
                is an easy strategy that can sharpen your application skills and
                save you precious time. You can begin creating one immediately
                and keep it regularly updated.
              </p>
            </li>
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 2{" "}
                </span>{" "}
                Crafting with Care
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                Certain important points are how you want your CV to be looked
                at by potential employees. Three factors are responsible for
                crafting the eligibility, the potential and the suitability. It
                is essential to craft a CV, where one should consider the
                employer, not oneself, what job you are looking for, and how you
                suit yourself.
              </p>
            </li>
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 3{" "}
                </span>{" "}
                Evidencing
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                It is important to provide evidence of your experience.
                Quantifying your experience is often necessary rather than just
                providing qualitative evidence. Certain numbers and figures help
                identify prospective employers to gain in-depth knowledge of
                your impact on past roles.
              </p>
            </li>
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 4{" "}
                </span>{" "}
                Not Just Academia or Work
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                Making your CV shine with interpersonal leadership experience,
                teamwork, problem-solving, innovation, creativity, planning, and
                organisation is advisable. These are some traits you may
                highlight to make your CV wholesome. While everybody can brag
                about degrees from a top university, sections like these can
                provide a cutting-edge to application.
              </p>
            </li>
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 5{" "}
                </span>{" "}
                The Digital Age
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                Another important but often ignored fact is your online
                presence. Make yourself visible on LinkedIn, Meta or X. In this
                digital age, employers often look for potential employees and
                applications for online presence and their impact on society.
                The more your profile is befitting, the quicker you may get a
                response.
              </p>
            </li>
            <li className="relative ">
              <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
              <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                  STEP 6{" "}
                </span>{" "}
                Get it all Together
              </div>
              <p className="pl-4 pb-4 md:pl-12">
                Once you have gathered enough evidence from your academic,
                professional and holistic background, it is time to assemble it.
                CVs should be well-organised, prioritised, professional,
                selective, and error-free.{"   "} Remember, you need to tailor
                your CV for each application you make, and do not try to say
                everything; it is just a flavour of your life, not the biography
                itself. <br /> Do not forget to get proofread by as many people
                as possible. While CVs can be primarily two types, chronological
                and skills-based, both should reflect you and yourself.
              </p>
            </li>
          </ul>
        </section>
        {/* Section 2: Key Sections */}
        {/* <section className="mb-8"> */}
        <section className=" md:my-16 mb-8 text-black bg-stone-100 rounded-xl  shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-300 opacity-40 rounded-xl transform -rotate-3 translate-y-2"></div>
          <h2 className="text-xl md:text-3xl text-secondary-200 font-bold  mb-4">
            Key Sections of a CV
          </h2>
          <ul className="list-disc pl-6 text-black">
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className=" w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Personal Information</strong>: Include your name,
                contact details, and optional links to your LinkedIn or
                professional website.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Professional Summary</strong>: A brief statement
                highlighting your career goals or qualifications, tailored to
                the job you are applying for.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Education</strong>: Mention your degrees, institutions,
                and relevant academic achievements.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Work Experience</strong>: List your roles,
                responsibilities, and key achievements in bullet points,
                starting with your most recent job.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Skills</strong>: Highlight both technical and soft
                skills, as well as language proficiency.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Certifications and Achievements</strong>: Add any
                relevant certifications, courses, or awards.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Projects and Publications</strong> (if applicable):
                Include major projects or research papers that add value to your
                profile.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Extracurricular Activities and Leadership</strong>:
                Showcase any leadership roles or community service involvement.
              </div>
            </li>
          </ul>
        </section>

        <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mb-4">
          Sample of CV
        </h2>
        <img
          src={CV_canva}
          alt="CV-image"
          className="px-6 mx-auto flex justify-center"
        />
        {/* Section 4: Templates & Examples */}
        <section className="mb-8">
          <h2 className="text-xl md:text-3xl text-secondary-200 font-bold my-4 ">
            Additional Resources and Links
          </h2>
          <ul className="list-disc list-inside ml-4 space-y-2 text-secondary-600 text-sm md:text-base">
            <li>
              <a
                href="https://www.careers.ox.ac.uk/files/academic-cv-example-careers"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Academic CV Example (source: Oxford Careers)
              </a>
            </li>

            <li>
              <a
                href="https://www.placements.eng.cam.ac.uk/PDF/cv-example"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Industrial Placement CV Example (source:Cambridge)
              </a>
            </li>
            <li>
              <a
                href="https://www.careers.manchester.ac.uk/media/services/careersandemployabilitydivision/careersservice/cvexamples/Example-CV-(Business).pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                BSc Management Student CV at The University of Manchester
              </a>
            </li>
            <li>
              <a
                href="folder_gURU_reosrces/Law_Bachelorette_CV_Vidushi_Khanna.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Law Bachelorette CV
              </a>
            </li>
            <li>
              <a
                href="https://cdn.uconnectlabs.com/wp-content/uploads/sites/74/2019/08/resume-masters-1.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                One Page CV Example
              </a>
            </li>
            <li>
              <a
                href="https://cdn.uconnectlabs.com/wp-content/uploads/sites/74/2020/03/SEAS-MSE-Sample-1.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Example for Engineering and Applied Science (source: Uconnect)
              </a>
            </li>
            <li>
              <a
                href="https://grad.illinois.edu/sites/default/files/pdfs/cvsamples.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                CV Guide and Example (source: University of Illinois)
              </a>
            </li>
            <li>
              <a
                href="https://www.cmu.edu/career/documents/sample-resumes-cover-letters/sample-resumes_scs.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                CV Example (source: Carnegie Mellon University)
              </a>
            </li>
            <li>
              <a
                href="https://students.ubc.ca/sites/students.ubc.ca/files/resume_sample_phd_history.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                PhD History CV Example (source: University of British Columbia)
              </a>
            </li>
            <li>
              <a
                href="https://students.ubc.ca/sites/students.ubc.ca/files/resume_sample_bsc_biology.pdf"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Undergraduate Biology CV Sample (source: UBC)
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=HaC4Fek8jE0"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Video Guide to Writing the Accomplishment Section
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/watch?v=bT-JuTlvR14&t=1s"
                className="text-blue-500"
                target="_blank"
                rel="noopener noreferrer"
              >
                Video Guide from Oxford on Crafting a CV
              </a>
            </li>
          </ul>
        </section>
        {/* Section 5: Final Checks */}
        <section className="mb-8">
          <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mb-4">
            Final Checks
          </h2>
          <ul className="list-disc pl-6 text-gray-600">
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Proofread</strong>: Ensure there are no typos or
                grammatical errors in your CV.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Ask for Feedback</strong>: Get a mentor or peer to
                review your CV and offer suggestions.
              </div>
            </li>
            <li className="flex items-start justify-start my-4">
              <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
              <div>
                <strong>Keep it Updated</strong>: Regularly update your CV as
                you gain new skills and experiences.
              </div>
            </li>
          </ul>
        </section>
        {/* Bonus Section */}
        <section className="mb-8">
          <h2 className="text-xl md:text-3xl text-secondary-200 font-bold  mb-4">
            Bonus Tips
          </h2>
          <p className="text-gray-600">
            Applying for graduate programs? Focus on academic achievements and
            publications. For internships, emphasize relevant coursework and
            projects. Also, be mindful of regional CV requirements—some
            countries may expect different information or formats.
          </p>
        </section>
      </div>
    </div>
  );
}

export default HowtowriteCV;
