// src/useAnalytics.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", "G-RG247XSYLN", {
      page_path: location.pathname,
    });
  }, [location]);
};

export default useAnalytics;
