import React from "react";
import { Link } from "react-router-dom";

function Blankpage() {
  return (
    <div className="h-screen w-screen flex items-center justify-center font-semibold text-lg">
      Please login to continue ...
      <Link to="/login">
        <button className="bg-primary text-white text-sm ml-1 rounded-md px-4 py-2">
          Login
        </button>
      </Link>
    </div>
  );
}

export default Blankpage;
