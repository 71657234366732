import React from "react";
import down_icon from "../../assets/images/ForResources/down_icon.svg";
import cover_letter_sample from "../../assets/images/ForResources/cover_letter _Resources.png";
import Tick from "../../assets/images/Tick.svg";
function Coverletter() {
  return (
    <div className=" pt-20  min-h-screen md:p-6">
      <div className="mx-2 p-8 md:mx-12 text-gray-600">
        <div className="md:min-h-72 md:my-16 bg-primary rounded-xl  shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 rounded-xl transform -rotate-3 translate-y-2"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white  text-center mb-6 relative z-10">
            COVER LETTER
          </h1>
          {/* Section 1: Introduction */}
          <section className="my-8 relative z-10">
            <p className="text-white px-8 text-center mx-auto text-lg font-medium">
              A cover letter is a personalised document submitted alongside a
              resume when applying for a job. It serves as a personal message
              from the candidate, explaining their interest in the position and
              highlighting relevant skills and experiences that align with the
              job description.
            </p>
          </section>
        </div>
        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold  mt-16 mb-6">
          Guidelines for Paragraph Structure
        </h2>
        <ul className="list-inside mb-4  space-y-3">
          <li className="flex items-start justify-start">
            <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
            Remember that employers often won’t read every word in detail.
          </li>
          <li className="flex items-start justify-start">
            <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
            Apply an 'hourglass' format: begin and end the paragraph with the
            most crucial points or keywords, placing supporting details in the
            middle.
          </li>
          <li className="flex items-start justify-start">
            <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
            Start each paragraph with a strong opening sentence that conveys the
            key message, and conclude with a powerful closing sentence that
            reinforces your point and connects it to the job.
          </li>
          <li className="flex items-start justify-start">
            <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
            Even if the employer only skims the first and last lines, they
            should still grasp your main ideas.
          </li>
          <li className="flex items-start justify-start">
            <img src={Tick} alt="Tick" className="w-4 h-4 me-2 mt-1" />
            Avoid starting every paragraph with "I" to maintain variety and
            avoid appearing self-centred.
          </li>
        </ul>
        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold  mt-6 mb-2">
          Body of the Letter
        </h2>
        <p className="mb-4">
          There are various approaches to writing a cover letter, and
          conventions can vary across industries, so it’s important not to rely
          on a single template for all your applications. If you're unsure where
          to begin or writing a cover letter for the first time, a good starting
          point is to follow a standard four-paragraph structure that most
          employers find acceptable:
        </p>

        <div className="space-y-4 flex flex-col">
          <div className="group bg-zinc-200 opacity-90 flex flex-col rounded-xl">
            <button
              className="inline-flex bg-zinc-200 opacity-90 py-4 rounded-xl font-semibold items-center justify-center"
              onClick={() =>
                document
                  .querySelector(".paragraph-1")
                  .classList.toggle("hidden")
              }
            >
              Paragraph 1:{" "}
              <img src={down_icon} alt="down-icon" className="h-6 w-6 ml-2" />
            </button>
            <p className="mb-4 hidden paragraph-1 pl-4 transition-opacity duration-500 ease-in-out">
              In the first paragraph of your cover letter, clearly state the
              position you are applying for and mention where you found the job
              listing. For example, "I am applying for the Marketing Coordinator
              role, which was advertised on your company website." Additionally,
              provide key details about yourself, such as your educational
              background. For instance, "I will graduate from the University of
              Oxford in 2019 with a degree in Engineering."
            </p>{" "}
          </div>
          <div className="bg-zinc-200 opacity-90 flex flex-col rounded-xl">
            <button
              className="inline-flex bg-zinc-200 opacity-90 py-4 rounded-xl font-semibold items-center justify-center"
              onClick={() =>
                document
                  .querySelector(".paragraph-2")
                  .classList.toggle("hidden")
              }
            >
              Paragraph 2:
              <img src={down_icon} alt="down-icon" className="h-6 w-6 ml-2" />
            </button>
            <p className="mb-4 hidden paragraph-2 pl-4 transition-opacity duration-500 ease-in-out">
              In the second paragraph, demonstrate your research by emphasising
              the key skills and experiences most relevant to the role. Choose
              two or three examples of your past successes, focusing on those
              that set you apart from other candidates. Tailor your language to
              match the employer's tone and values.
            </p>{" "}
          </div>
          <div className="bg-zinc-200 opacity-90 flex flex-col rounded-xl">
            <button
              className="inline-flex bg-zinc-200 opacity-90 py-4 rounded-xl font-semibold items-center justify-center"
              onClick={() =>
                document
                  .querySelector(".paragraph-3")
                  .classList.toggle("hidden")
              }
            >
              Paragraph 3:
              <img src={down_icon} alt="down-icon" className="h-6 w-6 ml-2" />
            </button>
            <p className="mb-4 hidden paragraph-3 pl-4 transition-opacity duration-500 ease-in-out">
              In the third paragraph, explain why you are interested in this
              role and want to work for this organization. Highlight how you
              learned about the company and what distinguishes them from their
              competitors, focusing on what appeals to you.
            </p>{" "}
          </div>
          <div className="bg-zinc-200 opacity-90 flex flex-col rounded-xl">
            <button
              className="inline-flex bg-zinc-200 opacity-90 py-4 rounded-xl font-semibold items-center justify-center"
              onClick={() =>
                document
                  .querySelector(".paragraph-4")
                  .classList.toggle("hidden")
              }
            >
              Paragraph 4:
              <img src={down_icon} alt="down-icon" className="h-6 w-6 ml-2" />
            </button>
            <p className="mb-4 hidden paragraph-4 pl-4 transition-opacity duration-500 ease-in-out">
              In the final paragraph, include any practical information the
              employer may need, such as your availability for interviews or
              specific dates you can attend. While it’s common for applicants to
              thank the employer for considering their application or express
              hope for an interview, this isn’t mandatory.
            </p>{" "}
          </div>
        </div>

        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold mt-6 mb-2">
          Cover letter Sample
        </h2>

        <img
          src={cover_letter_sample}
          alt="cover_letter_sample"
          className="px-6 mx-auto flex justify-center items-center"
        />

        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold mt-6 mb-2">
          Additional Cover Letter Links
        </h2>
        <ul className="list-disc list-inside text-sm md:text-base ml-2">
          <li>
            <a
              href="https://docs.google.com/document/d/1X0Xp-k2eJ4xtRf0S3PwjtPE5WTJhZIHC/edit#"
              className="text-blue-500"
            >
              Sample Cover Letter from University of California Berkeley
            </a>
          </li>
          <li>
            <a
              href="https://www.careers.manchester.ac.uk/applicationsinterviews/cl/examplecl/"
              className="text-blue-500"
            >
              Placement Cover Letter from University of Manchester
            </a>
          </li>

          <li>
            <a
              href="https://targetjobs.co.uk/careers-advice/cvs-applications-and-tests/covering-letter-essentials-graduate-vacancies"
              className="text-blue-500"
            >
              Target Jobs Cover Letter Guide
            </a>
          </li>
          <li>
            <a
              href="https://www.prospects.ac.uk/careers-advice/cvs-and-cover-letters/cover-letters"
              className="text-blue-500"
            >
              Prospects Jobs Cover Letter Example
            </a>
          </li>
          <li>
            <a
              href="https://students.ubc.ca/sites/students.ubc.ca/files/cover_letter_sample_bapsci_electrical_engineering.pdf"
              className="text-blue-500"
            >
              Engineering Undergrad Cover Letter Sample
            </a>
          </li>
          <li>
            <a
              href="https://students.ubc.ca/sites/students.ubc.ca/files/cover_letter_sample_stemcell.pdf"
              className="text-blue-500"
            >
              Cover Letter for Scientific Sales Representative at STEMCELL
              Technologies
            </a>
          </li>
          <li>
            <a
              href="https://students.ubc.ca/sites/students.ubc.ca/files/cover_letter_sample_director_of_engagement.pdf"
              className="text-blue-500"
            >
              Director of Engagement Cover Letter Example
            </a>
          </li>
          <li>
            <a
              href="https://hbr.org/2022/05/how-to-write-a-cover-letter-that-sounds-like-you-and-gets-noticed"
              className="text-blue-500"
            >
              Harvard Business Review Cover Letter Tips
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/watch?v=tuOoBPcBjpE"
              className="text-blue-500"
            >
              Oxford University Video on Cover Letter
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Coverletter;
