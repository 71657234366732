import React, { useState, useEffect } from "react";
import swal from "sweetalert2";
import supabase from "../../config/supabaseClient";
import { countries } from "../../data/Countries";
import { Courses } from "../../data/Courses";

function EditExperienceModal({
  userId,
  formData,
  setFormData,
  onClose,
  onSave,
}) {
  const [experienceIDs] = useState(() =>
    Object.freeze(formData.experience.map((exp) => exp.experience_id))
  );

  const [isSaving, setIsSaving] = useState(false);

  const handleArrayChange = (index, field, value, section) => {
    const updatedArray = [...formData[section]];
    if (index !== null && index !== undefined) {
      updatedArray[index][field] = value;
    }
    setFormData((prevData) => ({ ...prevData, [section]: updatedArray }));
  };

  const handleAddField = (section, emptyField) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], emptyField],
    }));
  };

  const handleDeleteField = (index, section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((_, i) => i !== index),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true); // Set loading state to true
    try {
      await updateExperience();
      await onSave();
      swal
        .fire({
          title: "Success",
          text: "Education updated successfully.",
          icon: "success",
        })
        .then(() => {
          window.location.reload();
        });
    } finally {
      setIsSaving(false); // Reset loading state after save operation
    }
  };

  // Update experience
  const updateExperience = async () => {
    const experiences = formData.experience;

    // Filter out experience IDs that do not exist in the current experiences
    const notExistingExperienceIDs = experienceIDs.filter(
      (id) => !experiences.some((exp) => exp.experience_id === id)
    );

    // Check for missing experience IDs and delete them
    for (const expID of notExistingExperienceIDs) {
      const { error } = await supabase
        .from("user_experience")
        .delete()
        .eq("user_id", userId)
        .eq("experience_id", expID);

      if (error) {
        console.error("Error deleting experience:", error);
      }
    }

    for (const exp of experiences) {
      const isEmpty =
        !exp.jobTitle &&
        !exp.company &&
        !exp.country &&
        !exp.areaOfWork &&
        !exp.currentPosition &&
        !exp.workperiod;

      if (!exp.experience_id && !isEmpty) {
        const { error } = await supabase.from("user_experience").insert({
          user_id: userId,
          job_title: exp.jobTitle,
          company: exp.company,
          country: exp.country,
          area_of_work: exp.areaOfWork,
          current_position: exp.currentPosition,
          work_period: exp.workperiod || null,
        });

        if (error) {
          console.error("Error adding experience:", error);
          swal.fire({
            title: "Error",
            text: "Failed to add experience.",
            icon: "error",
          });
        }
      } else {
        // Update existing experience entry
        const { error } = await supabase
          .from("user_experience")
          .update({
            job_title: exp.jobTitle,
            company: exp.company,
            country: exp.country,
            area_of_work: exp.areaOfWork,
            current_position: exp.currentPosition,
            work_period: exp.workperiod,
          })
          .eq("user_id", userId)
          .eq("experience_id", exp.experience_id)
          .single();

        if (error) {
          console.error("Error updating experience:", error);
          swal.fire({
            title: "Error",
            text: "Failed to update experience.",
            icon: "error",
          });
        }
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
      <div className="bg-white p-6 rounded-lg md:w-2/3 lg:w-1/2 mx-4 overflow-y-auto h-[80vh]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-primary">Edit Experience</h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>

        <div className="space-y-2">
          <h3 className="text-xl font-bold">Experience</h3>
          {formData.experience.map((exp, index) => (
            <div key={index} className="space-y-2 border p-4 rounded">
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-semibold">
                  Experience {index + 1}
                </h4>
                <button
                  type="button"
                  onClick={() => handleDeleteField(index, "experience")}
                  className="px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300"
                >
                  Delete Entry
                </button>
              </div>
              <div>
                <label className="block text-gray-700">Job Title</label>
                <input
                  type="text"
                  name="jobTitle"
                  value={exp.jobTitle}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "jobTitle",
                      e.target.value,
                      "experience"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Company</label>
                <input
                  type="text"
                  name="company"
                  value={exp.company}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "company",
                      e.target.value,
                      "experience"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Country</label>
                <input
                  type="text"
                  name="country"
                  value={exp.country}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "country",
                      e.target.value,
                      "experience"
                    )
                  }
                  className="w-full p-2 border rounded"
                  list="country-list"
                />
                <datalist id="country-list">
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div>
                <label className="block text-gray-700">Area of Work</label>
                <input
                  type="text"
                  name="areaOfWork"
                  value={exp.areaOfWork}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "areaOfWork",
                      e.target.value,
                      "experience"
                    )
                  }
                  className="w-full p-2 border rounded"
                  list="courses-list"
                />
                <datalist id="courses-list">
                  {Courses.map((course) => (
                    <option key={course.subject_id} value={course.subjects}>
                      {course.subjects}
                    </option>
                  ))}
                </datalist>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="currentPosition"
                  checked={exp.currentPosition}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "currentPosition",
                      e.target.checked,
                      "experience"
                    )
                  }
                  className="mr-2"
                />
                <label className="text-gray-700">I am working here</label>
              </div>
              <div>
                <label className="block text-gray-700">
                  How long have you worked here? (in months)
                </label>
                <input
                  type="number"
                  name="workperiod"
                  value={exp.workperiod || ""}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "workperiod",
                      e.target.value,
                      "experience"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={() =>
              handleAddField("experience", {
                experience_id: null,
                jobTitle: "",
                company: "",
                country: "",
                areaOfWork: "",
                currentPosition: null,
                workperiod: null,
              })
            }
            className="mt-4 px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
          >
            Add More Experience
          </button>

          <div className="flex justify-end mt-4">
            <button
              onClick={handleSave}
              className="bg-primary text-white px-4 py-2 rounded-md"
              disabled={isSaving} // Disable button when saving
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>

        {/* <div className="space-y-3">
          <div>
            <label className="block text-gray-700">Job Title</label>
            <input
              type="text"
              name="job_title"
              value={formData.experience[currentIndex].job_title}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Company</label>
            <input
              type="text"
              name="company"
              value={formData.experience[currentIndex].company}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={formData.experience[currentIndex].country}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              list="country-list"
            />
            <datalist id="country-list">
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">Area of Work</label>
            <input
              type="text"
              name="areaOfWork"
              value={formData.experience[currentIndex].areaOfWork}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              list="courses-list"
            />
            <datalist id="courses-list">
              {Courses.map((course) => (
                <option key={course.subject_id} value={course.subjects}>
                  {course.subjects}
                </option>
              ))}
            </datalist>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="currentPosition"
              checked={formData.experience[currentIndex].currentPosition}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label className="text-gray-700">I am working here</label>
          </div>
          <div>
            <label className="block text-gray-700">
              How long have you worked here? (in months)
            </label>
            <input
              type="number"
              name="workperiod"
              value={formData.experience[currentIndex].workperiod}
              onChange={handleChange}
              className="w-full p-2 border rounded"
              min="0"
            />
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default EditExperienceModal;
