import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

const ProfessionalBackground = ({
  formData,
  handleArrayChange,
  handleAddField,
  handleDeleteField,
  countries,
  universityList,
  degrees,
  courses,
  type,
}) => (
  <div className="space-y-4">
    {/* Education Section */}
    <div className="space-y-2">
      <h3 className="text-xl font-bold">Education</h3>

      {formData.education.map((edu, index) => (
        <div key={index} className="space-y-2 border p-4 rounded">
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-semibold">Education {index + 1}</h4>
            <button
              type="button"
              onClick={() => handleDeleteField(index, "education")}
              className="px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300"
            >
              Delete Entry
            </button>
          </div>
          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="region"
              placeholder="Search for a Country"
              value={edu.region}
              onChange={(e) =>
                handleArrayChange(index, "region", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
              list="country-list"
            />
            <datalist id="country-list">
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">School or University <span className="text-red-500">*</span></label>
            <input
              type="text"
              name="school"
              value={edu.school}
              onChange={(e) =>
                handleArrayChange(index, "school", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
              list="university-list"
            />
            <datalist id="university-list">
              {universityList.map((university) => (
                <option key={university} value={university}>
                  {university}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">Degree <span className="text-red-500">*</span></label>
            <select
              name="degree"
              value={edu.degree}
              onChange={(e) =>
                handleArrayChange(index, "degree", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
            >
              <option value="">Select Degree</option>
              {degrees.map((degree) => (
                <option key={degree} value={degree}>
                  {degree}
                </option>
              ))}
            </select>
            {/* {edu.degree === "Other" && (
              <input
                type="text"
                name="otherDegree"
                placeholder="Enter your degree"
                value={edu.otherDegree}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "otherDegree",
                    e.target.value,
                    "education"
                  )
                }
              />
            )} */}
          </div>
          <div>
            <label className="block text-gray-700">Field of Study</label>
            <input
              type="text"
              name="fieldOfStudy"
              placeholder="Search the Field"
              value={edu.fieldOfStudy}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "fieldOfStudy",
                  e.target.value,
                  "education"
                )
              }
              className="w-full p-2 border rounded"
              list="courses-list"
            />
            <datalist id="courses-list">
              {courses.map((course) => (
                <option key={course.subject_id} value={course.subjects}>
                  {course.subjects}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">Topic(optional)</label>
            <input
              type="text"
              name="topic"
              placeholder="Provide research topic or any specialisation"
              value={edu.topic}
              onChange={(e) =>
                handleArrayChange(index, "topic", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Funding</label>
            <select
              name="funding"
              value={edu.funding}
              onChange={(e) =>
                handleArrayChange(index, "funding", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
            >
              <option value="">None</option>
              <option value="Partial">Partial</option>
              <option value="Full">Full</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700">
              Scholarship Name (optional)
            </label>
            <input
              type="text"
              name="funding_name"
              placeholder="Enter scholarship name"
              value={edu.funding_name}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "funding_name",
                  e.target.value,
                  "education"
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
          {type === "Mentee" && (
            <div className="">
              <label className="flex items-center text-gray-700 relative">
                CGPA
                <span className="ml-1 text-gray-500 cursor-pointer group relative">
                  <IoIosInformationCircleOutline />
                  <span className="absolute p-2 text-sm bg-white md:min-w-96 text-gray-800 rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100 pointer-events-none">
                    The CGPA should be defined out of 10. If you have
                    percentage, convert on the scale of 10. Please provide your
                    current CGPA if you are studying.
                  </span>
                </span>
              </label>

              <input
                type="number"
                name="cgpa"
                value={edu.CGPA}
                onChange={(e) =>
                  handleArrayChange(index, "CGPA", e.target.value, "education")
                }
                className="w-full p-2 border rounded"
              />
            </div>
          )}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="currentlyStudying"
              checked={edu.currentlyStudying}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "currentlyStudying",
                  e.target.checked,
                  "education"
                )
              }
              className="mr-2"
            />
            <label className="text-gray-700">
              I am currently studying here
            </label>
          </div>
          <div>
            <label className="block text-gray-700">Start Date</label>
            <input
              type="date"
              name="startDate"
              value={edu.startDate}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "startDate",
                  e.target.value,
                  "education"
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">
              End Date (or Expected)
            </label>
            <input
              type="date"
              name="endDate"
              value={edu.endDate}
              onChange={(e) =>
                handleArrayChange(index, "endDate", e.target.value, "education")
              }
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={() =>
          handleAddField("education", {
            region: "",
            school: "",
            degree: "",
            fieldOfStudy: "",
            topic: "",
            funding: "",
            funding_name: "",
            CGPA: "",
            currentlyStudying: false,
            startDate: null,
            endDate: null,
            discipline: "",
          })
        }
        className="mt-4 px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
      >
        Add More Education
      </button>
    </div>

    {/* Experience Section */}
    <div className="space-y-2">
      <h3 className="text-xl font-bold">Experience</h3>
      {formData.experience.map((exp, index) => (
        <div key={index} className="space-y-2 border p-4 rounded">
          <div className="flex items-center justify-between">
            <h4 className="text-lg font-semibold">Experience {index + 1}</h4>
            <button
              type="button"
              onClick={() => handleDeleteField(index, "experience")}
              className="px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300"
            >
              Delete Entry
            </button>
          </div>
          <div>
            <label className="block text-gray-700">Job Title</label>
            <input
              type="text"
              name="jobTitle"
              value={exp.jobTitle}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "jobTitle",
                  e.target.value,
                  "experience"
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Company</label>
            <input
              type="text"
              name="company"
              value={exp.company}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "company",
                  e.target.value,
                  "experience"
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-gray-700">Country</label>
            <input
              type="text"
              name="country"
              value={exp.country}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "country",
                  e.target.value,
                  "experience"
                )
              }
              className="w-full p-2 border rounded"
              list="country-list"
            />
            <datalist id="country-list">
              {countries.map((country) => (
                <option key={country.code} value={country.name}>
                  {country.name}
                </option>
              ))}
            </datalist>
          </div>
          <div>
            <label className="block text-gray-700">Area of Work</label>
            <input
              type="text"
              name="areaOfWork"
              value={exp.areaOfWork}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "areaOfWork",
                  e.target.value,
                  "experience"
                )
              }
              className="w-full p-2 border rounded"
              list="courses-list"
            />
            <datalist id="courses-list">
              {courses.map((course) => (
                <option key={course.subject_id} value={course.subjects}>
                  {course.subjects}
                </option>
              ))}
            </datalist>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              name="currentPosition"
              checked={exp.currentPosition}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "currentPosition",
                  e.target.checked,
                  "experience"
                )
              }
              className="mr-2"
            />
            <label className="text-gray-700">I am working here</label>
          </div>
          <div>
            <label className="block text-gray-700">
              How long have you worked here? (in months)
            </label>
            <input
              type="number"
              name="workperiod"
              value={exp.workperiod || ""}
              onChange={(e) =>
                handleArrayChange(
                  index,
                  "workperiod",
                  e.target.value,
                  "experience"
                )
              }
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={() =>
          handleAddField("experience", {
            jobTitle: "",
            company: "",
            country: "",
            areaOfWork: "",
            currentPosition: false,
            workperiod: null,
          })
        }
        className="mt-4 px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
      >
        Add More Experience
      </button>
    </div>

    {/* Websites Section */}
    <div className="space-y-2">
      <h3 className="text-xl font-bold">Websites</h3>
      <label className="block text-gray-700">
        LinkedIn (optional)
        <input
          type="text"
          name="linkedin"
          value={formData.websites.linkedin}
          onChange={(e) =>
            handleArrayChange(null, "linkedin", e.target.value, "websites")
          }
          className="w-full p-2 border rounded"
        />
      </label>
      <label className="block text-gray-700">
        Research (optional)
        <input
          type="text"
          name="researchurl"
          placeholder="For example: Google Scholar or ResearchGate"
          value={formData.websites.researchurl}
          onChange={(e) =>
            handleArrayChange(null, "researchurl", e.target.value, "websites")
          }
          className="w-full p-2 border rounded"
        />
      </label>
      <label className="block text-gray-700">
        Website (optional)
        <input
          type="text"
          name="website"
          placeholder="Link of your own website"
          value={formData.websites.website}
          onChange={(e) =>
            handleArrayChange(null, "website", e.target.value, "websites")
          }
          className="w-full p-2 border rounded"
        />
      </label>
    </div>
  </div>
);

export default ProfessionalBackground;
