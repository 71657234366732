import React, { useState } from "react";
import supabase from "../config/supabaseClient";
import swal from "sweetalert2";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase.auth.getSession();

    if (error) {
      console.log(error);
    }
    if (!data.session.access_token) {
      setError("Invalid reset link.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    const { error: updateError } = await supabase.auth.updateUser({
      email: email,
      password: newPassword,
    });
    if (updateError) {
      setError(updateError.message);
    } else {
      swal.fire({
        icon: "success",
        title: "Password has been reset successfully!",
      });
    }
  };

  return (
    <div className="pt-24 min-h-lvh mx-auto">
      <h2 className="pt-10 mx-auto my-10 text-2xl font-bold text-center">
        Reset Password
      </h2>
      <form
        className="pt-20 text-center flex flex-col items-center space-y-4"
        onSubmit={handleResetPassword}
      >
        <div className="relative">
          <input
            type="email"
            placeholder="Email Address"
            className="w-64 p-2 mx-4 border border-gray-300 rounded-md"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="relative">
          <input
            type={showNewPassword ? "text" : "password"}
            placeholder="New Password"
            className="w-64 p-2 mx-4 border border-gray-300 rounded-md"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button
            type="button"
            onClick={() => setShowNewPassword(!showNewPassword)}
            className="absolute right-6 top-2 text-gray-500"
          >
            {showNewPassword ? "Hide" : "Show"}
          </button>
        </div>

        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            placeholder="Confirm Password"
            className="w-64 p-2 mx-4 border border-gray-300 rounded-md"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button
            type="button"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className="absolute right-6 top-2 text-gray-500"
          >
            {showConfirmPassword ? "Hide" : "Show"}
          </button>
        </div>
        <button
          type="submit"
          className="bg-primary text-white px-4 py-2 rounded-md"
        >
          Reset Password
        </button>
      </form>
      {error && <div className="text-red-500 text-center m-4">{error}</div>}
      {success && (
        <div className="text-green-500 text-center m-4">{success}</div>
      )}
    </div>
  );
}

export default ResetPassword;
