import React, { useState } from "react";

function PricedetailsMentor() {
  const [showDescriptions, setShowDescriptions] = useState({
    pathway: false,
    bootcamp: false,
    globalLiftoff: false,
    bridgeToUniversity: false,
  });

  const toggleDescription = (section) => {
    setShowDescriptions((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="pt-20">
      <div className="px-8 md:px-20 py-20 ">
        <div className="flex flex-col bg-stone-200 p-4 rounded-lg">
          <p className="my-6 leading-relaxed">
            “Based on our experience, around 6 sessions are typically enough to
            guide a mentee through the entire application process, including
            visa support. We encourage mentors to provide 2 additional sessions
            once the student has arrived at the university, to help them settle
            into their new environment and plan for their future career.”
          </p>

          <p className="mb-6 leading-relaxed italic font-semibold mx-4 pt-4">
            Please note that the "Pathway Discovery" are free for the mentees
            and no payment will be provided for it.
          </p>
        </div>
        <h3 className="text-lg md:text-2xl text-rose-400 text-center mx-auto font-bold my-6 md:mx-8">
          “More Dreams Realized, More Lives Impacted, More Success & Earnings
          for You”
        </h3>
        <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mt-16 mb-2 md:mx-8">
          Sessions Structure
        </h2>
        <p className="my-2 md:mx-8 text-sm border-2 border-primary rounded-lg p-4">
          This is a Unibridge recommended framework for mentors to follow during
          their interactions with mentees. While mentors have the flexibility to
          customize their sessions, it is important that the key outcomes of
          each phase are successfully met for each mentee.
          <br />
          <br />
          <p className="text-rose-400 italic">
            We will periodically evaluate and revise our payment structure to
            ensure it is fair and sustainable for both mentors and mentees.
          </p>
          <br />
          Please remember to confirm in the message section once a session has
          been completed ask mentee to confirm it.
        </p>
        <div className="relative md:mx-8">
          <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
          <div className="ml-6">
            <section className="relative my-8 md:mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Pathway Discovery
              </h3>
              <ul className="list-disc list-inside my-4 font-semibold text-primary">
                <li>Duration: 20-30 mins each</li>
                <li>Free (upto 2 sessions)</li>
                <div className="font-normal text-sm">
                  {" "}
                  Note: You have the option to merge these sessions into a
                  single 45-60 mins session if the mentee agrees and the
                  expected outcomes can be achieved.
                </div>
              </ul>

              <h3 className="text-md md:text-xl text-secondary-200 font-bold mb-2">
                Expected outcomes
              </h3>
              <ul className=" my-4 font-semibold text-primary">
                <li>📋 Clarity on Aspirations</li>
                <li>
                  📋 Clear understanding on target universities and courses
                </li>
                <li>📋 Strategic Application Roadmap</li>
              </ul>
              <button
                className="mb-2 text-secondary-200 font-semibold bg-stone-200 rounded-lg px-2 py-1 border-b-2 border-secondary-200 cursor-pointer hover:scale-105 transition-all duration-300"
                onClick={() => toggleDescription("pathway")}
              >
                Details
              </button>
              {showDescriptions.pathway && (
                <p className="mb-2">
                  These sessions are designed to provide mentees with a
                  foundational understanding of their academic and career
                  aspirations, offering a structured conversation with an
                  experienced mentor. Often, students struggle with identifying
                  the best universities and courses that align with their
                  interests, strengths, and long-term goals. This session
                  bridges that gap, helping students navigate the overwhelming
                  choices in higher education and make informed, confident
                  decisions.
                </p>
              )}
            </section>

            <section className="relative my-8 md:mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Application Bootcamp
              </h3>

              <ul className="list-disc list-inside my-4 font-semibold text-primary">
                <li>Duration: 45-60 mins each</li>
                <li>Upto 3 sessions</li>
                <li>
                  Payment: 75 GBP (in total upon successful application
                  submission)
                </li>
              </ul>

              <h3 className="text-md md:text-xl text-secondary-200 font-bold mb-2">
                Expected outcomes
              </h3>
              <ul className=" my-4 font-semibold text-primary">
                <li>📋 Strong application proposals</li>
                <li>📋 Standout SOPs</li>
                <li>
                  📋 Curated and compelling Scholarship applications (if
                  applicable)
                </li>
                <li>📋 Successfully Submitted application</li>
              </ul>

              <button
                className="mb-2 text-secondary-200 font-semibold bg-stone-200 rounded-lg px-2 py-1 border-b-2 border-secondary-200 cursor-pointer hover:scale-105 transition-all duration-300"
                onClick={() => toggleDescription("bootcamp")}
              >
                Details
              </button>
              {showDescriptions.bootcamp && (
                <p className="mb-2">
                  The focus of these sessions will be on perfecting key
                  documents like SOPs, recommendation letters, scholarship and
                  other application related documents. The iterative process of
                  feedback and revisions ensures that the documents are
                  well-crafted and ready for submission to universities. The
                  word "Bootcamp" conveys the intensive focus to detail and
                  guidance that mentors provide to help mentees achieve
                  excellence in their applications.
                </p>
              )}
            </section>

            <section className="relative my-8 md:mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Global Liftoff
              </h3>
              <ul className="list-disc list-inside my-4 font-semibold text-primary">
                <li>Duration: 45-60 mins</li>
                <li>Payment: 25 GBP</li>
              </ul>

              <h3 className="text-md md:text-xl text-secondary-200 font-bold mb-2">
                Expected outcomes
              </h3>
              <ul className=" my-4 font-semibold text-primary">
                <li>📋 Visa processes</li>
                <li>📋 Financial planning</li>
                <li>📋 Accommodation suggestions</li>
                <li>📋 Pre-departure checklist</li>
              </ul>

              <button
                className="mb-2 text-secondary-200 font-semibold bg-stone-200 rounded-lg px-2 py-1 border-b-2 border-secondary-200 cursor-pointer hover:scale-105 transition-all duration-300"
                onClick={() => toggleDescription("globalLiftoff")}
              >
                Details
              </button>
              {showDescriptions.globalLiftoff && (
                <p className="mb-2">
                  The Post-Application Success Session is designed to guide the
                  mentee through crucial steps like visa processes, financial
                  planning, and accommodation suggestions. The mentor should
                  also provide essential tips such as managing finances, travel
                  and accommodation for settling into their new home. The
                  session aims to ensure they’re well-prepared for life abroad,
                  making their transition as smooth and stress-free as possible.
                </p>
              )}
            </section>

            <p className="font-semibold">
              You should encourage your mentee to book the "Sustained Guidance"
              sessions once they have started studying abroad.
            </p>

            <section className="relative my-8 md:mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                The Sustained Guidance
              </h3>
              <ul className="list-disc list-inside my-4 font-semibold text-primary">
                <li>Duration: 20-25 mins each</li>
                <li>Upto 2 sessions</li>
                <li>Payment: 15 GBP each</li>
              </ul>

              <h3 className="text-md md:text-xl text-secondary-200 font-bold mb-2">
                Expected outcomes
              </h3>
              <ul className=" my-4 font-semibold text-primary">
                <li>📋 Getting comfortable </li>
                <li>📋 Career planning</li>
              </ul>

              <button
                className="mb-2 text-secondary-200 font-semibold bg-stone-200 rounded-lg px-2 py-1 border-b-2 border-secondary-200 cursor-pointer hover:scale-105 transition-all duration-300"
                onClick={() => toggleDescription("bridgeToUniversity")}
              >
                Details
              </button>
              {showDescriptions.bridgeToUniversity && (
                <p className="mb-2">
                  In these sessions, the mentor can provide ongoing support to
                  help the mentee adjust smoothly to their new environment. This
                  phase includes guidance on practical aspects such as setting
                  up a local bank account, managing finances, having social life
                  , and understanding local transport. Also the mentor will
                  endeavor to provide guidance around future career prospects
                  once the mentee is well settled at the university
                </p>
              )}
            </section>
          </div>
        </div>

        <div className="bg-stone-100 rounded-lg p-4">
          <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
            Single Accelerator Session
          </h3>
          <ul className=" my-4 font-semibold text-primary">
            <li>Duration: 45-60 min</li>
            <li>Payment: 35 GBP </li>
          </ul>
          You can book per session with your preferred mentor if you are looking
          for specific and targeted feedback. This offers the flexibility to
          choose only what you need.
          <span className="font-semibold">
            {" "}
            Please note that in this case, you will only get one free
            introductory session of 20-25 mins to align with your mentor.
          </span>
        </div>
      </div>
    </div>
  );
}

export default PricedetailsMentor;
