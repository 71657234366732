import React from "react";
import { MdOutlineEdit, MdOutlineAddCircleOutline } from "react-icons/md";

function EducationSection({ education, type, onEdit, onAdd }) {
  return (
    <div className="">
      <h3 className="flex justify-between text-xl font-bold text-primary">
        Education
        <div className="flex space-x-4">
          <button onClick={onAdd} className=" hover:bg-gray-200">
            <MdOutlineEdit className="w-6 h-6" />
          </button>
        </div>
      </h3>
      <ul>
        {education.map((edu, index) => (
          <div
            key={edu.id}
            className={`flex flex-col justify-start text-sm px-4 pb-2 rounded-lg ${
              index % 2 === 0 ? "bg-gray-100" : "bg-white"
            }`}
          >
            <div className="pt-4">
              <span className="font-semibold pr-2">
                {edu.degree} {edu.field_of_study}{" "}
              </span>
            </div>
            <div>{edu.school}</div>
            {edu.funding && (
              <div className="italic">
                {edu.funding}-Funding: {edu.funding_name}
              </div>
            )}
            {edu.topic && (
              <div>
                <span className="font-semibold pr-2 ">
                  Research/Specialisation:
                </span>
                {edu.topic}
              </div>
            )}
            {type == "Mentee" && edu.cgpa && (
              <div>
                <span className="font-semibold pr-2 ">CGPA:</span>
                {edu.cgpa}
              </div>
            )}

            {edu.start_date && edu.end_date && (
              <div>
                {new Date(edu.start_date).toLocaleString("default", {
                  year: "numeric",
                  month: "long",
                })}{" "}
                -{" "}
                {new Date(edu.end_date).toLocaleString("default", {
                  year: "numeric",
                  month: "long",
                })}
              </div>
            )}
          </div>
        ))}
      </ul>
    </div>
  );
}

export default EducationSection;
