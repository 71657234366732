import React, { useState, useEffect, useMemo } from "react";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/Unibridgelogo.svg";
import { Link, useNavigate } from "react-router-dom";
import Win from "../../assets/images/Beamentor/Win.svg";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";

function Mentors() {
  const navigate = useNavigate();
  const [userEducation, setUserEducation] = useState([]);
  const [mentor, setMentor] = useState(null);
  const [session, setSession] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [userFilter, setUserFilter] = useState({
    degree: "All",
    discipline: "All",
    region: "All",
  });
  const [displayCount, setDisplayCount] = useState(9);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // **New Loading States**
  const [isApplying, setIsApplying] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const degrees = [
    { id: 1, name: "All" },
    { id: 2, name: "Undergraduate" },
    { id: 3, name: "Master" },
    { id: 4, name: "PhD" },
    { id: 5, name: "Postdoctoral" },
    { id: 6, name: "Professional career" },
  ];

  const disciplines = [
    { id: 1, name: "All" },
    { id: 2, name: "STEM" },
    { id: 3, name: "Art & Humanities" },
    { id: 4, name: "Business & Management" },
    { id: 5, name: "Social sciences" },
    { id: 6, name: "Law" },
  ];

  const regions = [
    { id: 1, name: "All" },
    { id: 2, name: "UK" },
    { id: 3, name: "US" },
    { id: 4, name: "Canada" },
    { id: 5, name: "Europe" },
    { id: 6, name: "Australia & NZ" },
    { id: 7, name: "Japan" },
    { id: 8, name: "South Korea" },
    { id: 9, name: "Singapore" },
  ];

  const fetchMentors = async () => {
    const { data, error } = await supabase.rpc("get_mentors_testing", {
      p_degree: userFilter.degree === "All" ? null : userFilter.degree,
      p_discipline:
        userFilter.discipline === "All" ? null : userFilter.discipline,
      p_region: userFilter.region === "All" ? null : userFilter.region,
    });

    if (error) {
      console.error(`Error fetching mentors: ${error.message}`);
    } else {
      const userIds = data.map((mentor) => mentor.user_id);

      const { data: mentorsData, error: mentorsError } = await supabase
        .from("users")
        .select("user_id, name, profile_pic, mentorship_status")
        .in("user_id", userIds);

      if (mentorsError) {
        console.error(mentorsError);
      } else {
        setFilteredUsers(mentorsData);
      }

      const { data: educationData, error: educationError } = await supabase
        .from("user_education")
        .select("*")
        .in("user_id", userIds);
      if (educationError) console.error(educationError);
      else setUserEducation(educationData);
    }
  };

  const handleFilterChange = (e) => {
    setUserFilter({
      ...userFilter,
      [e.target.name]: e.target.value,
    });
  };

  const handleApplyFilters = () => {
    fetchMentors();
  };

  const handleConnectClick = (user_id) => {
    setIsDialogOpen(true);
    setMentor(user_id);
  };

  const handleSendMessage = async () => {
    setIsSending(true); // Set sending state to true
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      const { data: Types, error: TypesError } = await supabase
        .from("users")
        .select("type")
        .eq("user_id", user.id);

      if (TypesError) {
        console.error("Error fetching types:", TypesError);
      } else {
        if (!Types[0].type || Types[0].type.length === 0) {
          Swal.fire({
            title: "Please complete your profile to connect to a mentor",
            icon: "error",
          });
          navigate("/signupfilter");
          return;
        } else if (Types.length > 0 && Types[0].type === "Mentor") {
          Swal.fire({
            title:
              "You are a mentor, sorry you cannot connect to a mentor at the moment",
            icon: "error",
          });
          return;
        }
      }

      const { data: connection, error: Connectionerror } = await supabase
        .from("connection")
        .select("status, mentor")
        .eq("mentee", user.id);

      if (Connectionerror) {
        console.error("Error fetching connections:", Connectionerror);
      }
      /*else {
        if (
          connection &&
          connection.filter((conn) => conn.status === "Accepted").length > 0
        ) {
          Swal.fire({
            text: "Sorry, at this time, you can only connect to one mentor. If you want to change your mentor reachout to us at support@uni-bridge.com",
            icon: "info",
          });
          return;
        }

        if (
          connection &&
          connection.filter((conn) => conn.mentor === mentor).length > 0
        ) {
          Swal.fire({
            title: "You have tried to connect this mentor before.",
            icon: "error",
          });
          return;
        }

        if (connection.filter((conn) => conn.status === "Waiting").length < 3) {
          const { data, error } = await supabase
            .from("connection")
            .insert([
              {
                mentee: user.id,
                mentor: mentor,
                message_note: message,
              },
            ])
            .select();

          if (error) {
            console.error("Error inserting data:", error);
          } else {
            console.log("Data inserted successfully", data);
          }
          Swal.fire({
            title: "Message sent",
            text: message,
            icon: "success",
          });

          setIsDialogOpen(false);
          setMessage("");
        } else {
          Swal.fire({
            title:
              "Sorry, you can only send invite to 3 mentors. Please wait for the response",
            icon: "error",
          });
        }
      }*/

      const activeConnections = connection.filter(
        (conn) => conn.status === "Accepted"
      );
      const expiredConnections = connection.filter(
        (conn) => conn.status === "Expired"
      );
      const declinedConnections = connection.filter(
        (conn) => conn.status === "Declined"
      );
      const waitingConnections = connection.filter(
        (conn) => conn.status === "Waiting"
      );

      if (activeConnections.length > 0) {
        Swal.fire({
          text: "You already have an active mentor connection. To connect with another mentor, reach out to us at support@uni-bridge.com.",
          icon: "info",
        });
        return;
      }

      if (waitingConnections.length === 3) {
        Swal.fire({
          title:
            "Sorry, you can only send invite to 3 mentors. Please wait for the response",
          icon: "error",
        });
        return;
      }

      if (declinedConnections.some((conn) => conn.mentor === mentor)) {
        Swal.fire({
          title:
            "This mentor has declined your request. You cannot send a new request.",
          icon: "error",
        });
        return;
      }

      if (expiredConnections.some((conn) => conn.mentor === mentor)) {
        Swal.fire({
          title:
            "Your request to this mentor has been expired. You can send a new request but the mentor again may not respond.",
          icon: "info",
        });
      } else if (
        connection.some(
          (conn) => conn.mentor === mentor && conn.status === "Waiting"
        )
      ) {
        Swal.fire({
          title:
            "You have already sent a request to this mentor, please wait for their response.",
          icon: "error",
        });
        return;
      }

      // Insert new connection if conditions are met
      const { data, error } = await supabase
        .from("connection")
        .insert([
          {
            mentee: user.id,
            mentor: mentor,
            message_note: message,
          },
        ])
        .select();

      if (error) {
        console.error("Error inserting data:", error);
      } else {
        console.log("Data inserted successfully", data);
      }

      Swal.fire({
        title: "Message sent",
        text: message,
        icon: "success",
      });

      setIsDialogOpen(false);
      setMessage("");
    } catch (error) {
      console.error(`Error sending message: ${error.message}`);
    } finally {
      setIsSending(false); // Reset sending state
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      const { data: sessionData, error: sessionError } =
        await supabase.auth.getSession();
      if (sessionError) {
        console.error("Error fetching session:", sessionError);
        return;
      }

      setSession(sessionData.session);

      await fetchMentors();
    };

    fetchSession();
  }, []); // Keep it empty if you only want it to run on mount

  // **Memoize the visible users to optimize rendering**
  const visibleUsers = useMemo(
    () => filteredUsers.slice(0, displayCount),
    [filteredUsers, displayCount]
  );

  return (
    <div className="min-h-lvh pt-16">
      <div className="min-h-96 bg-stone-100 text-secondary-100 text-3xl md:text-5xl font-bold flex flex-col md:flex-row text-center items-center justify-center h-72">
        <p className="py-10 md:py-0">
          We Guide,
          <br /> You Achieve,
          <br />
          Together We Conquer
        </p>
        <img src={Win} alt="Win" className="md:w-1/2 px-16 md:p-24" />
      </div>

      <div>
        <div className="text-secondary-200 text-xl md:text-3xl font-bold flex justify-center my-4 md:my-10 mx-auto text-center">
          Let's help you find the perfect mentor!
        </div>
        <div className="text-secondary-200 text-xl md:text-2xl text-semibold flex justify-center my-4 md:my-10">
          What do you aspire?
        </div>
        <div className="flex flex-col md:flex-row justify-evenly md:justify-center md:items-center mx-8 my-4">
          <div className="flex md:flex-row justify-between text-center items-center my-4 md:mx-4">
            <label className="flex text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4">
              Degree
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="degree"
            >
              {degrees.map((degree) => (
                <option key={degree.id} value={degree.name}>
                  {degree.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex md:flex-row justify-between items-center mb-4 md:mb-0 md:mx-4">
            <label className="text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4 md:ml-4">
              Discipline
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="discipline"
            >
              {disciplines.map((discipline) => (
                <option key={discipline.id} value={discipline.name}>
                  {discipline.name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex md:flex-row justify-between items-center mb-4 md:mb-0 md:mx-4">
            <label className="text-md md:text-lg font-semibold text-primary mb-2 md:mb-0 md:mr-4 md:ml-4">
              Region
            </label>
            <select
              className="p-2 min-w-52 border rounded-md shadow-md text-sm"
              onChange={handleFilterChange}
              name="region"
            >
              {regions.map((region) => (
                <option key={region.id} value={region.name}>
                  {region.name}
                </option>
              ))}
            </select>
          </div>
          <button
            className="p-2 bg-primary text-white rounded-md hover:scale-105"
            onClick={handleApplyFilters}
            disabled={isApplying} // Disable button when applying
          >
            {isApplying ? "Applying..." : "Apply"}
          </button>
        </div>
        {session !== null ? (
          <div>
            <div className="my-10 mx-6 md:mx-20">
              <div className="flex flex-wrap justify-center gap-4">
                {filteredUsers.length === 0 ? (
                  <div className="my-2 px-4 min-w-96 min-h-72 text-center">
                    <div className="border rounded-xl p-4 shadow-lg">
                      <h3 className="text-center font-semibold mb-4">
                        Apply the filters to find your mentor
                      </h3>
                    </div>
                  </div>
                ) : (
                  visibleUsers.map((user) => (
                    <div
                      key={user.user_id}
                      className="my-2 px-4 w-96 min-h-72 text-sm overflow-auto"
                    >
                      <div className="relative border rounded-3xl p-6 shadow-lg bg-slate-50 transition-all transform ">
                        <img
                          src={user.profile_pic || default_profile}
                          alt={user.name}
                          className="object-cover rounded-full h-40 w-40 border-4 border-white shadow-md mx-auto "
                        />
                        {user.mentorship_status && (
                          <div className="absolute top-4 right-4 group">
                            <div
                              className={`flex items-center px-2 py-1 rounded-full text-sm font-medium ${
                                user.mentorship_status === "Active"
                                  ? "bg-green-500 text-white"
                                  : user.mentorship_status === "Busy"
                                  ? "bg-yellow-500 text-white"
                                  : "bg-red-500 text-white"
                              }`}
                            >
                              {user.mentorship_status}{" "}
                              <IoIosInformationCircleOutline className="ml-2" />
                            </div>
                            <div className="absolute w-64 p-2 rounded-lg bg-gray-800 text-white text-xs right-0 mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                              <span>
                                {user.mentorship_status === "Active" &&
                                  "Active: I am available to mentor."}
                                {user.mentorship_status === "Busy" &&
                                  "Busy: I will soon be available to mentor."}
                                {user.mentorship_status === "Inactive" &&
                                  "Inactive: I am not available to mentor."}
                              </span>
                            </div>
                          </div>
                        )}

                        <h3 className="text-center text-lg font-semibold text-gray-800 mb-2 mt-2">
                          {user.name}
                        </h3>

                        {/*<ul className=" h-24 ">
                          {userEducation
                            .filter(
                              (education) => education.user_id === user.user_id
                            )
                            .slice(0, 1)
                            .map((education) => (
                              <li
                                key={education.id}
                                className="flex flex-col justify-start"
                              >
                                <div>
                                  <span className="font-semibold pr-2">
                                    {education.degree}
                                  </span>
                                  {education.school}
                                </div>
                                {education.field_of_study && (
                                  <div>
                                    <span className="font-semibold pr-2">
                                      Subject
                                    </span>
                                    {education.field_of_study}
                                  </div>
                                )}
                                {education.funding && (
                                  <div className="mt-2 text-sm flex flex-wrap items-center">
                                    <span className="bg-cyan-600 text-white rounded-xl px-2 py-1 pr-2">
                                      {`${education.funding}-Funding`}
                                    </span>
                                    <span className="font-semibold ml-2">
                                      {education.funding_name}
                                    </span>
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul> */}
                        <ul className="h-24 space-y-1">
                          {userEducation
                            .filter(
                              (education) => education.user_id === user.user_id
                            )
                            .sort((a, b) => {
                              const degreeOrder = {
                                "Ph.D. ": 1, // PhD Level
                                "Pharm.D.": 1,
                                "M.D.": 2,
                                "Master's": 2, // Master's Level
                                MBA: 2,
                                MPhil: 2,
                                Mres: 2,
                                LLM: 2,
                                "Bachelor's": 3, // Bachelor's Level
                                LLB: 3,
                                "Associate's Degree": 4, // Lower qualifications
                                Diploma: 4,
                                "High school": 4,
                                GCSE: 4,
                                "AS Level": 4,
                                "A Level": 4,
                                "Professional Certificate": 4,
                                Other: 5,
                                Select: 6, // Keep "Select" at the end
                              };
                              return (
                                (degreeOrder[a.degree] || 7) -
                                (degreeOrder[b.degree] || 7)
                              );
                            })
                            .slice(0, 1)
                            .map((education) => (
                              <li
                                key={education.id}
                                className="flex flex-col justify-start text-gray-700"
                              >
                                <div>
                                  <span className="font-semibold pr-2">
                                    {education.degree}
                                  </span>
                                  {education.school}
                                </div>
                                {education.field_of_study && (
                                  <div>
                                    <span className="font-semibold pr-2">
                                      Subject
                                    </span>
                                    {education.field_of_study}
                                  </div>
                                )}
                                {education.funding && (
                                  <div className="my-2 text-sm flex items-center">
                                    <span className="bg-cyan-600 text-white rounded-full px-2 py-1 pr-2 whitespace-nowrap mt-1">
                                      {`${education.funding}-Funding`}
                                    </span>
                                    <span className="font-semibold ml-2 mt-1">
                                      {education.funding_name}
                                    </span>
                                  </div>
                                )}
                              </li>
                            ))}
                        </ul>

                        <div className="flex justify-evenly mt-4 md:mt-0">
                          <Link
                            to={`/profile/${user.user_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center"
                          >
                            <button className="bg-purple-600 hover:scale-105 text-white font-medium py-2 px-4 rounded-lg transition duration-300 mt-6">
                              Learn more
                            </button>
                          </Link>

                          <button
                            className="bg-primary hover:scale-105 text-white font-medium py-2 px-4 rounded-lg transition duration-300 mt-6"
                            onClick={() => handleConnectClick(user.user_id)}
                          >
                            Connect
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              {filteredUsers.length > displayCount && (
                <button
                  className="mt-4 flex justify-center mx-auto text-center bg-primary text-white py-2 px-4 rounded"
                  onClick={() => setDisplayCount((prev) => prev + 9)}
                >
                  Load more
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center h-32 text-semibold md:text-xl">
            <p className="text-primary bg-stone-200 rounded-xl shadow-md p-4">
              We have got 100+ dedicated mentors, Please login to view mentors
              <Link to="/login">
                <button className="bg-primary text-white text-sm ml-1 rounded-md px-4 py-2">
                  Login
                </button>
              </Link>
            </p>
          </div>
        )}

        {/* **Dialog Rendered Once Outside the Map** */}
        {isDialogOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
            <div className="bg-white p-4 rounded shadow-lg mx-2 md:w-1/2">
              <h3 className="text-lg font-semibold">Add a note (optional)</h3>
              <p className="text-sm">
                <i>
                  Reminder: you can send messages to only 3 mentors at a time
                </i>
              </p>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="This can increase your chances of getting a response. For example, I aspire to do a master's degree in data science in the US. I have done my bachelors in mechanical engineering from Amity University, Delhi."
                className="w-full border rounded p-2"
                rows="4"
              />
              <div className="flex justify-end mt-2">
                <button
                  className="bg-primary text-white py-1 px-3 rounded mr-2"
                  onClick={handleSendMessage}
                  disabled={isSending} // Disable button when sending
                >
                  {isSending ? "Sending..." : "Send"}{" "}
                </button>
                <button
                  className="bg-gray-300 text-black py-1 px-3 rounded"
                  onClick={() => setIsDialogOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mentors;
