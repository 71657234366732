import React, { useState } from "react";
import swal from "sweetalert2";
import supabase from "../../config/supabaseClient";
import { countries } from "../../data/Countries";
import { Courses } from "../../data/Courses";
import { Universitylist } from "../../data/Universitylist";
import { Degrees } from "../../data/Degrees";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { format } from "date-fns"; 

function EditEducationModal({
  userId,
  type,
  formData,
  setFormData,
  onClose,
  onSave,
}) {
  const [educationIDs] = useState(() =>
    Object.freeze(formData.education.map((edu) => edu.education_id))
  );

  const [isSaving, setIsSaving] = useState(false);
  //console.log(educationIDs);

  const handleArrayChange = (index, field, value, section) => {
    const updatedArray = [...formData[section]];
    if (index !== null && index !== undefined) {
      updatedArray[index][field] = value;
    }
    setFormData((prevData) => ({ ...prevData, [section]: updatedArray }));
  };

  const handleAddField = (section, emptyField) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], emptyField],
    }));
  };

  const handleDeleteField = (index, section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((_, i) => i !== index),
    }));
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await updateEducation();
      await onSave();

      swal
        .fire({
          title: "Success",
          text: "Education updated successfully.",
          icon: "success",
        })
        .then(() => {
          window.location.reload();
        });
    } finally {
      setIsSaving(false);
    }
  };

  const updateEducation = async () => {
    const educations = formData.education;

    // Filter out education IDs that do not exist in the current educations
    const notExistingEducationIDs = educationIDs.filter(
      (id) => !educations.some((edu) => edu.education_id === id)
    );

    // Check for missing education IDs and delete them
    for (const eduId of notExistingEducationIDs) {
      const { error } = await supabase
        .from("user_education")
        .delete()
        .eq("user_id", userId)
        .eq("education_id", eduId);

      if (error) {
        console.error("Error deleting education:", error);
      }
    }

    for (const edu of educations) {
      const isEmpty =
        !edu.school &&
        !edu.region &&
        !edu.degree &&
        !edu.fieldOfStudy &&
        !edu.currentlyStudying &&
        !edu.startDate &&
        !edu.endDate &&
        !edu.topic &&
        !edu.funding &&
        !edu.funding_name &&
        !edu.cgpa;

      if (!edu.education_id && !isEmpty) {
        const { error } = await supabase.from("user_education").insert({
          user_id: userId,
          region: edu.region,
          school: edu.school,
          degree: edu.degree,
          field_of_study: edu.fieldOfStudy,
          topic: edu.topic,
          funding: edu.funding,
          funding_name: edu.funding_name,
          cgpa: edu.cgpa,
          currently_studying: edu.currentlyStudying,
          start_date: edu.startDate,
          end_date: edu.endDate,
          discipline: edu.discipline,
        });

        if (error) {
          console.error("Error adding education:", error);
          swal.fire({
            title: "Error",
            text: "Failed to add education.",
            icon: "error",
          });
        }
      } else if (edu.education_id) {
        const { error } = await supabase
          .from("user_education")
          .update({
            region: edu.region,
            school: edu.school,
            degree: edu.degree,
            field_of_study: edu.fieldOfStudy,
            topic: edu.topic,
            funding: edu.funding,
            funding_name: edu.funding_name,
            cgpa: edu.cgpa,
            currently_studying: edu.currentlyStudying,
            start_date: edu.startDate,
            end_date: edu.endDate,
            discipline: edu.discipline,
          })
          .eq("user_id", userId)
          .eq("education_id", edu.education_id)
          .single();

        if (error) {
          console.error(`Error updating education: ${error.message}`);
          swal.fire({
            title: "Error",
            text: "Failed to update education.",
            icon: "error",
          });
        }
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40">
      <div className="bg-white p-6 rounded-lg md:w-2/3 lg:w-1/2 mx-4 overflow-y-auto h-[80vh]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-primary">Edit Education</h2>
          <button
            onClick={onClose}
            className="bg-secondary-500 text-white px-4 py-2 rounded-md"
          >
            Close
          </button>
        </div>

        <div className="space-y-2">
          {formData.education.map((edu, index) => (
            <div key={index} className="space-y-2 border p-4 rounded">
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-semibold">Education {index + 1}</h4>
                <button
                  type="button"
                  onClick={() => handleDeleteField(index, "education")}
                  className="px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300"
                >
                  Delete Entry
                </button>
              </div>
              <div>
                <label className="block text-gray-700">Country</label>
                <input
                  type="text"
                  name="region"
                  placeholder="Search for a Country"
                  value={edu.region}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "region",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                  list="country-list"
                />
                <datalist id="country-list">
                  {countries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </datalist>
              </div>
              <div>
                <label className="block text-gray-700">
                  School or University
                </label>
                <input
                  type="text"
                  name="school"
                  value={edu.school}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "school",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                  list="university-list"
                />
                <datalist id="university-list">
                  {Universitylist.map((university) => (
                    <option key={university} value={university}>
                      {university}
                    </option>
                  ))}
                </datalist>
              </div>
              <div>
                <label className="block text-gray-700">Degree</label>
                <select
                  name="degree"
                  value={edu.degree}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "degree",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Degree</option>
                  {Degrees.map((degree) => (
                    <option key={degree} value={degree}>
                      {degree}
                    </option>
                  ))}
                </select>
                {/* {edu.degree === "Other" && (
              <input
                type="text"
                name="otherDegree"
                placeholder="Enter your degree"
                value={edu.otherDegree}
                onChange={(e) =>
                  handleArrayChange(
                    index,
                    "otherDegree",
                    e.target.value,
                    "education"
                  )
                }
              />
            )} */}
              </div>
              <div>
                <label className="block text-gray-700">Field of Study</label>
                <input
                  type="text"
                  name="fieldOfStudy"
                  placeholder="Search the Field"
                  value={edu.fieldOfStudy}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "fieldOfStudy",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                  list="courses-list"
                />
                <datalist id="courses-list">
                  {Courses.map((course) => (
                    <option key={course.subject_id} value={course.subjects}>
                      {course.subjects}
                    </option>
                  ))}
                </datalist>
              </div>
              <div>
                <label className="block text-gray-700">Topic (optional)</label>
                <input
                  type="text"
                  name="topic"
                  placeholder="Provide research topic or any specialisation"
                  value={edu.topic}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "topic",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">Funding</label>
                <select
                  name="funding"
                  value={edu.funding}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "funding",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                >
                  <option value="">None</option>
                  <option value="Partial">Partial</option>
                  <option value="Full">Full</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700">
                  Scholarship Name (optional)
                </label>
                <input
                  type="text"
                  name="funding_name"
                  placeholder="Enter scholarship name"
                  value={edu.funding_name}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "funding_name",
                      e.target.value,
                      "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              {type === "Mentee" && (
                <div className="">
                  <label className="flex items-center text-gray-700 relative">
                    CGPA
                    <span className="ml-1 text-gray-500 cursor-pointer group relative">
                      <IoIosInformationCircleOutline />
                      <span className="absolute p-2 text-sm bg-white md:min-w-96 text-gray-800 rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100 pointer-events-none">
                        The CGPA should be defined out of 10. If you have
                        percentage, convert on the scale of 10. Please provide
                        your current CGPA if you are studying.
                      </span>
                    </span>
                  </label>

                  <input
                    type="number"
                    name="cgpa"
                    value={edu.cgpa}
                    onChange={(e) =>
                      handleArrayChange(
                        index,
                        "cgpa",
                        e.target.value,
                        "education"
                      )
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
              )}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="currentlyStudying"
                  checked={edu.currentlyStudying}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "currentlyStudying",
                      e.target.checked,
                      "education"
                    )
                  }
                  className="mr-2"
                />
                <label className="text-gray-700">
                  I am currently studying here
                </label>
              </div>
              <div>
                <label className="block text-gray-700">Start Date</label>
                <input
                  type="date"
                  name="startDate"
                  value={edu.startDate ? format(new Date(edu.startDate), "yyyy-MM-dd") : ""}
                  onChange={(e) =>
                    handleArrayChange(
                      index,
                      "startDate",
                      e.target.value,
                      "education"
                      )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  End Date (or Expected)
                </label>
                <input
                  type="date"
                  name="endDate"
                  value={edu.endDate ? format(new Date(edu.endDate), "yyyy-MM-dd") : ""}
                  onChange={(e) =>
                    handleArrayChange(
                    index,
                    "endDate",
                    e.target.value,
                    "education"
                    )
                  }
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          ))}
          <button
            type="button"
            onClick={() =>
              handleAddField("education", {
                education_id: null,
                region: "",
                school: "",
                degree: "",
                fieldOfStudy: "",
                topic: "",
                funding: "",
                funding_name: "",
                cgpa: null,
                currentlyStudying: null,
                startDate: null,
                endDate: null,
                discipline: "",
              })
            }
            className="mt-4 px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
          >
            Add More Education
          </button>
        </div>
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSave}
            className="bg-primary text-white px-4 py-2 rounded-md"
            disabled={isSaving} // Disable button when saving
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditEducationModal;