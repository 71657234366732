export const Degrees = [
  "Select",
  "Bachelor's",
  "Master's",
  "MPhil",
  "Mres",
  "Ph.D. ",
  "MBA",
  "LLB",
  "LLM",
  "Associate's Degree",
  "GCSE",
  "AS Level",
  "A Level",
  "High school",
  "Professional Certificate",
  "Pharm.D.",
  "M.D.",
  "Diploma",
  "Other",
];
