import React from "react";

function Letterofrecom() {
  return (
    <div className="pt-20 min-h-screen md:p-6">
      <div className="mx-auto p-8 text-neutral-600">
        <div className="md:min-h-72 md:my-16 bg-primary rounded-xl shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 rounded-xl transform -rotate-3 translate-y-2"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white text-center mb-6 relative z-10">
            Letter of Recommendation
          </h1>
          {/* Section 1: Introduction */}
          <section className="my-8 relative z-10">
            <p className="text-white px-8 text-center mx-auto md:text-lg font-medium">
              A Letter of Recommendation (LOR) is a formal document written by
              someone who can vouch for an individual's qualifications, skills,
              and character. A strong letter of recommendation can significantly
              enhance an application that an academic or professional record
              alone cannot convey.
            </p>
            <div className="md:text-xl text-stone-200 font-semibold flex items-center justify-center text-center mx-4 my-8">
              <i>
                "Ensure your LOR authentically represents the candidate's
                strengths and potential."
              </i>
            </div>
          </section>
        </div>

        {/* Guidelines Section */}
        <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mt-16 mb-2 md:mx-8">
          Guidelines for Writing a Letter of Recommendation
        </h2>
        <div className="relative md:mx-12">
          <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
          <div className="ml-6">
            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Recognise the Goal:
              </h3>
              <p className="">
                Familiarise yourself with the university and program the
                applicant is applying to and any particular guidelines or
                requests for a reference letter. It will help to modify the
                letter to reflect the program's expectations and values.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Gather Information:
              </h3>
              <p className="">
                Compile comprehensive details on the student, such as their
                goals, extracurricular activities, academic record, resume, and
                any particular projects or experiences they would like to have
                highlighted.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Emphasise Strengths:
              </h3>
              <p className="">
                Draw attention to the student's accomplishments, special traits,
                and strengths. It could involve leadership capacity,
                problem-solving techniques, and academic success.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Talk about Potential and Fit:
              </h3>
              <p className="">
                Explain the student's suitability for the program and how their
                objectives, experience, and background match what the university
                seeks. Mention their capacity to positively impact the academic
                community and their likelihood of success in their field of
                study.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Be Sincere and Truthful:
              </h3>
              <p className="">
                Write the letter with sincerity and truth. Avoid hyperbole and
                too much positive rhetoric. The letter should present a fair
                assessment of the student's qualifications and personality.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Act with professionalism:
              </h3>
              <p className="">
                Use formal words and a formal tone. Ensure the letter is
                formatted according to the university's requirements,
                well-organised, and devoid of typos and grammatical errors.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Respect Deadlines:
              </h3>
              <p className="">
                Ensure you finish and send the letter by the specified time.
                Also, carefully follow the university's instructions; some may
                demand online submission or specific forms to be completed.
              </p>
            </section>

            <section className="relative my-8 mr-10">
              <div className="absolute -left-10 top-0 bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Respect Confidentiality:
              </h3>
              <p className="">
                Follow the procedures to keep the letter secret and not reveal
                its contents to the student if it is confidential (e.g.,
                submitted directly to the institution).
              </p>
            </section>
          </div>
        </div>

        {/* What and How Section */}
        <div className="md:mx-8">
          <h2 className="text-secondary-200 text-xl md:text-3xl font-bold mt-6 mb-2 ">
            What and How of a Letter of Recommendation?
          </h2>
          <p className="mb-4">
            When evaluating reference letters from your referees, or if you must
            draft them yourself, it is crucial to consider several key qualities
            that define a strong reference letter. Ideally, the letter should be
            concise, spanning 1-2 pages, and formatted formally, preferably on
            institutional letterhead. <br /> The initial part of the letter
            should provide a brief professional biography of the referee and
            clearly outline their relationship with you, including how long and
            in what capacity they have known you. The following section should
            focus on the referee's detailed assessment of your performance in
            the work you have conducted together or under their supervision.
            These elements help ensure that the reference letter is
            comprehensive, credible, and effectively communicates your abilities
            and achievements.
          </p>
          <h3 className="text-xl text-secondary-200 font-semibold mb-2">
            For Academic Referees:
          </h3>
          <p className="mb-4">
            The reference letter should include their evaluation of your class
            performance, participation, exam results, class rank, and any other
            academic work you have completed within the university setting.
          </p>
          <h3 className="text-xl text-secondary-200 font-semibold mb-2">
            For Professional Referees:
          </h3>
          <p className="mb-4">
            A professional referee would focus on your personal qualities in a
            workplace context, providing specific examples. They might comment
            on communication, research, leadership, dedication, efficiency,
            commitment to quality, and other relevant soft skills.
          </p>
        </div>

        {/* Additional Links Section */}
        <h2 className="text-2xl text-secondary-200 font-semibold mb-2 md:mx-8">
          Additional Links and Examples:
        </h2>
        <ul className="list-disc list-inside mb-4 md:mx-8">
          <li>
            <a
              className="text-blue-500"
              href="https://medicine.cuny.edu/wp-content/uploads/2023/07/Sample-Letters-of-Recommendation.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sample LoR - CUNY
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://www.mrc-cbu.cam.ac.uk/documents/equality/HHMI_WriteReference.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide to LoR from MRC Cambridge University
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://www.press.umich.edu/pdf/9780472031887-appendixg.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Example from University of Michigan
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://gsi.berkeley.edu/media/sample-recommendation-letter.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sample LoR UC Berkeley
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://www.mrc-cbu.cam.ac.uk/documents/equality/HHMI_WriteReference.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide to LoR Writing CBU Cambridge Uni
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://www.ualberta.ca/en/graduate-studies/media-library/professional-development/pd-resources/student-life/stlife-2013-08-19-reference-letters.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide and Example University of Alberta
            </a>
          </li>
          <li>
            <a
              className="text-blue-500"
              href="https://medicine.fiu.edu/resources/faculty/clinical-and-classroom-teaching/_assets/docs/sample-strong-letter-of-recommendation-03-08-2018.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sample LoR Medicine FIU
            </a>
          </li>
        </ul>

        {/* Additional Links Section */}
      </div>
    </div>
  );
}

export default Letterofrecom;
