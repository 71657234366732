import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  motion,
  useInView,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import Unilog from "../assets/images/Unilogo.png";
import Stepping_hero from "../assets/images/stepping_hero.svg";
import Subhero from "../assets/images/subhero2.svg";
import Mnetor_pic from "../assets/images/mentor2.jpg";
import Offerings from "../assets/images/Offerings.jpeg";
import Cambridge from "../assets/images/University/cambridge.svg";
import Imperial from "../assets/images/University/icl.svg";
import Berkeley from "../assets/images/University/berkeley.svg";
import MIT from "../assets/images/University/mit.svg";
import Stanford from "../assets/images/University/stanford.svg";
import Oxford from "../assets/images/University/oxford.svg";
import UCL from "../assets/images/University/ucl.svg";
import Max from "../assets/images/University/max.svg";
import ETH from "../assets/images/University/eth.svg";
import Princeton from "../assets/images/University/princeton.svg";
import Toronto from "../assets/images/University/toronto.svg";
import Chicago from "../assets/images/University/chicago.svg";
import Caltech from "../assets/images/University/caltech.svg";
import Penn from "../assets/images/University/penn.svg";
import ICL from "../assets/images/University/icl.svg";
import NUS from "../assets/images/University/nus.svg";
import UBC from "../assets/images/University/ubc.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay, FreeMode } from "swiper/modules";
// import Testimonials from "./Testimonials";
import rectangle from "../assets/images/rectangle.svg";
// import down_icon from "../assets/images/ForResources/down_icon.svg";
// import Mentor_offering2 from "../assets/images/login.svg";
import "./Subherosection.css";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import {
  FaPencilAlt,
  FaGraduationCap,
  FaCalendarAlt,
  FaTrophy,
  FaPassport,
} from "react-icons/fa";
import { MdFlightTakeoff } from "react-icons/md";
import { LiaUniversitySolid } from "react-icons/lia";

function Subherosection() {
  function Card({ icon, title, description }) {
    return (
      <div className="bg-stone-200 rounded-lg p-6 ">
        <div className="bg-secondary-500 rounded-full p-3 mr-4 flex h-20 w-20">
          {React.cloneElement(icon, {
            className: "text-white text-5xl ml-1 text-center",
          })}
        </div>
        <div className="text-left mt-6 mb-4">
          <h3 className="text-xl font-bold mb-4 text-primary">{title}</h3>
          <p className="text-primary pl-2">{description}</p>
        </div>
      </div>
    );
  }

  const AnimatedNumber = ({ value }) => {
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });
    const numValue = parseFloat(value);
    const motionValue = useMotionValue(0);
    const springValue = useSpring(motionValue, { duration: 3000 });
    const roundedValue = useTransform(springValue, (latest) =>
      Math.round(latest)
    );

    useEffect(() => {
      if (inView) {
        motionValue.set(numValue);
      }
    }, [inView, motionValue, numValue]);

    return (
      <motion.span ref={ref} className="text-4xl md:text-5xl font-bold mb-2">
        <motion.span>{roundedValue}</motion.span>
        {value.toString().includes("%") && "%"}
        {value.toString().includes("+") && "+"}
      </motion.span>
    );
  };

  const StatCard = ({ bgColor, textColor, value, description }) => (
    <div
      className={`${bgColor} ${textColor} rounded-lg p-6 text-center shadow-lg flex flex-col items-center justify-center h-full `}
    >
      <AnimatedNumber value={value} />
      <div className="text-base mt-2">{description}</div>
    </div>
  );

  return (
    <div className="flex top-0 flex-col w-full">
      <div className=" my-10 md:mt-20 flex flex-col">
        <h2 className="text-4xl xl:text-6xl font-bold text-center text-primary mb-6">
          Trusted Mentors
        </h2>
        <div className="max-w-6xl mx-auto px-4 py-8">
          <div className="grid grid-cols-1 md:grid-cols-4 ">
            {/* First Tall Column */}
            <div className="md:row-span-2 md:mr-6 md:mb-0 mb-6">
              <StatCard
                bgColor="bg-primary"
                textColor="text-white"
                value="100+"
                description="and growing"
              />
            </div>
            {/* First Pair of Small Columns */}
            <div className="grid grid-rows-2 gap-6 h-full">
              <StatCard
                bgColor="bg-gray-100"
                textColor="text-primary"
                value="90%"
                description="from top 20 universities"
              />
              <StatCard
                bgColor="bg-gray-100"
                textColor="text-primary"
                value="95%"
                description="with full scholarships"
              />
            </div>
            {/* Second Tall Column */}
            <div className="md:row-span-2 md:mx-6 md:my-0 my-6 ">
              <div className="bg-primary text-white rounded-lg p-6 flex flex-col justify-center items-center text-center h-full shadow-lg">
                <div className="text-xl mb-4"> across all disciplines</div>
                <div className="space-y-2 text-lg text-center font-bold">
                  <div>STEM</div>
                  <div>Social Sciences</div>
                  <div>Arts & Humanities</div>
                  <div>Law</div>
                  <div>Business & Management</div>
                </div>
              </div>
            </div>
            {/* Second Pair of Small Columns */}
            <div className="grid grid-rows-2 gap-6">
              <StatCard
                bgColor="bg-gray-100"
                textColor="text-primary"
                value="80%"
                description="hold a PhD degree"
              />
              <StatCard
                bgColor="bg-gray-100"
                textColor="text-primary"
                value="300+"
                description="students supported by our mentors*"
              />
            </div>
          </div>
          <div className="text-center md:text-right md:pr-6 ">
            <p className="text-sm text-gray-500 mt-2">
              * as part of independent mentorship
            </p>
          </div>
        </div>
        <div className="  mx-auto bg-white w-[360px] h-[72px]  flex flex-col lg:flex-row items-center mt-8 md:w-[1080px] md:h[240px]">
          <Swiper
            spaceBetween={2}
            slidesPerView={2}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
            }}
            loop={true}
            freeMode={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              reverseDirection: false,
            }}
            speed={4000}
            modules={[Autoplay, FreeMode]}
            className="w-full marquee-swiper"
          >
            {[
              Cambridge,
              Imperial,
              Berkeley,
              MIT,
              Stanford,
              Oxford,
              UCL,
              Max,
              ETH,
              Princeton,
              Toronto,
              Chicago,
              Caltech,
              Penn,
              ICL,
              NUS,
              UBC,
            ].map((university, index) => (
              <SwiperSlide key={index}>
                <img
                  src={university}
                  alt={`University ${index + 1}`}
                  className="h-12 sm:h-16 md:h-20 w-full object-contain"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="relative  bg-opacity-90 text-grey-700  w-full flex-grow  md:px-20 md:py-32">
          <img
            src={rectangle}
            alt="rectangle"
            className="hidden xl:block absolute top-4 left-0  w-full h-full object-cover "
          />
          <h1 className="text-4xl xl:text-6xl font-bold text-center text-secondary-700 mb-10 mt-10 mx-2 ">
            Your Journey with us
          </h1>
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col mx-8 space-y-6 xl:w-2/5">
              <div className=" xl:h-52  text-sm xl:text-base flex bg-white rounded-lg overflow-hidden shadow-md xl:-translate-x-1/3 ">
                <div className=" bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🏆
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Customized Support for Every Dream
                  </h2>
                  <p className="text-gray-500">
                    No matter your ambition—whether it's a prestigious
                    university or studying abroad—receive personalized
                    mentorship tailored to your unique profile.
                  </p>
                </div>
              </div>
              <div className=" xl:h-52 text-sm md:text-base flex bg-white rounded-lg overflow-hidden shadow-md ">
                <div className="bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🤝
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Real-World Experience at Your Fingertips
                  </h2>
                  <p className="text-gray-500">
                    Learn from mentors who have successfully navigated their own
                    journeys, offering you invaluable insights and proven
                    strategies.
                  </p>
                </div>
              </div>
              <div className=" xl:h-52 text-sm md:text-base flex bg-white rounded-lg overflow-hidden shadow-md xl:translate-x-1/3">
                <div className="bg-secondary-500 w-1/4 flex items-center justify-center text-white text-6xl font-bold">
                  🚀
                </div>
                <div className="p-4 w-3/4 bg-stone-100">
                  <h2 className="text-xl font-bold mb-2">
                    Propel Your Future Forward
                  </h2>
                  <p className="text-gray-500">
                    Equip yourself with the tools and strategies to shine in
                    your applications and stand out in a competitive landscape!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h1 className="text-3xl font-bold font-center text-primary mx-auto md:mb-12 md:text-6xl">
        Process Overview
      </h1>
      <div className="min-h-96 ml-8 my-10 md:mt-10 flex flex-col md:flex-row">
        <div className="md:w-3/5 px-6 my-2 md:my-10 flex justify-center items-center">
          <img
            src={Subhero}
            alt="stepping_up icon"
            className="flex item-center justify-center"
          />
        </div>
        <div class="flex flex-col items-center justify-center md:mx-auto md:w-3/5  mx-8 space-y-8">
          <div class="card bg-secondary-500 sticky top-24 rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">01</h2>
            <h3 class="mb-4 text-2xl font-semibold">Sign up for an account</h3>
            <p>Create your personal account to start using our services.</p>
          </div>
          <div class="card bg-secondary-600 sticky top-24 -rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">02</h2>
            <h3 class="mb-4 text-2xl font-semibold">Choose a mentor</h3>
            <p>
              Select from a list of qualified mentors who fit best to your
              academic requirements.
            </p>
          </div>
          <div class="card bg-secondary-500 sticky top-24 rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">03</h2>
            <h3 class="mb-4 text-2xl font-semibold">Book a session</h3>
            <p>
              Book a mentorship session at your convenience to get personalized
              guidance and support for your applications.
            </p>
          </div>
          <div class="card bg-secondary-600 sticky top-24 -rotate-2 p-4 rounded-lg shadow-md md:w-4/5 h-50">
            <h2 class="text-xl font-bold">04</h2>
            <h3 class="mb-4 text-2xl font-semibold">Track your progress</h3>
            <p>
              Monitor your academic progress and receive feedback from your
              mentor to stay on track towards achieving your goals.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-secondary-900 bg-opacity-90 h-52 flex flex-wrap justify-center px-4 my-12 mx-8 md:mx-20 rounded-xl text-white">
        <div className="flex flex-col items-center justify-center md:w-1/2">
          <h1 className="text-3xl md:text-6xl font-bold mb-2">
            Connect with Us
          </h1>
          <h3 className="text-center mb-4">
            Join our network to access top-notch mentors.
          </h3>
        </div>
        <div className="text-center flex items-center justify-center md:w-1/2">
          <Link to="/signup">
            <button className="bg-primary text-white font-bold py-2 px-4 rounded transition duration-300 transform hover:scale-105">
              Join Us
            </button>
          </Link>
        </div>
      </div>

      {/* <div className="bg-stone-200 flex h-20 px-4 justify-center items-center text-primary  text-2xl md:text-3xl font-bold">
        Begin your journey with us
        <Link to="/signup">
          <button className=" text-white bg-primary ml-2 md:py-2  text-sm md:text-base font-semibold px-4 rounded  transition duration-300 transform hover:scale-105">
            Join now
          </button>
        </Link>
      </div> */}
      <div className="bg-secondary-700">
        <div className="text-3xl font-bold text-center text-white mx-auto my-12 md:text-6xl">
          What to expect from your mentor?
        </div>

        <div className="mx-8 md:mx-32 md:mt-10 ">
          <div className="md:flex w-full mb-20">
            <div className=" w-full md:w-1/2 md:py-8 px-6 ">
              <h1 className="text-3xl font-bold  text-white mx-auto my-4 md:mt-10  md:text-6xl ">
                Stand out, <br />
                from the crowd
              </h1>
              <h3 className="text-md  text-white md:font-semibold md:mr-10  md:text-lg">
                Mentors on Unibridge are handpicked from the top universities
                and have a proven track record of success in their respective
                fields.
              </h3>
            </div>

            <div className=" w-full md:w-1/2 py-8 md:py-0 md:px-4">
              <div className="w-full flex justify-center items-center">
                <img
                  src={Offerings}
                  alt="stepping_up icon"
                  className="flex item-center justify-center rounded-xl"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-4 md:mr-12 grid grid-cols-1  gap-16">
          <div className="w-full px-4 md:px-28 md:py-12 ">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <Card
                icon={
                  <FaArrowsDownToPeople className="text-4xl mb-4 text-secondary-700" />
                }
                title="Personalized Guidance"
                description={
                  <ul className="list-disc">
                    <li>Share similar experiences</li>
                    <li>Provide tailored advice</li>
                    <li>Help navigate challenges</li>
                  </ul>
                }
              />
              <Card
                icon={
                  <FaPencilAlt className="text-4xl mb-4 text-secondary-700" />
                }
                title="University Selection"
                description={
                  <ul className="list-disc">
                    <li>Identify best-fit institutions</li>
                    <li>Evaluate program strengths</li>
                    <li>Share campus culture</li>
                  </ul>
                }
              />

              <Card
                icon={
                  <FaGraduationCap className="text-4xl mb-4 text-secondary-700" />
                }
                title="Application Mastery"
                description={
                  <ul className="list-disc">
                    <li>Review and refine essays</li>
                    <li>Prepare for interviews</li>
                    <li>Strengthen overall applications</li>
                  </ul>
                }
              />

              <Card
                icon={
                  <FaCalendarAlt className="text-4xl mb-4 text-secondary-700" />
                }
                title="Scholarship Strategy"
                description={
                  <ul className="list-disc">
                    <li>Explore funding opportunities</li>
                    <li>Scholarship applications help</li>
                    <li>Advise on financial planning</li>
                  </ul>
                }
              />
            </div>
          </div>

          <div className=" flex flex-wrap px-4 py-4 md:mx-32">
            <div className="w-full md:w-1/2 px-4 flex justify-center items-center">
              <img
                src={Stepping_hero}
                alt="mentor icon"
                className="flex item-center justify-center "
              />
            </div>

            <div className=" w-full md:w-1/2 py-8 px-6 md:pl-16 ">
              <h1 className="text-3xl font-bold  text-white mx-auto my-4 md:mt-10  md:text-6xl ">
                Empowering Students with Every Step
              </h1>
              <h3 className="text-md  text-white md:font-semibold md:mr-10  md:text-lg">
                We are dedicated to guiding students through their academic and
                career journeys, ensuring their success and growth in every
                step.
              </h3>
            </div>
          </div>

          <div className="w-full  md:mr-12 grid grid-cols-1 gap-16">
            <div className="w-full px-4 md:px-28 pb-12 md:pt-12 ">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <Card
                  icon={
                    <FaPassport className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Visa Navigation"
                  description={
                    <ul className="list-disc">
                      <li>Create application checklist</li>
                      <li>Get insights on documents</li>
                      <li>Share tips for successful visa applications</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <MdFlightTakeoff className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Pre-Departure Prep"
                  description={
                    <ul className="list-disc">
                      <li>Facilitate Batchmates Connect</li>
                      <li>Essentials to carry</li>
                      <li>Accommodation support</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <LiaUniversitySolid className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Campus Life advice"
                  description={
                    <ul className="list-disc">
                      <li>Guidance on settling in</li>
                      <li>Advise on campus resources</li>
                      <li>Support building social networks</li>
                    </ul>
                  }
                />

                <Card
                  icon={
                    <FaTrophy className="text-4xl mb-4 text-secondary-700" />
                  }
                  title="Career Development"
                  description={
                    <ul className="list-disc">
                      <li>Offer industry insights</li>
                      <li>Guide internship searches</li>
                      <li>Advice on networking strategies</li>
                    </ul>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="bg-stone-100 my-10 py-10">
        {" "}
        <Testimonials />{" "}
      </div> */}

      <div className="flex top-0 md:flex-col w-full"></div>
    </div>
  );
}

export default Subherosection;
