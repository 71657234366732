import React from "react";
import { MdOutlineEdit } from "react-icons/md";
import Linkedin from "../../assets/images/Linkedin.svg";
import Google_Scholar from "../../assets/images/Google_Scholar.svg";
import Website_personal from "../../assets/images/Website_personal.svg";

function WebsiteSection({ website, onEdit }) {
  return (
    <div className="mt-4">
      <h3 className="flex justify-between text-xl font-bold text-primary">
        Website
        <button onClick={onEdit} className="hover:bg-gray-200">
          <MdOutlineEdit className="w-6 h-6" />
        </button>
      </h3>
      <div className="text-sm flex items-center mt-6">
        <div className="flex items-start">
          <a
            href={website[0]?.linkedin}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center mb-2"
          >
            <img src={Linkedin} alt="LinkedIn" className="w-6 h-6 mr-3" />
          </a>
          <a
            href={website[0]?.research_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center mb-2"
          >
            <img
              src={Google_Scholar}
              alt="Google Scholar"
              className="w-6 h-6 mr-3"
            />
          </a>
          <a
            href={website[0]?.website_url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center"
          >
            <img
              src={Website_personal}
              alt="Personal Website"
              className="w-6 h-6 mr-3"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default WebsiteSection;
