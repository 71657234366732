import React from "react";
import { MdOutlineEdit, MdOutlineAddCircleOutline } from "react-icons/md";

function ExperienceSection({ experience, onEdit, onAdd }) {
  return (
    <div className="mt-4">
      <h3 className="flex justify-between text-xl font-bold text-primary">
        Experience
        <div className="flex space-x-4">
          <button onClick={onAdd} className=" hover:bg-gray-200">
            <MdOutlineEdit className="w-6 h-6" />
          </button>
          {/* <button onClick={onEdit} className="mr-2 hover:bg-gray-200 ">
            <MdOutlineEdit className="w-6 h-6" />
          </button> */}
        </div>
      </h3>
      {experience.map((exp, index) => (
        <div
          key={exp.id}
          className={`text-sm font-semibold my-1 py-2 px-4 rounded-lg ${
            index % 2 === 0 ? "bg-gray-100" : "bg-white"
          }`}
        >
          {exp.job_title}
          <span className="font-normal"> {exp.company}</span>
          {exp.work_period && (
            <div className="italic font-normal">
              Work duration: {exp.work_period} months
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ExperienceSection;
