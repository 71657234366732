import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import supabase from "../config/supabaseClient";
import default_profile from "../assets/images/default_profile.svg";
import Callinstruction from "./ChatComponent/Callinstruction";
import Sessiondetails from "./ChatComponent/Sessiondetails";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { GoReport } from "react-icons/go";
import Swal from "sweetalert2";

function Chat({ userId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [userConnection, setUserConnection] = useState([]);
  const [userProfilePic, setUserProfilePic] = useState({}); // Changed to an object to store profile pictures
  const [userMap, setUserMap] = useState({});
  const [selectedConnection, setSelectedConnection] = useState(null);
  const [connectionId, setConnectionId] = useState(null);
  const [userType, setUserType] = useState(null); // Stores user type
  const [showCallInstructions, setShowCallInstructions] = useState(false); // New state
  const [loading, setLoading] = useState(true); // Added loading state
  const messagesEndRef = useRef(null);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [showReportAbuseForm, setShowReportAbuseForm] = useState(false); // New state for Report Abuse form
  const [reportType, setReportType] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [emails, setEmails] = useState({ userEmail: "", reportedEmail: "" });
  const [userEmails, setUserEmails] = useState({});

  useEffect(() => {
    const fetchUserTypeAndConnections = async () => {
      try {
        // Fetch the current user's type
        const { data: currentUser, error: userError } = await supabase
          .from("users")
          .select("type, email")
          .eq("user_id", userId)
          .single();

        if (userError) {
          console.error("Error fetching user type:", userError);
          return;
        }

        setUserType(currentUser.type);
        setEmails({
          userEmail: currentUser.email,
        });

        // Fetch connections based on user type
        const connectionsPromise = supabase
          .from("connection")
          .select("id, mentor, mentee, status, channel_chat, message_note")
          .eq(
            currentUser.type === "Mentee" ? "mentee" : "mentor",
            userId.toString()
          )
          .order("status", { ascending: true });

        const mentorDataPromise =
          currentUser.type === "Mentee"
            ? null
            : supabase
                .from("users")
                .select("user_id, name, type, profile_pic")
                .eq("user_id", userId.toString())
                .single();

        // Execute promises concurrently
        const [connectionsResponse, mentorResponse] = await Promise.all([
          connectionsPromise,
          mentorDataPromise,
        ]);

        const { data: connections, error: connectionsError } =
          connectionsResponse;

        if (connectionsError) {
          console.error("Error fetching connections:", connectionsError);
          return;
        }

        setUserConnection(connections || []);
        setSelectedConnection(connections[0] || null);

        // Determine the IDs to fetch based on user type
        const relatedUserIds = connections
          .flatMap((conn) => [conn.mentor, conn.mentee])
          .filter(
            (id, index, self) => self.indexOf(id) === index && id !== userId
          );

        // Fetch user details for the other parties
        const { data: users, error: userDetailsError } = await supabase
          .from("users")
          .select("user_id, name, type, profile_pic, email")
          .in("user_id", relatedUserIds);

        if (userDetailsError) {
          console.error("Error fetching users:", userDetailsError);
          return;
        }

        const newUserProfilePic = Object.fromEntries(
          users.map((user) => [
            user.user_id,
            user.profile_pic || default_profile,
          ]) // Use default profile if none exists
        );
        setUserProfilePic(newUserProfilePic);

        const newUserMap = Object.fromEntries(
          users.map((user) => [user.user_id, user.name])
        );
        setUserMap(newUserMap);

        const newUserEmails = Object.fromEntries(
          users.map((user) => [user.user_id, user.email])
        );
        setUserEmails(newUserEmails);

        // Set the connection ID for real-time messaging
        if (connections.length > 0) {
          setConnectionId(connections[0].id);
        }
      } catch (error) {
        console.error("Error fetching user type and connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserTypeAndConnections();
  }, [userId]);

  useEffect(() => {
    if (!connectionId) return;

    const fetchMessages = async () => {
      try {
        const { data: existingMessages, error } = await supabase
          .from("chat_messages")
          .select("*")
          .eq("connection_id", connectionId)
          .order("created_at", { ascending: true });

        if (error) {
          console.error("Error fetching messages:", error);
          return;
        }

        setMessages(existingMessages || []);
        scrollToBottom();
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();

    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Check if the message has already been shown
        const hasShownMessage = localStorage.getItem(
          "notificationMessageShown"
        );
        if (!hasShownMessage) {
          // Show the first-time message
          alert(
            "Make sure in your device notification is enabled for your web browser."
          );
          // Set a flag in local storage to prevent showing the message again
          localStorage.setItem("notificationMessageShown", "true");
        }
      } else {
        console.error("Notification permission denied");
      }
    };

    requestNotificationPermission();

    // Create or connect to a real-time channel
    const channel = supabase.channel(
      `public:chat_messages:connection_id=eq.${connectionId}`
    );

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "chat_messages",
        filter: `connection_id=eq.${connectionId}`,
      },
      (payload) => {
        const newMessage = payload.new;
        setMessages((prevMessages) => [...prevMessages, newMessage]);

        if (!document.hasFocus()) {
          // Show notification if the document is not focused
          if (Notification.permission === "granted") {
            new Notification("New Message!", {
              body: `You have a new message from ${
                userMap[newMessage.sender_id]
              }: ${newMessage.message}`,
            });
          }

          // Update new messages count
          setNewMessagesCount((prevCount) => prevCount + 1);

          // Change the tab title temporarily
          const originalTitle = document.title;
          document.title = "New Message! 📩";

          // Reset the title after a short delay
          setTimeout(() => {
            document.title = originalTitle;
          }, 5000); // Change back after 3 seconds
        }
      }
    );

    channel.subscribe((status) => {
      if (status === "SUBSCRIBED") {
        console.log("Subscribed to channel");
      }
    });

    return () => {
      supabase.removeChannel(channel);
    };
  }, [connectionId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    const messageContainer = document.querySelector(
      ".flex-1.overflow-y-auto.p-4"
    );
    messageContainer?.scrollTo({
      top: messageContainer.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleReportAbuse = async (e) => {
    e.preventDefault();
    const otherUserId =
      userType === "Mentee"
        ? selectedConnection.mentor
        : selectedConnection.mentee;
    setEmails({
      reportedEmail: userEmails[otherUserId],
    });

    try {
      // Insert report into Supabase "report_abuse" table
      const { error } = await supabase.from("report_abuse").insert([
        {
          complainant_id: userId,
          reported_user_id: otherUserId,
          complainant_email: emails.userEmail,
          reported_user_email: emails.reportedEmail,
          abuse_type: reportType,
          description: reportDescription,
        },
      ]);

      if (error) {
        console.error("Error submitting report:", error);
      } else {
        Swal.fire({
          title: "Success!",
          text: "Your report has been submitted successfully.",
          icon: "success",
        });
        // Reset the form and close the popup
        setReportType("");
        setReportDescription("");
        setShowReportAbuseForm(false);
        //alert("Abuse report submitted successfully.");
      }
    } catch (error) {
      console.error("Error handling abuse report:", error);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" || !connectionId) return;

    // Determine receiver ID based on user type
    const receiverId =
      userType === "Mentee"
        ? selectedConnection.mentor
        : selectedConnection.mentee;

    const { error } = await supabase.from("chat_messages").insert([
      {
        connection_id: connectionId,
        sender_id: userId,
        receiver_id: receiverId,
        message: newMessage,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      console.error("Error sending message:", error);
    } else {
      setNewMessage("");
    }
  };

  const handleAccept = async (menteeId) => {
    // console.log("Accepting connection for mentee ID:", menteeId, userId);
    const { error } = await supabase
      .from("connection")
      .update({ status: "Accepted" })
      .eq("mentee", menteeId)
      .eq("mentor", userId);

    if (error) {
      console.error("Error accepting connection:", error);
    } else {
      // console.log("Connection accepted successfully");
      setSelectedConnection((prev) => ({ ...prev, status: "Accepted" }));
      window.location.reload();
    }
  };

  const handleReject = async (menteeId) => {
    const { error } = await supabase
      .from("connection")
      .update({ status: "Rejected" })
      .eq("mentor", userId)
      .eq("mentee", menteeId);

    if (error) {
      console.error("Error rejecting connection:", error);
    } else {
      setSelectedConnection((prev) => ({ ...prev, status: "Rejected" }));
      window.location.reload();
    }
  };

  // Helper function to format dates
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return "Today";
    if (date.toDateString() === yesterday.toDateString()) return "Yesterday";
    return date.toLocaleDateString();
  };

  // Group messages by date
  const groupedMessages = messages.reduce((groups, message) => {
    const date = formatDate(message.created_at);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  const handleConnectionChange = (connection) => {
    setSelectedConnection(connection);
    setConnectionId(connection.id);
  };

  const toggleCallInstructions = () => {
    setShowCallInstructions((prev) => !prev);
  };

  if (loading) {
    return (
      <div className="pt-20 py-4 min-h-lvh flex items-center justify-center">
        <div className="text-center">
          <div className="loader mb-4"></div>
          <p className="text-lg text-primary">Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 py-4">
      {userConnection.length > 0 ? (
        <div className="mx-4 md:mx-20">
          <div className="text-xl font-bold flex justify-center text-center my-4 ">
            Your Messages
          </div>

          <div className="flex flex-col md:flex-row justify-center h-full">
            {/* Sidebar */}
            <div className="md:w-1/4 bg-stone-200 h-full rounded-md overflow-y-auto mb-4">
              {userConnection.map((connection) => {
                const otherUserId =
                  userType === "Mentee" ? connection.mentor : connection.mentee;
                return (
                  <div
                    key={connection.id}
                    className={`flex border border-spacing-1 border-white px-4 py-2 cursor-pointer font-semibold ${
                      selectedConnection?.id === connection.id
                        ? "bg-secondary-500 bg-opacity-50"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleConnectionChange(connection)}
                  >
                    {userMap[otherUserId]}{" "}
                    <span className="px-2 flex items-center text-sm font-light">
                      ({connection.status})
                    </span>
                  </div>
                );
              })}
            </div>
            {showReportAbuseForm && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
                  <h2 className="text-xl font-bold mb-4">Report Abuse</h2>
                  <form onSubmit={handleReportAbuse}>
                    <label className="block mb-2 font-semibold">
                      Type of Abuse
                    </label>
                    <select
                      value={reportType}
                      onChange={(e) => setReportType(e.target.value)}
                      className="w-full p-2 mb-4 border rounded"
                      required
                    >
                      <option value="">Select abuse type</option>
                      <option value="Spam">Spam</option>
                      <option value="Harassment">Harassment</option>
                      <option value="Inappropriate Content">
                        Inappropriate Content
                      </option>
                      <option value="Other">Other</option>
                    </select>

                    <label className="block mb-2 font-semibold">
                      Describe the Issue
                    </label>
                    <textarea
                      value={reportDescription}
                      onChange={(e) => setReportDescription(e.target.value)}
                      className="w-full p-2 mb-4 border rounded"
                      rows="4"
                      placeholder="Describe the issue..."
                      required
                    ></textarea>

                    <div className="flex justify-end">
                      <button
                        type="button"
                        onClick={() => setShowReportAbuseForm(false)}
                        className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={handleReportAbuse}
                        className="bg-red-500 text-white px-4 py-2 rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
            {/* Main Chat Area */}
            <div className="md:w-3/4 flex flex-col h-[60lvh] border border-white bg-secondary-500 bg-opacity-50 rounded-md md:ml-4 px-2 overflow-y-auto">
              {selectedConnection ? (
                <>
                  <div className="relative flex items-center md:justify-center px-4 border-b-2 border-white py-2 font-semibold">
                    <Link
                      to={`/profile/${
                        userType === "Mentee"
                          ? selectedConnection.mentor
                          : selectedConnection.mentee
                      }`}
                    >
                      <img
                        src={
                          userProfilePic[
                            userType === "Mentee"
                              ? selectedConnection.mentor
                              : selectedConnection.mentee
                          ]
                        }
                        alt="Profile"
                        className="w-8 h-8 rounded-full mr-2"
                      />
                    </Link>
                    {userType === "Mentee"
                      ? userMap[selectedConnection.mentor]
                      : userMap[selectedConnection.mentee]}

                    <div className=" absolute flex top-1 right-0 mr-1">
                      <button
                        onClick={toggleCallInstructions}
                        className="flex  bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark transition duration-300"
                      >
                        Video Call{" "}
                        <IoIosInformationCircleOutline className="w-6 h-6 ml-1 " />
                      </button>

                      {showCallInstructions && (
                        <Callinstruction
                          onClose={() => setShowCallInstructions(false)}
                        />
                      )}
                      <div className="flex justify-start items-center">
                        <button
                          onClick={() => setShowReportAbuseForm(true)}
                          className="ml-2 p-2 text-red-500"
                          title="Report Abuse"
                        >
                          <GoReport className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex-1 overflow-y-auto p-4">
                    {Object.keys(groupedMessages).map((date) => (
                      <div key={date}>
                        <div className="text-center my-4 text-gray-600">
                          {date}
                        </div>
                        {groupedMessages[date].map((msg) => (
                          <div
                            key={msg.id}
                            className={`mb-2 flex ${
                              msg.sender_id === userId
                                ? "justify-end"
                                : "justify-start"
                            }`}
                          >
                            <div
                              className={`flex flex-wrap item-center max-w-xs ${
                                msg.sender_id === userId
                                  ? "bg-green-100"
                                  : "bg-white"
                              } p-2 rounded-md shadow`}
                            >
                              <p className="text-gray-800">{msg.message}</p>
                              <span className="text-xs text-gray-500 pl-2 pt-1">
                                {new Date(msg.created_at).toLocaleTimeString(
                                  [],
                                  { hour: "2-digit", minute: "2-digit" }
                                )}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  {/* Send Message Form - Visible to Both User Types When Accepted */}
                  {selectedConnection.status === "Accepted" && (
                    <form
                      onSubmit={handleSendMessage}
                      className="flex p-4 border-t border-gray-300 bg-white my-4 rounded-lg"
                    >
                      <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder="Type your message..."
                        className="flex-grow p-2 rounded-l-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
                      />
                      <button
                        type="submit"
                        className="bg-primary text-white px-4 py-2 rounded-r-md hover:bg-primary-dark transition duration-300"
                      >
                        Send
                      </button>
                    </form>
                  )}
                  {/* Accept/Reject Buttons - Visible Only to Mentors When Waiting */}
                  {selectedConnection.status === "Waiting" && (
                    <div className="flex flex-col items-center justify-center h-full p-4">
                      <div className="my-4 text-center">
                        {selectedConnection.message_note}
                        <div className="text-sm mt-2 text-purple-600">
                          Click on the profile picture above to view the full
                          profile.
                        </div>
                      </div>
                      {userType === "Mentor" && (
                        <div className="flex justify-center mt-12">
                          <button
                            onClick={() =>
                              handleAccept(selectedConnection.mentee)
                            }
                            className="bg-primary text-white px-4 py-2 rounded mr-2 hover:scale-105 transition duration-300"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() =>
                              handleReject(selectedConnection.mentee)
                            }
                            className="bg-red-300 bg-opacity-50 text-white px-4 py-2 rounded mr-2 hover:scale-105 transition duration-300"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p>Select a connection to start chatting.</p>
                </div>
              )}
            </div>
          </div>

          <Sessiondetails
            userId={userId}
            userType={userType}
            selectedConnection={selectedConnection || null}
            userName={
              userType === "Mentee"
                ? userMap[selectedConnection.mentor]
                : userMap[selectedConnection.mentee]
            }
          />
        </div>
      ) : (
        <div className="pt-20 py-4 min-h-lvh flex items-center justify-center">
          <div className="text-center">
            <div className="loader mb-4"></div>
            <p className="text-lg text-primary">No connections yet</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
