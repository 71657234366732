import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
// import Pricedetails from "../pages/Profile_related/PricedetailsMentor";
// import profile_icon from "../assets/images/ForNavbar/profile_icon.svg";
import default_profile from "../assets/images/default_profile.svg";
import { FaBars, FaTimes, FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing React Icons

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSignupMessage, setShowSignupMessage] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [session, setSession] = useState(null);
  const [showPricedetails, setShowPricedetails] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false); // New state
  const navigate = useNavigate();

  const handleLogout = async (e) => {
    e.preventDefault();
    if (isLoggingOut) return; // Prevent multiple clicks
    setIsLoggingOut(true); // Set loading state

    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Logout Error:", error.message);
      setIsLoggingOut(false); // Reset loading state on error
    } else {
      console.log("User signed out");
      navigate("/");
      window.location.reload();
    }
  };

  useEffect(() => {
    const fetchSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
      } else {
        setSession(data.session);

        if (data.session) {
          const email = data.session.user.email;
          const { data: user, error: userError } = await supabase
            .from("users")
            .select("user_id, name, type, profile_pic")
            .eq("email", email)
            .single();

          if (userError) {
            console.error("User Fetch Error:", userError.message);
          } else if (!user.name && !user.type) {
            setUserProfile(user);

            setShowSignupMessage(true);
          } else {
            setUserProfile(user);
          }
        }
      }
    };
    fetchSession();
  }, []);

  return (
    <nav className="fixed w-full z-40 h-16 2xl:h-20 2xl:pt-2 bg-primary shadow-md">
      <div className="px-4 md:px-8 md:text-lg xl:px-20 2xl:text-xl">
        <div className="flex justify-between h-16">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center justify-start">
            <h1
              className="text-4xl font-bold text-white cursor-pointer font-league-spartan"
              onClick={() => navigate("/")}
            >
              Unibridge
            </h1>
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center font-semibold justify-center flex-grow">
            <Link to="/mentors" className="mx-8 text-white hover:text-rose-300">
              Mentors
            </Link>

            {/* Resources Dropdown */}
            <div className=" group ">
              <button className="flex items-center text-white hover:text-rose-300">
                Resources <FaChevronDown className="ml-1 mt-1" />
              </button>

              <div className="absolute hidden w-1/2 mx-auto left-1/2 -translate-x-1/2 z-10 rounded-lg shadow-lg font-normal bg-stone-200 text-primary tooltip  group-hover:flex">
                <ul className="py-2 text-lg font-semibold flex  mx-auto space-x-12">
                  <li className="cursor-pointer ">
                    <button className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105">
                      General
                    </button>
                    <ul className="py-2 text-sm font-normal">
                      <div className="px-4 text-md  font-semibold">
                        Guidance for
                      </div>
                      <li>
                        <Link
                          to="/resources/how-to-write-cv"
                          className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                        >
                          CV
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/resources/cover-letter"
                          className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                        >
                          Cover Letter
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/resources/personal-statement"
                          className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                        >
                          Personal statement
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/resources/letter-of-recommendation"
                          className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                        >
                          Letter of recommendation
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className="">
                    <Link
                      to="/resources/undergrad"
                      className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                    >
                      Undergraduate
                    </Link>
                  </li>
                  <li className="">
                    <Link
                      to="/resources/postgrad"
                      className="block w-full text-start px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105"
                    >
                      Postgraduate
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* About Us Dropdown */}
            <div className="relative group">
              <button className="mx-8 text-white hover:text-rose-300 cursor-pointer flex items-center">
                About us <FaChevronDown className="ml-1 mt-1" />
              </button>
              <div className="absolute hidden top-full left-1 z-10 font-normal bg-stone-200 rounded-lg group-hover:flex">
                <ul className="py-2 font-semibold text-base text-primary whitespace-nowrap">
                  <li className="cursor-pointer hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition duration-300 hover:scale-105  ">
                    <Link
                      to="/who-we-are"
                      className="block w-full text-start px-8 py-2"
                    >
                      Who we are
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition hover:scale-105 transiton duration-300">
                    <Link
                      to="/what-we-offer"
                      className="block w-full text-start px-8 py-2"
                    >
                      What we offer
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition hover:scale-105 transiton duration-300">
                    <Link
                      to="/why-choose-us"
                      className="block w-full text-start px-8 py-2"
                    >
                      Why Choose us?
                    </Link>
                  </li>
                  <li className="cursor-pointer hover:bg-secondary-600 hover:bg-opacity-20 hover:rounded-sm transition hover:scale-105 transiton duration-300">
                    <Link
                      to="/faqs"
                      className="block w-full text-start px-8 py-2"
                    >
                      FAQs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* User Authentication */}
          {session ? (
            <div className="hidden lg:flex items-center justify-end">
              <Link
                to={
                  userProfile?.type === "Mentor"
                    ? "/pricedetails_mentor"
                    : "/pricedetails_mentee"
                }
                className="mr-4"
              >
                <button className="px-2 py-1 text-base font-semibold text-red-300 border-2 border-red-300  hover:scale-110 rounded transition duration-300">
                  Pricing
                </button>
              </Link>

              <Link to="/yourmessages" className=" py-2 mr-12">
                <button className="px-2 py-1 text-base font-semibold bg-rose-300 text-primary border-2 border-rose-300 hover:scale-110 rounded transition duration-300">
                  Messages
                </button>
              </Link>

              <div className="relative group">
                <img
                  src={userProfile?.profile_pic || default_profile}
                  alt="profile icon"
                  className="w-10 h-10 cursor-pointer rounded-full border-2 border-white"
                />
                <div className="absolute hidden -right-8 z-10 whitespace-nowrap py-4 group-hover:flex flex-col bg-stone-200 rounded-lg shadow-lg">
                  <Link
                    to="/your_profile"
                    className="px-8 py-2 hover:bg-gray-100"
                  >
                    Your Profile
                  </Link>

                  <Link
                    to={
                      userProfile?.type === "Mentor"
                        ? "/guideline_mentor"
                        : "/guideline_mentee"
                    }
                    className="px-8 py-2 hover:bg-gray-100"
                  >
                    Guidelines
                  </Link>
                  <button
                    onClick={handleLogout}
                    disabled={isLoggingOut} // Disable button when logging out
                    className={`bg-primary mt-2 text-white font-bold mx-8 p-2 border-0 rounded transition duration-300 ${
                      isLoggingOut
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:shadow-lg hover:scale-105"
                    }`}
                  >
                    {isLoggingOut ? "Logging out..." : "Logout"}{" "}
                    {/* Feedback */}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="hidden lg:flex items-center justify-end">
              <Link to="/beamentor">
                <button className="px-2 py-1 text-base font-semibold text-white border-2 border-white hover:shadow-lg hover:border-rose-300 hover:scale-110 rounded transition duration-300">
                  Be a mentor
                </button>
              </Link>
              <Link to="/login">
                <button className="mx-5 py-1 px-2 rounded bg-white text-primary font-semibold hover:shadow-lg hover:text-white hover:bg-rose-300 transition duration-300 hover:scale-110">
                  Login
                </button>
              </Link>
            </div>
          )}

          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Open main menu</span>
              {!isMenuOpen ? (
                <FaBars className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <FaTimes className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="lg:hidden absolute top-16 left-0 w-full bg-white shadow-lg z-50">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link
              to="/mentors"
              className="block px-3 py-2 rounded-md text-base font-medium text-primary hover:text-rose-300 hover:bg-gray-50"
              onClick={() => setIsMenuOpen(false)}
            >
              Mentors
            </Link>

            {/* Mobile Resources Dropdown */}
            <MobileDropdown title="Resources">
              <Link
                to="/resources/how-to-write-cv"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                CV
              </Link>
              <Link
                to="/resources/cover-letter"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Cover Letter
              </Link>
              <Link
                to="/resources/personal-statement"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Personal Statement
              </Link>
              <Link
                to="/resources/letter-of-recommendation"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Letter of Recommendation
              </Link>
              <Link
                to="/resources/undergrad"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Undergraduate
              </Link>
              <Link
                to="/resources/postgrad"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Postgraduate
              </Link>
              <Link
                to="/resources/professional"
                className="block px-4 py-2 hover:bg-secondary-600 hover:bg-opacity-20"
                onClick={() => setIsMenuOpen(false)}
              >
                Professional
              </Link>
            </MobileDropdown>

            {/* Mobile About Us Dropdown */}
            <MobileDropdown title="About us">
              <Link
                to="/who-we-are"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Who we are
              </Link>
              <Link
                to="/what-we-offer"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                What we offer
              </Link>
              <Link
                to="/why-choose-us"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                Why Choose us?
              </Link>
              <Link
                to="/faqs"
                className="block px-4 py-2 hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                FAQs
              </Link>
            </MobileDropdown>

            {/* Mobile User Authentication */}
            {session ? (
              <div className="flex flex-col">
                <Link
                  to={
                    userProfile?.type === "Mentor"
                      ? "/pricedetails_mentor"
                      : "/pricedetails_mentee"
                  }
                  className="block px-4 py-2 text-rose-300 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Pricing
                </Link>
                <Link
                  to="/your_profile"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Your Profile
                </Link>
                <Link
                  to="/yourmessages"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Messages
                </Link>
                <Link
                  to={
                    userProfile?.type === "Mentor"
                      ? "/guideline_mentor"
                      : "/guideline_mentee"
                  }
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Guidelines
                </Link>
                <button
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 mt-2 bg-primary text-white rounded hover:bg-primary-dark"
                >
                  Logout
                </button>
              </div>
            ) : (
              <div className="flex flex-col">
                <Link
                  to="/login"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <button className="w-full bg-primary text-white py-2 rounded">
                    Login
                  </button>
                </Link>
                <Link
                  to="/beamentor"
                  className="block px-4 py-2 hover:bg-gray-100"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <button className="w-full border border-rose-300 text-rose-300 py-2 rounded hover:scale-105 ">
                    Be a mentor
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Signup Message */}
      {showSignupMessage && (
        <div
          className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 flex flex-col items-center justify-center"
          role="alert"
        >
          <p className="font-bold">Welcome to Unibridge</p>
          <p>
            Please click below to complete your profile to access all the
            features.
          </p>
          <Link to="/signupfilter">
            <button
              className="mt-2 bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
              onClick={() => setShowSignupMessage(false)}
            >
              Create Profile
            </button>
          </Link>
        </div>
      )}
    </nav>
  );
}

/**
 * MobileDropdown Component
 */
function MobileDropdown({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left px-3 py-2 text-base font-medium text-primary hover:text-rose-300 hover:bg-gray-50 flex justify-between items-center"
      >
        {title}
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      {isOpen && <div className="pl-4">{children}</div>}
    </div>
  );
}

export default Navbar;
