import React from "react";
import Unicam from "../assets/images/Uni_Cam.jpg";
import { Link } from "react-router-dom";

function Signupfilter() {
  // const [choice, setChoice] = useState("");
  return (
    <div className="pt-16 min-h-screen">
      <div
        className="bg-cover bg-center h-full flex w-full items-center justify-center"
        style={{
          backgroundImage: `url(${Unicam})`,
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          backgroundBlendMode: "color",
        }}
      >
        <div className="max-w-3xl min-h-screen mx-auto my-10 flex justify-center ">
          <div className="mt-10 space-x-4 text-lg font-bold">
            <div className="text-white text-md font-normal md:my-10">
              <h2 className="text-center font-bold  text-2xl md:text-6xl mb-6 md:mb-16">
                Welcome to Unibridge!
              </h2>
              <h3 className="text-slate-100 px-4 text-center text-base md:text-lg">
                We are thrilled to have you on board as you embark on this
                journey of growth and achievement. Whether you’re here to gain
                guidance or to offer it, your presence makes our community
                stronger.
              </h3>
              <div className="my-5 text-xl text-tertiary-100 font-semibold px-4 text-center">
                {" "}
                What would you like to do next?
              </div>
            </div>
            <div className="flex items-center justify-center space-x-5">
              <Link
                to="/mentorsignup"
                className="p-4  text-tertiary-100 border border-tertiary-100 rounded hover:bg-tertiary-100 hover:text-white transition duration-300 ease-in-out transform hover:scale-110"
              >
                <div className="md:font-2xl">Be a Mentor</div>
              </Link>
              <Link
                to="/menteesignup"
                className="p-4 text-tertiary-100 border border-tertiary-100 rounded hover:bg-tertiary-100 hover:text-white transition duration-300 ease-in-out transform hover:scale-110"
              >
                <div className="md:font-2xl">Find a Mentor</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signupfilter;
