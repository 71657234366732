import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import supabase from "../config/supabaseClient";
import { FcGoogle } from "react-icons/fc";
import swal from "sweetalert2";

function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false); // Existing state
  const [isSigningUp, setIsSigningUp] = useState(false); // New state

  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    if (isSigningUp) return; // Prevent multiple submissions
    setIsSigningUp(true); // Set loading state

    if (!email || !password || !confirmPassword) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill in all the fields.",
      });
      setIsSigningUp(false); // Reset loading state
      return;
    }

    if (password !== confirmPassword) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Passwords do not match.",
      });
      setIsSigningUp(false); // Reset loading state
      return;
    }

    if (!termsAccepted) {
      swal.fire({
        icon: "error",
        text: "You must accept the terms and conditions to sign up.",
      });
      setIsSigningUp(false); // Reset loading state
      return;
    }

    // Check if the email already exists in the 'users' table
    const { data: existingUser, error: fetchError } = await supabase
      .from("users")
      .select("user_id")
      .eq("email", email)
      .single();

    console.log(existingUser);
    console.log(email);

    if (existingUser) {
      swal.fire({
        icon: "error",
        title: "User Already Exists",
        text: "An account with this email already exists. Please login.",
      });
      setIsSigningUp(false); // Reset loading state
      return;
    }

    try {
      const { data: user, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        console.error("Signup error:", error.message);
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Signup failed. Please try again.",
        });
      } else {
        swal.fire({
          icon: "success",
          title: "Success!",
          text: "Registration Successful! Please check your email to verify your account and complete the setup process.",
        });
        navigate("/Login");
      }
    } catch (err) {
      console.error("An unexpected error occurred:", err);
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setIsSigningUp(false); // Reset loading state
    }
  };

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    let { data, error } = await supabase.auth.signInWithOAuth({
      provider: "google",
    });
  };

  return (
    <div className="pt-16 bg-stone-100">
      <div className="md:w-3/5 flex items-center justify-center h-lvh mx-auto bg-white">
        <div className="w-full px-8 max-w-md">
          <h2 className="text-3xl font-semibold my-2">Sign Up</h2>
          <div className="text-start mt-1">
            Already have an account? Click here for{" "}
            <Link
              to="/login"
              className=" font-bold text-secondary-100 hover:text-blue-500"
            >
              login.
            </Link>
          </div>
          <div className="border border-spacing-0 border-secondary-200 opacity-40 mb-6"></div>
          <form onSubmit={handleSignup}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                EMAIL
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@address.com"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                PASSWORD
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="minimum 6 characters"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className=" pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? "Hide" : "Show"}
                <p className="text-sm ml-4 text-gray-500">
                  Password must be at least 6 characters long.
                </p>
              </button>
            </div>
            <div className="mb-6">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-semibold text-gray-700 mb-2"
              >
                CONFIRM PASSWORD
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="**********"
                className="mt-1 block w-full px-3 py-2 border border-gray-400 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
              {confirmPassword !== password && confirmPassword && (
                <div className="text-red-500 text-sm mt-2">
                  Passwords do not match.
                </div>
              )}
            </div>

            <div className="mb-4 flex items-center">
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="terms" className="text-sm text-gray-700">
                I agree to the{" "}
                <Link
                  to="/terms-and-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  Terms and Conditions
                </Link>
              </label>
            </div>

            <button
              type="submit"
              disabled={isSigningUp} // Disable button when signing up
              className={`w-full bg-secondary-100 text-white px-4 py-2 rounded-md hover:scale-105 transition duration-300 ${
                isSigningUp ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {isSigningUp ? "Signing up..." : "Sign Up"} {/* Feedback */}
            </button>
            <div className="flex justify-center">or Connect using</div>
            <div className="flex justify-center space-x-4 mt-2">
              <button
                onClick={handleGoogleLogin}
                className="w-10 h-10 flex items-center justify-center rounded-full border border-gray-300 shadow-sm"
              >
                <FcGoogle />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
