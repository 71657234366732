import React from "react";

const PersonalInfo = ({
  formData,
  handleChange,
  inputValue,
  setInputValue,
  handleAddLanguage,
  handleRemoveLanguage,
  languages,
  countries,
}) => (
  <div className="space-y-4">
    <div>
      <div className="text-center text-rose-400">
        The form will take you less than 5 minutes to complete.
      </div>
      <label className="block text-gray-700 ">
        Name<span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        name="Name"
        required
        value={formData.Name}
        onChange={handleChange}
        className="w-full p-2 border rounded "
      />
    </div>
    <div>
      <label className="block text-gray-700 ">
        Email<span className="text-red-500">*</span>
      </label>
      <input
        type="email"
        name="email"
        required
        placeholder="Use the same email as your signup"
        value={formData.email}
        onChange={handleChange}
        className="w-full p-2 border rounded "
      />
    </div>
    <div>
      <label className="block text-gray-700">Nationality</label>
      <div className="relative">
        <input
          type="text"
          name="nationality"
          placeholder="Search for a Country"
          value={formData.nationality}
          onChange={handleChange}
          className="w-full p-2 border rounded"
          list="nationality-list"
        />
        <datalist id="nationality-list">
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </datalist>
      </div>
    </div>
    <div>
      <label className="block text-gray-700">
        Current Country of Residence<span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        name="countryOfResidence"
        required
        placeholder="Search for a Country"
        value={formData.countryOfResidence}
        onChange={handleChange}
        className="w-full p-2 border rounded"
        list="country-list"
      />
      <datalist id="country-list" className="bg-white text-black">
        {countries.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </datalist>
    </div>
    <div className="">
      <label className=" text-gray-700">
        Languages Spoken<span className="text-red-500">*</span> (please provide
        at least one)
      </label>
      <input
        type="text"
        required
        name="languagesSpoken"
        placeholder="Select Languages"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        className="w-4/5 p-2 border rounded"
        list="languages-list"
      />
      <datalist id="languages-list">
        {languages.map((language) => (
          <option key={language} value={language}>
            {language}
          </option>
        ))}
      </datalist>
      <button
        type="button"
        onClick={handleAddLanguage}
        className="ml-4 p-2 px-3 bg-secondary-100 text-white rounded"
      >
        Add
      </button>
    </div>
    {formData.languagesSpoken.length > 0 && (
      <div className="mt-0">
        <p className="text-gray-700">Selected Languages:</p>
        <ul className="list-disc pl-5">
          {formData.languagesSpoken.map((language) => (
            <li key={language} className=" inline-block items-center">
              {language}
              <button
                type="button"
                onClick={() => handleRemoveLanguage(language)}
                className="ml-2 mr-3 text-red-500"
              >
                &times;
              </button>
            </li>
          ))}
        </ul>
      </div>
    )}
    <div>
      <label className="block text-gray-700">
        About Yourself (Optional - it will show on your mentor profile)
      </label>
      <textarea
        name="About_yourself"
        value={formData.About_yourself}
        onChange={handleChange}
        className="w-full p-2 border rounded"
      />
    </div>
    <div>
      <label className="block text-gray-700">
        Profile Picture (This will be shown on your mentor profile)
      </label>
      <input
        type="file"
        name="profile_pic"
        onChange={handleChange}
        className="w-full p-2 border rounded"
        accept="image/*"
      />
      {formData.profile_pic && (
        <img
          src={formData.profile_pic}
          alt="Profile Preview"
          className="mt-2 w-48 h-48 object-cover"
        />
      )}
    </div>
  </div>
);

export default PersonalInfo;
