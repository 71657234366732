import React, { useState } from "react";

function Undergraduate() {
  const [showInfo, setShowInfo] = useState("General");

  const undergrad_in_country = [
    {
      country: "General",
      steps: [
        {
          title: "Identify Your Academic and Career Goals",
          description:
            "Determine the field of study or specialization that aligns with your passions and career aspirations.",
        },
        {
          title: "Research University Rankings and Reputation",
          description:
            "Check global rankings (e.g., QS, THE) and ensure the university excels in your field of interest.",
        },
        {
          title: "Explore Course Structure and Curriculum",
          description:
            "Review the program’s curriculum and ensure it matches your academic and professional goals, including electives, research, and internships.",
        },
        {
          title: "Consider Location and Campus Life",
          description:
            "Choose a location based on personal preferences (city/town) and assess cost of living, campus culture, and extracurricular activities.",
        },
        {
          title: "Check Scholarship and Financial Aid Opportunities",
          description:
            "Explore tuition fees, living costs, and available scholarships to minimize your financial burden.",
        },
        {
          title: "Evaluate Career Services and Alumni Network",
          description:
            "Investigate career services, job placement assistance, and the strength of the alumni network for career opportunities post-graduation.",
        },
        {
          title: "Speak with Current Students and Alumni",
          description:
            "Seek insights from students/alumni through forums or mentor platforms like Uni-Bridge to learn about the academic and social experience.",
        },
        {
          title: "University’s Global Recognition and Industry Connections",
          description:
            "Ensure the university is globally recognized and has strong partnerships with industries and global companies for employability.",
        },
        {
          title: "Assess Admission Requirements",
          description:
            "Confirm that you meet the university’s academic, test score, and document requirements for admission.",
        },
      ],
    },
    {
      country: "US",
      steps: [
        {
          title: "Research and Shortlist Universities",
          description:
            "Identify universities and programs that match academic interests, career goals, and personal preferences. Check specific admission requirements and deadlines for each university.",
        },
        {
          title: "Prepare and Take Standardized Tests",
          description:
            "Take required tests such as SAT/ACT. Prove English proficiency with TOEFL/IELTS if required.",
        },
        {
          title: "Prepare Application Materials",
          description:
            "Gather transcripts, recommendation letters, personal statements, and resumes. Make sure all documents align with the specific requirements of each program.",
        },
        {
          title: "Apply Online",
          description:
            "Complete the university’s online application form. Upload all required documents and pay application fees.",
        },
        {
          title: "Submit Test Scores and Transcripts",
          description:
            "Send SAT/ACT, GRE/GMAT, TOEFL/IELTS scores directly to universities. Submit academic transcripts through the required process.",
        },
        {
          title: "Apply for Financial Aid (if needed)",
          description:
            "Submit scholarship, grant, or financial aid applications if needed. Look for external funding opportunities like Fulbright scholarships.",
        },
        {
          title: "Participate in Interviews (if required)",
          description:
            "Attend interviews if requested, usually via phone or video conferencing.",
        },
        {
          title: "Await Admission Decisions",
          description:
            "Wait for admission decisions through email or the university portal.",
        },
        {
          title: "Receive Admission Offer and Accept",
          description:
            "Accept the admission offer from your preferred university by confirming your enrollment and paying any deposits.",
        },
        {
          title: "Apply for a Student Visa",
          description:
            "Apply for a U.S. student visa using Form I-20 (F-1) or DS-2019 (J-1) provided by the university. Complete the DS-160 form and schedule a visa interview.",
        },
        {
          title: "Prepare for Arrival",
          description:
            "Arrange travel, accommodation, and attend any pre-departure or orientation sessions provided by the university.",
        },
        {
          title: "Enrollment",
          description:
            "Complete enrollment upon arrival at the university and participate in orientation sessions for international students.",
        },
      ],
    },
    {
      country: "UK",
      steps: [
        {
          title: "Research Universities and Programs",
          description:
            "Identify universities and programs that align with academic interests, career goals, and entry requirements. Look at rankings, courses offered, and student reviews for guidance.",
        },
        {
          title: "Check Entry Requirements",
          description:
            "Verify the academic qualifications, English language proficiency (IELTS/TOEFL), and additional criteria such as interviews or tests specific to the program.",
        },
        {
          title: "Prepare and Take Standardized Tests",
          description:
            "Complete required English language tests such as IELTS, TOEFL, or PTE Academic, if not exempted by prior qualifications.",
        },
        {
          title: "Complete UCAS Application",
          description:
            "For undergraduate programs, apply via UCAS (Universities and Colleges Admissions Service) portal",
        },
        {
          title: "Prepare Supporting Documents",
          description:
            "Gather academic transcripts, recommendation letters, personal statements, and a CV. For certain courses, a portfolio or audition might be required.",
        },
        {
          title: "Submit Application Fees",
          description:
            "Pay the required application fees either through UCAS or the university’s individual application system.",
        },
        {
          title: "Apply for Scholarships or Financial Aid",
          description:
            "Explore scholarships, grants, or bursaries provided by the universities or external bodies. Some scholarships may require additional essays or interviews.",
        },
        {
          title: "Attend Interviews (if required)",
          description:
            "Some programs, particularly in fields like medicine, law, or art and design, may require interviews, either online or in-person.",
        },
        {
          title: "Receive and Accept Offers",
          description:
            "Upon receiving offers (conditional or unconditional), decide which offer to accept. Confirm the offer through UCAS (for undergraduate) or directly with the university (postgraduate).",
        },
        {
          title: "Apply for a UK Student Visa",
          description:
            "Use your Confirmation of Acceptance for Studies (CAS) from the university to apply for a UK student visa (Tier 4). Prepare supporting documents and attend a visa interview.",
        },
        {
          title: "Arrange Accommodation",
          description:
            "Arrange university accommodation or private housing. Some universities provide guaranteed housing for first-year students.",
        },
        {
          title: "Prepare for Arrival",
          description:
            "Book flights and prepare for pre-departure orientations. Understand living expenses, health insurance (UK's NHS), and other practical aspects of studying in the UK.",
        },
        {
          title: "Enroll at the University",
          description:
            "Complete the final enrollment process upon arrival, attend orientation sessions, and begin your academic program.",
        },
      ],
    },
    {
      country: "Canada",
      steps: [
        {
          title: "Research Programs",
          description:
            "Identify courses/programs that align with interests & goals. Check admission requirements and fees.",
        },
        {
          title: "Verify Eligibility & Prepare Docs",
          description:
            "Confirm academic requirements, including specific high school courses, GPA, language proficiency. Gather transcripts, SOP, LORs, and language proof.",
        },
        {
          title: "Language Proficiency Test",
          description:
            "Take IELTS/TOEFL for English proficiency or French equivalent if required. Send results to universities.",
        },
        {
          title: "Create Application Profile",
          description:
            "Use the university's online portal or centralized platform (e.g., OUAC for Ontario) to create an account and select programs.",
        },
        {
          title: "Submit Application & Pay Fees",
          description:
            "Complete online application form for chosen programs. Pay application fee (CAD 100-200).",
        },
        {
          title: "Upload Documents",
          description:
            "Upload necessary documents like transcripts, test scores, LORs, and proof of language proficiency. Ensure certified copies/translated docs if needed.",
        },
        {
          title: "Track Application Status",
          description:
            "Receive a confirmation email; use the online portal to track application status.",
        },
        {
          title: "Wait for Admission Decision",
          description:
            "Wait a few weeks/months for decision. Check portals regularly for updates.",
        },
        {
          title: "Accept Offer",
          description:
            "If accepted, receive an offer letter. Accept the offer via the portal and pay the deposit to secure enrollment.",
        },
        {
          title: "Apply for Study Permit/Visa",
          description:
            "Apply for a study permit with the acceptance letter, proof of funds, passport, etc. through the IRCC.",
        },
        {
          title: "Arrange Accommodation & Travel",
          description:
            "Explore on/off-campus housing. Book travel and ensure all documents for entry into Canada are ready.",
        },
      ],
    },
    {
      country: "Australia",
      steps: [
        {
          title: "Research Programs",
          description:
            "Explore courses that match your interests & career goals on university websites or Australian education portals. Check entry requirements, fees, and intakes.",
        },
        {
          title: "Verify Eligibility & Prepare Docs",
          description:
            "Confirm eligibility (GPA, specific high school subjects, equivalent qualifications). Prepare necessary documents including transcripts, language proficiency, and statement of purpose (SOP).",
        },
        {
          title: "Language Proficiency Test",
          description:
            "Take English proficiency tests such as IELTS/TOEFL/PTE if needed. Submit results as required by university programs.",
        },
        {
          title: "Create Application Profile",
          description:
            "Use university websites, or centralized application services like UAC or VTAC (for some states) to create an application account.",
        },
        {
          title: "Submit Application & Pay Fees",
          description:
            "Fill in the application form for selected programs. Pay application fees (typically AUD 100-150 per university).",
        },
        {
          title: "Upload Documents",
          description:
            "Submit supporting documents such as academic transcripts, test scores, LORs, and SOP through the application portal. Make sure all documents are certified or translated if needed.",
        },
        {
          title: "Track Application Status",
          description:
            "Receive application acknowledgment via email. Track status through the application portal or via regular updates from the university.",
        },
        {
          title: "Wait for Admission Decision",
          description:
            "Admission decisions can take a few weeks to months. Keep checking the application portal for updates.",
        },
        {
          title: "Accept Offer",
          description:
            "Once offered a place, confirm acceptance by paying a deposit and completing any other formalities. Receive the 'Confirmation of Enrolment (CoE).'",
        },
        {
          title: "Apply for Student Visa",
          description:
            "Apply for an Australian student visa (subclass 500) through the Department of Home Affairs using the CoE, proof of funds, and health insurance documents.",
        },
        {
          title: "Arrange Accommodation & Travel",
          description:
            "Secure on-campus or off-campus accommodation. Plan your travel to Australia and ensure all entry documents are prepared.",
        },
      ],
    },
    {
      country: "Europe",
      steps: [
        {
          title: "Research Universities and Programs",
          description:
            "Identify universities and programs that fit your academic and career goals. Research entry requirements, program details, fees, and rankings. Look for information on study portals.",
        },
        {
          title: "Check Entry Requirements",
          description:
            "Ensure you meet the academic qualifications, language requirements (English, French, German, etc.), and any program-specific criteria like entrance exams or portfolios.",
        },
        {
          title: "Prepare Language Proficiency Tests",
          description:
            "Complete English proficiency tests such as IELTS, TOEFL, or country-specific language tests (e.g., DELF for French, TestDaF for German).",
        },
        {
          title: "Submit University Applications",
          description:
            "Apply via the university’s application portal or a national platform (e.g.- Studielink in the Netherlands, Uni-Assist in Germany).",
        },
        {
          title: "Prepare Supporting Documents",
          description:
            "Collect required documents such as academic transcripts, proof of language proficiency, a CV, recommendation letters, and a statement of purpose or motivation letter.",
        },
        {
          title: "Application Fees",
          description:
            "Pay the application fees, if required, through the university’s online application system or the national application platform.",
        },
        {
          title: "Apply for Scholarships or Financial Aid",
          description:
            "Research and apply for available scholarships or financial aid opportunities that can help cover tuition fees or living expenses.",
        },
        {
          title: "Wait for Admission Results",
          description:
            "After applying, wait for conditional or unconditional offers. Universities may take several weeks to respond depending on the program and country.",
        },
        {
          title: "Accept Offer and Apply for Student Visa",
          description:
            "After accepting the offer, gather documents (including proof of admission and financial resources) to apply for a student visa at the country’s embassy or consulate.",
        },
        {
          title: "Arrange Accommodation",
          description:
            "Arrange for university housing or private accommodation. Some universities offer assistance with housing for international students.",
        },
        {
          title: "Health Insurance and Financial Planning",
          description:
            "Ensure you have valid health insurance that complies with local regulations. Plan for living expenses and explore part-time work options available for students.",
        },
        {
          title: "Travel and Enroll",
          description:
            "Book travel to the destination country, attend orientation sessions, and complete your university enrollment after arrival.",
        },
      ],
    },
  ];

  return (
    <div className="pt-20 min-h-screen md:p-6">
      <div className="mx-auto p-8">
        <div className="min-h-72 md:my-16 bg-primary shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 rotate-6 md:rotate-3 transform"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white text-center mb-6 relative z-10">
            Undergraduate
          </h1>
        </div>
        <div className="flex justify-center mx-auto text-center text-secondary-200 text-xl md:text-3xl font-bold px-8 pt-12 pb-6">
          Choose the country
        </div>
        <div className="flex justify-center flex-wrap item-center text-sm text-white font-semibold md:text-base gap-3 md:gap-6">
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "General" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "General" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("General")}
          >
            General
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "US" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "US" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("US")}
          >
            US
          </button>

          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "UK" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "UK" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("UK")}
          >
            UK
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Canada" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Canada" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Canada")}
          >
            Canada
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Europe" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Europe" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Europe")}
          >
            Europe
          </button>

          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Australia" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Australia" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Australia")}
          >
            Australia
          </button>

          {showInfo !== "General" && (
            <div className="pl-6 mt-6 text-gray-600 text-base font-normal md:w-2/3 mx-auto">
              <h2 className="my-6 text-center text-secondary-100 text-xl md:text-3xl font-bold">
                STEPS FOR ADMISSION IN {showInfo}
              </h2>
              {undergrad_in_country
                .filter((country) => country.country === showInfo)
                .map((country) =>
                  country.steps.map((step, index) => (
                    <div key={index} className="relative">
                      <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
                      <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                        <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                          STEP {index + 1}
                        </span>
                        {step.title}
                      </div>
                      <p className="pl-4 pb-4 md:pl-12">{step.description}</p>
                    </div>
                  ))
                )}
            </div>
          )}
          {showInfo === "General" && (
            <div className="pl-6 mt-6 text-gray-600 text-base font-normal md:w-2/3 mx-auto">
              <h2 className="my-6 text-center text-secondary-100 text-xl md:text-3xl font-bold">
                GENERAL STEPS
              </h2>
              {undergrad_in_country
                .filter((country) => country.country === showInfo)
                .map((country) =>
                  country.steps.map((step, index) => (
                    <div key={index} className="relative">
                      <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
                      <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                        <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                          STEP {index + 1}
                        </span>
                        {step.title}
                      </div>
                      <p className="pl-4 pb-4 md:pl-12">{step.description}</p>
                    </div>
                  ))
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Undergraduate;
