import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
const MenteePreferences = ({
  formData,
  setFormData,
  inputuniValue,
  setInputUniValue,
  handleRemoveUniversity,
  Degrees,
  Courses,
  StudyStartOptions,
  Universitylist,
  countries,
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-gray-700">
          Select Degree/Course for mentorship.
          <span className="text-red-500">*</span>
        </label>
        <select
          name="degreeAspiration"
          value={formData.careerPreferences.degreeAspiration}
          onChange={(e) =>
            setFormData({
              ...formData,
              careerPreferences: {
                ...formData.careerPreferences,
                degreeAspiration: e.target.value,
              },
            })
          }
          className="w-full p-2 border rounded"
        >
          {Degrees.map((degree) => (
            <option key={degree} value={degree}>
              {degree}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label className="flex items-center text-gray-700">
          When would you like to start your studies?
          <span className="text-red-500">*</span>
          <span className="ml-1 text-gray-500 cursor-pointer group relative">
            <IoIosInformationCircleOutline />
            <span className="absolute right-0 p-2 text-sm bg-white md:min-w-96 text-gray-800 rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100 pointer-events-none">
              Please note that the start date varies depending upon the course
              and the university. Most of the courses across universities begin
              in September or October every year
            </span>
          </span>
        </label>
        <select
          name="studyStart"
          value={formData.careerPreferences.studyStart}
          onChange={(e) =>
            setFormData({
              ...formData,
              careerPreferences: {
                ...formData.careerPreferences,
                studyStart: e.target.value,
              },
            })
          }
          className="w-full p-2 border rounded"
        >
          {StudyStartOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-gray-700">
          Have you decided a field of study ?
          <span className="text-red-500">*</span>
        </label>
        <div>
          <select
            name="courseDecided"
            value={formData.careerPreferences.courseDecided}
            onChange={(e) =>
              setFormData({
                ...formData,
                careerPreferences: {
                  ...formData.careerPreferences,
                  courseDecided: e.target.value,
                },
              })
            }
            className="w-full p-2 border rounded"
          >
            <option defaultValue="Select">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
          {formData.careerPreferences.courseDecided === "Yes" && (
            <div className="py-2">
              <input
                type="text"
                name="courseName"
                placeholder="Search the field"
                value={formData.careerPreferences.courseName}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    careerPreferences: {
                      ...formData.careerPreferences,
                      courseName: e.target.value,
                    },
                  })
                }
                className="w-full p-2 border rounded"
                list="courses-list"
              />
              <datalist id="courses-list">
                {Courses.map((course) => (
                  <option key={course.subject_id} value={course.subjects}>
                    {course.subjects}
                  </option>
                ))}
              </datalist>
            </div>
          )}
        </div>
        {formData.careerPreferences.courseDecided === "No" && (
          <p className="text-secondary-100">
            Don't worry mentor will guide you for that
          </p>
        )}
      </div>

      <div>
        <label className="block text-gray-700">
          Do you have any country preference?
        </label>
        <input
          type="text"
          name="countryPreference"
          placeholder="Enter the country name"
          value={formData.careerPreferences.countryPreference}
          onChange={(e) =>
            setFormData({
              ...formData,
              careerPreferences: {
                ...formData.careerPreferences,
                countryPreference: e.target.value,
              },
            })
          }
          className="w-full p-2 border rounded"
          list="country-list"
        />
        <datalist id="country-list">
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </datalist>
      </div>

      <div>
        <label className="block text-gray-700">
          Do you have university preference?
        </label>
        <input
          type="text"
          name="universityPreferences"
          placeholder="Enter the university name"
          value={inputuniValue}
          onChange={(e) => {
            setInputUniValue(e.target.value);
          }}
          className="w-full p-2 border rounded"
          list="university-list"
        />
        <datalist id="university-list">
          {Universitylist.map((university) => (
            <option key={university} value={university}>
              {university}
            </option>
          ))}
        </datalist>
        <button
          type="button"
          onClick={() => {
            if (
              Universitylist.includes(inputuniValue) &&
              !formData.careerPreferences.universityPreferences.includes(
                inputuniValue
              )
            ) {
              setFormData({
                ...formData,
                careerPreferences: {
                  ...formData.careerPreferences,
                  universityPreferences: [
                    ...formData.careerPreferences.universityPreferences,
                    inputuniValue,
                  ],
                },
              });
              setInputUniValue("");
            }
          }}
          className="ml-4 mt-2 p-2 px-3 bg-secondary-100 text-white rounded"
        >
          Add
        </button>
      </div>
      {formData.careerPreferences.universityPreferences.length > 0 && (
        <div className="mt-0">
          <p className="text-gray-700">Preferred university:</p>
          <ul className="list-disc pl-5">
            {formData.careerPreferences.universityPreferences.map(
              (university) => (
                <li key={university} className=" inline-block items-center">
                  {university}
                  <button
                    type="button"
                    onClick={() => handleRemoveUniversity(university)}
                    className="ml-2 mr-3 text-red-500"
                  >
                    &times;
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      )}
      <div>
        <label className="block text-gray-700">
          Provide CV link (Optional)
        </label>
        <input
          type="text"
          name="CV"
          placeholder="share the link of your CV from any online drive "
          onChange={(e) =>
            setFormData({
              ...formData,
              CV: e.target.value,
            })
          }
          className="w-full p-2 border rounded"
        />
      </div>
    </div>
  );
};

export default MenteePreferences;
