import React from "react";

function TermsNconditions() {
  const sections = [
    { id: 1, title: "Platform Overview" },
    { id: 2, title: "User Eligibility" },
    { id: 3, title: "User Responsibilities" },
    { id: 4, title: "Mentorship Services" },
    { id: 5, title: "Payments and Fees" },
    { id: 6, title: "Account Termination" },
    { id: 7, title: "Intellectual Property" },
    { id: 8, title: "Confidentiality and Privacy" },
    { id: 9, title: "Limitation of Liability" },
    { id: 10, title: "Indemnification" },
    { id: 11, title: "Third-Party Links" },
    { id: 12, title: "Governing Law and Jurisdiction" },
    { id: 13, title: "Changes to Terms" },
    { id: 14, title: "Contact Information" },
  ];

  return (
    <div className="pt-24 p-6 max-w-4xl mx-auto">
      <h1 className=" text-3xl text-center font-bold text-primary mb-6">
        Unibridge General Terms & Conditions
      </h1>

      {/* Table of Contents */}
      <nav className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
        <ul className="list-disc list-inside space-y-2">
          {sections.map((section) => (
            <li key={section.id}>
              <a
                href={`#section-${section.id}`}
                className="text-blue-600 hover:underline"
              >
                {section.title}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      {/* Terms Sections */}
      {sections.map((section) => (
        <section key={section.id} id={`section-${section.id}`} className="mb-6">
          <h2 className="text-2xl font-semibold text-secondary mb-2">
            {section.id}. {section.title}
          </h2>
          <p className="mb-4">
            {/* Placeholder for section content */}
            {getSectionContent(section.id)}
          </p>
        </section>
      ))}
    </div>
  );
}

/**
 * Function to retrieve content for each section.
 * Replace the placeholder text with actual content.
 */
function getSectionContent(id) {
  const content = {
    1: `Uni-Bridge is an online platform that connects students ("Mentees") with mentors ("Mentors") for guidance and assistance in applying for higher education at global institutions. The platform provides resources, content, and a network of Mentors to help students with their educational aspirations.`,
    2: `To use Uni-Bridge, you must be at least 18 years of age or have parental/guardian consent to use the platform. You must provide accurate and current information during the registration process. False or misleading information may lead to account termination.`,
    3: `**Mentees:** You agree to use Uni-Bridge services solely for the purpose of higher education mentoring and guidance. You are responsible for actively engaging in sessions and providing necessary information to the Mentor.

    **Mentors:** You agree to provide accurate, helpful, and ethical guidance to Mentees. You must not guarantee admission to any institution or mislead Mentees with false information.`,
    4: `Uni-Bridge facilitates mentorship sessions between Mentees and Mentors. These sessions may include advice on university selection, application processes, essay reviews, and interview preparation. The platform does not guarantee admission to any educational institution or program. The quality and accuracy of mentorship services depend on the information shared by both the Mentor and Mentee.`,
    5: `Fees for mentorship sessions are specified on the platform. These may be paid per session or as part of a package. Payments must be made through the Uni-Bridge platform. Any payment made outside of the platform is not recognized by Uni-Bridge and may result in loss of services. Refunds for sessions may be available only in cases where the Mentor or Uni-Bridge cancels the session. Other refund requests are subject to the platform’s discretion.`,
    6: `Uni-Bridge reserves the right to terminate user accounts if there is a violation of these Terms, including inappropriate behavior, providing false information, or misuse of the platform. Users may also terminate their accounts at any time by contacting Uni-Bridge customer support.`,
    7: `All content, including but not limited to text, images, videos, and logos on the Uni-Bridge platform, is owned by or licensed to Uni-Bridge and is protected under intellectual property laws. Users may not reproduce, distribute, or otherwise exploit any content without the prior written consent of Uni-Bridge.`,
    8: `Uni-Bridge is committed to protecting the privacy of its users. The platform will collect, use, and store personal data in accordance with its Privacy Policy. Mentees and Mentors must not share confidential information obtained during mentorship sessions without prior consent.`,
    9: `Uni-Bridge does not guarantee the accuracy, completeness, or reliability of the information provided by Mentors. The platform shall not be liable for any direct, indirect, incidental, or consequential damages arising from the use of Uni-Bridge services.`,
    10: `Users agree to indemnify and hold Uni-Bridge harmless from any claims, damages, or expenses arising out of the use of the platform, violation of these Terms, or infringement of third-party rights.`,
    11: `The Uni-Bridge platform may contain links to third-party websites or services. These are provided for convenience only, and Uni-Bridge does not endorse or assume responsibility for any content on third-party sites.`,
    12: `These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Uni-Bridge operates. Any disputes arising from these Terms shall be resolved through arbitration or in the courts of the jurisdiction where Uni-Bridge is based.`,
    13: `Uni-Bridge reserves the right to modify these Terms at any time. Users will be notified of any significant changes, and continued use of the platform constitutes acceptance of the updated Terms.`,
    14: `For any questions or concerns regarding these Terms, please contact Uni-Bridge at support@unibridge.com. These Terms are intended to provide a safe and positive experience for both Mentees and Mentors on the Uni-Bridge platform. By using the platform, you agree to adhere to these guidelines and ensure a constructive environment for all.`,
  };

  return content[id] || "Content not available.";
}

export default TermsNconditions;
