import React, { useState } from "react";

function Postgraduate() {
  const [showInfo, setShowInfo] = useState("General");

  const postgrad_in_country = [
    {
      country: "General",
      steps: [
        {
          title: "Identify Your Academic and Career Goals",
          description:
            "Determine the field of study or specialization that aligns with your passions and career aspirations.",
        },
        {
          title: "Research University Rankings and Reputation",
          description:
            "Check global rankings (e.g., QS, THE) and ensure the university excels in your field of interest.",
        },
        {
          title: "Explore Course Structure and Curriculum",
          description:
            "Review the program’s curriculum and ensure it matches your academic and professional goals, including electives, research, and internships.",
        },
        {
          title: "Consider Location and Campus Life",
          description:
            "Choose a location based on personal preferences (city/town) and assess cost of living, campus culture, and extracurricular activities.",
        },
        {
          title: "Check Scholarship and Financial Aid Opportunities",
          description:
            "Explore tuition fees, living costs, and available scholarships to minimize your financial burden.",
        },
        {
          title: "Evaluate Career Services and Alumni Network",
          description:
            "Investigate career services, job placement assistance, and the strength of the alumni network for career opportunities post-graduation.",
        },
        {
          title: "Speak with Current Students and Alumni",
          description:
            "Seek insights from students/alumni through forums or mentor platforms like Uni-Bridge to learn about the academic and social experience.",
        },
        {
          title: "University’s Global Recognition and Industry Connections",
          description:
            "Ensure the university is globally recognized and has strong partnerships with industries and global companies for employability.",
        },
        {
          title: "Assess Admission Requirements",
          description:
            "Confirm that you meet the university’s academic, test score, and document requirements for admission.",
        },
      ],
    },
    {
      country: "US",
      steps: [
        {
          title: "Research and Shortlist Universities",
          description:
            "Identify universities and programs that match academic interests, career goals, and personal preferences. Check specific admission requirements and deadlines for each university.",
        },
        {
          title: "Prepare and Take Standardized Tests",
          description:
            "Take required tests such as GRE/GMAT. Prove English proficiency with TOEFL/IELTS if required.",
        },
        {
          title: "Prepare Application Materials",
          description:
            "Gather transcripts, recommendation letters, personal statements, and resumes. Make sure all documents align with the specific requirements of each program.",
        },
        {
          title: "Apply Online",
          description:
            "Complete the university’s online application form. Upload all required documents and pay application fees.",
        },
        {
          title: "Submit Test Scores and Transcripts",
          description:
            "Send SAT/ACT, GRE/GMAT, TOEFL/IELTS scores directly to universities. Submit academic transcripts through the required process.",
        },
        {
          title: "Apply for Financial Aid (if needed)",
          description:
            "Submit scholarship, grant, or financial aid applications if needed. Look for external funding opportunities like Fulbright scholarships.",
        },
        {
          title: "Participate in Interviews (if required)",
          description:
            "Attend interviews if requested, usually via phone or video conferencing.",
        },
        {
          title: "Await Admission Decisions",
          description:
            "Wait for admission decisions through email or the university portal.",
        },
        {
          title: "Receive Admission Offer and Accept",
          description:
            "Accept the admission offer from your preferred university by confirming your enrollment and paying any deposits.",
        },
        {
          title: "Apply for a Student Visa",
          description:
            "Apply for a U.S. student visa using Form I-20 (F-1) or DS-2019 (J-1) provided by the university. Complete the DS-160 form and schedule a visa interview.",
        },
        {
          title: "Prepare for Arrival",
          description:
            "Arrange travel, accommodation, and attend any pre-departure or orientation sessions provided by the university.",
        },
        {
          title: "Enrollment",
          description:
            "Complete enrollment upon arrival at the university and participate in orientation sessions for international students.",
        },
      ],
    },
    {
      country: "UK",
      steps: [
        {
          title: "Research Universities and Programs",
          description:
            "Identify universities and programs that align with academic interests, career goals, and entry requirements. Look at rankings, courses offered, and student reviews for guidance.",
        },
        {
          title: "Check Entry Requirements",
          description:
            "Verify the academic qualifications, English language proficiency (IELTS/TOEFL), and additional criteria such as interviews or tests specific to the program.",
        },
        {
          title: "Prepare and Take Standardized Tests",
          description:
            "Complete required English language tests such as IELTS, TOEFL, or PTE Academic, if not exempted by prior qualifications.",
        },
        {
          title: "Complete Application",
          description:
            "Postgraduate applicants apply directly via the university's application portal.",
        },
        {
          title: "Prepare Supporting Documents",
          description:
            "Gather academic transcripts, recommendation letters, personal statements, and a CV. For certain courses, a portfolio or audition might be required.",
        },
        {
          title: "Submit Application Fees",
          description:
            "Pay the required application fees either through UCAS or the university’s individual application system.",
        },
        {
          title: "Apply for Scholarships or Financial Aid",
          description:
            "Explore scholarships, grants, or bursaries provided by the universities or external bodies. Some scholarships may require additional essays or interviews.",
        },
        {
          title: "Attend Interviews (if required)",
          description:
            "Some programs, particularly in fields like medicine, law, or art and design, may require interviews, either online or in-person.",
        },
        {
          title: "Receive and Accept Offers",
          description:
            "Upon receiving offers (conditional or unconditional), decide which offer to accept. Confirm the offer through UCAS (for Postgraduate) or directly with the university (postgraduate).",
        },
        {
          title: "Apply for a UK Student Visa",
          description:
            "Use your Confirmation of Acceptance for Studies (CAS) from the university to apply for a UK student visa (Tier 4). Prepare supporting documents and attend a visa interview.",
        },
        {
          title: "Arrange Accommodation",
          description:
            "Arrange university accommodation or private housing. Some universities provide guaranteed housing for first-year students.",
        },
        {
          title: "Prepare for Arrival",
          description:
            "Book flights and prepare for pre-departure orientations. Understand living expenses, health insurance (UK's NHS), and other practical aspects of studying in the UK.",
        },
        {
          title: "Enroll at the University",
          description:
            "Complete the final enrollment process upon arrival, attend orientation sessions, and begin your academic program.",
        },
      ],
    },
    {
      country: "Canada",
      steps: [
        {
          title: "Research Programs",
          description:
            "Identify courses/programs that align with interests & goals. Check admission requirements and fees.",
        },
        {
          title: "Verify Eligibility & Prepare Docs",
          description:
            "Ensure you meet academic requirements (degree, GPA). Prepare transcripts, CV, SOP, LORs, research proposals. Check GRE/GMAT needs.",
        },
        {
          title: "Language Proficiency Test",
          description:
            "Take IELTS/TOEFL for English proficiency or French equivalent if required. Send results to universities.",
        },
        {
          title: "Create Application Profile",
          description:
            "Use specific university portals to apply. Fill in details and upload documents.",
        },
        {
          title: "Submit Application & Pay Fees",
          description:
            "Submit application, upload documents, and pay the application fee (CAD 100-150).",
        },
        {
          title: "Upload Documents",
          description:
            "Upload required documents, including research proposal (for research-based programs).",
        },
        {
          title: "Track Application Status",
          description:
            "Use the university portal to track the status of the application; be prepared for interviews.",
        },
        {
          title: "Wait for Admission Decision",
          description:
            "Wait for a decision (can take several weeks to months depending on the program).",
        },
        {
          title: "Accept Offer",
          description:
            "If accepted, receive an offer letter. Accept the offer via the portal and pay the deposit to secure enrollment.",
        },
        {
          title: "Apply for Study Permit/Visa",
          description:
            "Apply for a Canadian study permit once accepted using the acceptance letter and other documents.",
        },
        {
          title: "Arrange Accommodation & Travel",
          description:
            "Explore on/off-campus housing. Book travel and ensure all documents for entry into Canada are ready.",
        },
      ],
    },
    {
      country: "Australia",
      steps: [
        {
          title: "Research Programs",
          description:
            "Explore suitable graduate programs, research options, and entry requirements, considering supervisor availability (if required).",
        },
        {
          title: "Verify Eligibility & Prepare Docs",
          description:
            "Check academic prerequisites (GPA, degree equivalence). Prepare documents like CV, academic transcripts, SOP, LORs, and research proposals for specific programs.",
        },
        {
          title: "Language Proficiency Test",
          description:
            "Take language proficiency tests like IELTS/TOEFL if required. Prepare for other standardized tests (e.g., GRE/GMAT) as per program requirements.",
        },
        {
          title: "Create Application Profile",
          description:
            "Apply directly through university portals or use state-specific application services (where applicable).",
        },
        {
          title: "Submit Application & Pay Fees",
          description:
            "Submit complete application form with relevant documents and pay the application fee (AUD 100-150 per university).",
        },
        {
          title: "Upload Documents",
          description:
            "Upload all required documents including CV, research proposal, and LORs (for research-based programs).",
        },
        {
          title: "Track Application Status",
          description:
            "Track the progress of the application through university updates or online portal. Some programs may require interviews or additional documentation.",
        },
        {
          title: "Wait for Admission Decision",
          description:
            "Wait for the admission decision. The timeline can vary based on program complexity and intake periods.",
        },
        {
          title: "Accept Offer",
          description:
            "Accept the offer by completing the required paperwork and paying the initial fee deposit. Receive a CoE for the student visa application.",
        },
        {
          title: "Apply for Student Visa",
          description:
            "Apply for a student visa (subclass 500) with the required documents like CoE, financial proof, and Overseas Student Health Cover (OSHC).",
        },
        {
          title: "Arrange Accommodation & Travel",
          description:
            "Arrange for housing and travel arrangements. Make sure all visa and arrival documents are in place before travel.",
        },
      ],
    },

    {
      country: "Europe",
      steps: [
        {
          title: "Research Universities and Programs",
          description:
            "Identify universities and programs that align with your academic and career aspirations. Investigate entry requirements, program specifics, fees, and rankings. Utilize study portals for comprehensive information.",
        },
        {
          title: "Check Entry Requirements",
          description:
            "Verify that you meet the academic qualifications, language requirements (English, French, German, etc.), and any specific criteria such as entrance exams or portfolios required by the program.",
        },
        {
          title: "Prepare Language Proficiency Tests",
          description:
            "Complete necessary English proficiency tests like IELTS, TOEFL, or country-specific language assessments (e.g., DELF for French, TestDaF for German).",
        },
        {
          title: "Submit University Applications",
          description:
            "Postgraduate applicants should apply directly via the university's application portal.",
        },
        {
          title: "Prepare Supporting Documents",
          description:
            "Assemble necessary documents including academic transcripts, language proficiency evidence, a CV, recommendation letters, and a statement of purpose or motivation letter.",
        },
        {
          title: "Application Fees",
          description:
            "If applicable, remit the application fees through the university’s online application system or the respective national application platform.",
        },
        {
          title: "Apply for Scholarships or Financial Aid",
          description:
            "Investigate and apply for scholarships or financial aid that can assist with tuition fees and living expenses.",
        },
        {
          title: "Wait for Admission Results",
          description:
            "Post-application, await conditional or unconditional offers. Response times may vary by program and country.",
        },
        {
          title: "Accept Offer and Apply for Student Visa",
          description:
            "Upon offer acceptance, compile necessary documents (including proof of admission and financial resources) for a student visa application at the respective country’s embassy or consulate.",
        },
        {
          title: "Arrange Accommodation",
          description:
            "Secure university housing or private accommodation. Assistance may be available from universities for international students.",
        },
        {
          title: "Health Insurance and Financial Planning",
          description:
            "Confirm that you have valid health insurance meeting local requirements. Budget for living expenses and consider part-time employment opportunities for students.",
        },
        {
          title: "Travel and Enroll",
          description:
            "Organize travel to the host country, participate in orientation sessions, and finalize your university enrollment upon arrival.",
        },
      ],
    },
  ];

  return (
    <div className="pt-20 min-h-screen md:p-6">
      <div className="mx-auto p-8">
        <div className="min-h-72 md:my-16 bg-primary shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 -rotate-6 md:-rotate-3 transform"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white text-center mb-6 relative z-10">
            Postgraduate
          </h1>
        </div>
        <div className="flex justify-center mx-auto text-center text-secondary-200 text-xl md:text-3xl font-bold px-8 pt-12 pb-6">
          Choose the country
        </div>
        <div className="flex justify-center flex-wrap item-center text-sm text-white font-semibold md:text-base gap-3 md:gap-6">
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "General" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "General" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("General")}
          >
            General
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "US" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "US" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("US")}
          >
            US
          </button>

          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "UK" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "UK" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("UK")}
          >
            UK
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Canada" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Canada" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Canada")}
          >
            Canada
          </button>
          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Europe" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Europe" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Europe")}
          >
            Europe
          </button>

          <button
            className={`px-4 py-2 w-20 ${
              showInfo === "Australia" ? "active" : ""
            } flex justify-center text-center hover:scale-105 ${
              showInfo === "Australia" ? "bg-primary" : "bg-secondary-500"
            } rounded-md transition duration-300`}
            onClick={() => setShowInfo("Australia")}
          >
            Australia
          </button>

          {showInfo !== "General" && (
            <div className="pl-6 mt-6 text-gray-600 text-base font-normal md:w-2/3 mx-auto">
              <h2 className="my-6 text-center text-secondary-100 text-xl md:text-3xl font-bold">
                STEPS FOR ADMISSION IN {showInfo}
              </h2>
              {postgrad_in_country
                .filter((country) => country.country === showInfo)
                .map((country) =>
                  country.steps.map((step, index) => (
                    <div key={index} className="relative">
                      <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
                      <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                        <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                          STEP {index + 1}
                        </span>
                        {step.title}
                      </div>
                      <p className="pl-4 pb-4 md:pl-12">{step.description}</p>
                    </div>
                  ))
                )}
            </div>
          )}
          {showInfo === "General" && (
            <div className="pl-6 mt-6 text-gray-600 text-base font-normal md:w-2/3 mx-auto">
              <h2 className="my-6 text-center text-secondary-100 text-xl md:text-3xl font-bold">
                GENERAL STEPS
              </h2>
              {postgrad_in_country
                .filter((country) => country.country === showInfo)
                .map((country) =>
                  country.steps.map((step, index) => (
                    <div key={index} className="relative">
                      <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
                      <div className="font-bold text-secondary-200 mb-4 pl-16 flex items-center text-lg md:text-xl py-2 pt-10">
                        <span className="absolute -left-8 bg-primary text-stone-200 md:text-lg font-bold p-2 rounded-xl shadow-lg shadow-gray-400">
                          STEP {index + 1}
                        </span>
                        {step.title}
                      </div>
                      <p className="pl-4 pb-4 md:pl-12">{step.description}</p>
                    </div>
                  ))
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Postgraduate;
