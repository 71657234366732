export const Universitylist = [
  "Massachusetts Institute of Technology (MIT) ",
  "Imperial College London",
  "University of Oxford",
  "Harvard University",
  "University of Cambridge",
  "Stanford University",
  "ETH Zurich - Swiss Federal Institute of Technology",
  "National University of Singapore (NUS)",
  "UCL",
  "California Institute of Technology (Caltech)",
  "University of Pennsylvania",
  "University of California, Berkeley (UCB)",
  "The University of Melbourne",
  "Peking University",
  "Nanyang Technological University, Singapore (NTU)",
  "Cornell University",
  "The University of Hong Kong",
  "The University of Sydney",
  "The University of New South Wales (UNSW Sydney)",
  "Tsinghua University",
  "University of Chicago",
  "Princeton University",
  "Yale University",
  "Université PSL",
  "University of Toronto",
  "EPFL",
  "The University of Edinburgh",
  "Technical University of Munich",
  "McGill University",
  "The Australian National University",
  "Seoul National University",
  "The University of Tokyo",
  "Johns Hopkins University",
  "The University of Manchester",
  "Columbia University",
  "The Chinese University of Hong Kong (CUHK)",
  "Monash University",
  "University of British Columbia",
  "Fudan University",
  "The University of Queensland",
  "King's College London",
  "University of California, Los Angeles (UCLA)",
  "New York University (NYU)",
  "University of Michigan-Ann Arbor",
  "Shanghai Jiao Tong University",
  "Institut Polytechnique de Paris",
  "Zhejiang University",
  "The Hong Kong University of Science and Technology",
  "Delft University of Technology",
  "Kyoto University",
  "The London School of Economics and Political Science (LSE)",
  "Northwestern University",
  "KAIST - Korea Advanced Institute of Science & Technology",
  "University of Bristol",
  "University of Amsterdam",
  "Yonsei University",
  "The Hong Kong Polytechnic University",
  "Carnegie Mellon University",
  "Ludwig-Maximilians-Universität München",
  "Universiti Malaya (UM)",
  "Duke University",
  "City University of Hong Kong",
  "KU Leuven",
  "Sorbonne University",
  "The University of Auckland",
  "University of Texas at Austin",
  "Korea University",
  "National Taiwan University (NTU)",
  "The University of Warwick",
  "University of Illinois at Urbana-Champaign",
  "Universidad de Buenos Aires (UBA)",
  "University of California, San Diego (UCSD)",
  "Université Paris-Saclay",
  "KTH Royal Institute of Technology ",
  "Lund University",
  "University of Washington",
  "The University of Western Australia",
  "University of Glasgow",
  "Brown University",
  "University of Birmingham",
  "University of Southampton",
  "The University of Adelaide",
  "University of Leeds",
  "Ruprecht-Karls-Universität Heidelberg",
  "Tokyo Institute of Technology (Tokyo Tech)",
  "Osaka University",
  "Trinity College Dublin, The University of Dublin",
  "University of Technology Sydney",
  "Durham University",
  "Pennsylvania State University",
  "Purdue University",
  "Universidade de São Paulo",
  "Pontificia Universidad Católica de Chile (UC)",
  "Universidad Nacional Autónoma de México (UNAM)",
  "Lomonosov Moscow State University",
  "University of Alberta",
  "Freie Universitaet Berlin",
  "Pohang University of Science And Technology (POSTECH)",
  "RWTH Aachen University",
  "University of Copenhagen",
  "King Fahd University of Petroleum & Minerals",
  "KIT, Karlsruhe Institute of Technology",
  "Uppsala University",
  "University of St Andrews",
  "Utrecht University",
  "The University of Sheffield",
  "Tohoku University",
  "University of Nottingham",
  "Boston University",
  "Technical University of Denmark",
  "University of Zurich",
  "Politecnico di Milano",
  "Aalto University",
  "Georgia Institute of Technology",
  "University of Waterloo",
  "University of Wisconsin-Madison",
  "University of Helsinki",
  "Indian Institute of Technology Bombay (IITB)",
  "University of Oslo",
  "Western University",
  "Queen Mary University of London",
  "Qatar University",
  "RMIT University",
  "Sungkyunkwan University(SKKU)",
  "University of Southern California",
  "Humboldt-Universität zu Berlin",
  "University College Dublin",
  "Stockholm University",
  "Newcastle University",
  "University of California, Davis",
  "University of Basel",
  "Sapienza University of Rome",
  "Macquarie University",
  "University of Science and Technology of China",
  "Alma Mater Studiorum - University of Bologna",
  "Eindhoven University of Technology",
  "University of Vienna",
  "Universiti Kebangsaan Malaysia (UKM)",
  "Universidad de Chile",
  "Chalmers University of Technology",
  "Leiden University",
  "Lancaster University",
  "Rice University",
  "Aarhus University",
  "Nanjing University",
  "Universiti Sains Malaysia (USM)",
  "Technische Universität Berlin (TU Berlin)",
  "Universiti Putra Malaysia (UPM)",
  "King Abdulaziz University (KAU)",
  "Indian Institute of Technology Delhi (IITD)",
  "University of Bath",
  "Nagoya University",
  "Michigan State University",
  "Texas A&M University",
  "Wageningen University & Research",
  "University of Geneva",
  "University of North Carolina, Chapel Hill",
  "Erasmus University Rotterdam ",
  "Université de Montréal ",
  "University of Groningen",
  "University of Bern",
  "Hanyang University",
  "Al-Farabi Kazakh National University",
  "Complutense University of Madrid",
  "Universitat de Barcelona",
  "University of Liverpool",
  "University of Wollongong",
  "Kyushu University",
  "Ghent University",
  "The University of Exeter",
  "University of Cape Town",
  "Washington University in St. Louis",
  "University of Reading",
  "Hokkaido University",
  "Curtin University",
  "Universitat Autònoma de Barcelona",
  "McMaster University",
  "University of California, Santa Barbara (UCSB)",
  "The University of Newcastle, Australia (UON)",
  "Universidad de los Andes",
  "Waseda University",
  "Universiti Teknologi Malaysia ",
  "Hamad Bin Khalifa University",
  "University of York",
  "Tecnológico de Monterrey",
  "Cardiff University",
  "École Normale Supérieure de Lyon",
  "Keio University",
  "University of Ottawa",
  "Technische Universität Wien",
  "Universität Hamburg",
  "Tongji University",
  "Queen's University at Kingston",
  "Wuhan University",
  "University of Gothenburg",
  "Emory University",
  "Deakin University",
  "University of Calgary",
  "Universidad Autónoma de Madrid",
  "King Saud University",
  "Arizona State University",
  "Khalifa University of Science and Technology",
  "Université catholique de Louvain (UCLouvain)",
  "University of Minnesota Twin Cities",
  "Ecole des Ponts ParisTech",
  "Universitas Indonesia",
  "Queen's University Belfast",
  "The Ohio State University",
  "Tel Aviv University",
  "National Tsing Hua University",
  "Indian Institute of Science",
  "Albert-Ludwigs-Universitaet Freiburg",
  "Queensland University of Technology (QUT)",
  "University of Otago",
  "National Cheng Kung University (NCKU)",
  "University of Florida",
  "La Trobe University",
  "University of Maryland, College Park",
  "Universidad Nacional de Colombia",
  "National Yang Ming Chiao Tung University",
  "Vrije Universiteit Amsterdam",
  "Eberhard Karls Universität Tübingen",
  "Indian Institute of Technology Kharagpur (IIT-KGP)",
  "Friedrich-Alexander-Universität Erlangen-Nürnberg",
  "University of Lausanne",
  "Loughborough University",
  "Rheinische Friedrich-Wilhelms-Universität Bonn",
  "Indian Institute of Technology Madras (IITM)",
  "Chulalongkorn University",
  "Universite libre de Bruxelles",
  "Maastricht University ",
  "Universidade Estadual de Campinas (Unicamp)",
  "University of Twente",
  "Technische Universität Dresden",
  "University of Waikato",
  "Università di Padova",
  "University of Aberdeen",
  "University of Rochester",
  "Gadjah Mada University",
  "Massey University",
  "Technical University of Darmstadt",
  "Politecnico di Torino",
  "Dartmouth College",
  "Victoria University of Wellington",
  "University of Macau",
  "Charles University",
  "University of Sussex",
  "Vanderbilt University",
  "University of Navarra",
  "American University of Beirut (AUB)",
  "Taylor's University",
  "Harbin Institute of Technology",
  "University of Göttingen",
  "Hong Kong Baptist University",
  "Griffith University",
  "Bandung Institute of Technology (ITB)",
  "Heriot-Watt University",
  "University of Warsaw ",
  "Case Western Reserve University",
  "University of Lisbon ",
  "University of Canterbury | Te Whare Wananga o Waitaha",
  "United Arab Emirates University",
  "Indian Institute of Technology Kanpur (IITK)",
  "Norwegian University of Science And Technology",
  "UCSI University",
  "Universitat Pompeu Fabra (Barcelona)",
  "University of Antwerp",
  "University of Witwatersrand",
  "Tianjin University",
  "Universiti Teknologi PETRONAS (UTP)",
  "Beijing Normal University ",
  "Radboud University ",
  "University College Cork",
  "University of Galway",
  "Dalhousie University",
  "University of Massachusetts Amherst",
  "University of Pittsburgh",
  "Vrije Universiteit Brussel (VUB)",
  "University of Porto",
  "Ulsan National Institute of Science and Technology (UNIST)",
  "The Hebrew University of Jerusalem",
  "University of Strathclyde",
  "Université Paris 1 Panthéon-Sorbonne ",
  "Southern University of Science and Technology",
  "University of Cologne",
  "University of Milan",
  "Universidad Carlos III de Madrid (UC3M)",
  "Middle East Technical University",
  "University of Leicester",
  "University of Surrey",
  "Swinburne University of Technology",
  "University of Bergen",
  "University of Tasmania",
  "The University of Arizona",
  "Xi’an Jiaotong University",
  "Stellenbosch University",
  "University of Virginia",
  "Bauman Moscow State Technical University",
  "Swansea University",
  "Huazhong University of Science and Technology",
  "Georgetown University",
  "Beijing Institute of Technology",
  "Université Paris Cité",
  "Universidade Federal do Rio de Janeiro",
  "Linköping University",
  "Aalborg University",
  "University of California, Irvine",
  "Airlangga University",
  "Universität Innsbruck",
  "Goethe-University Frankfurt am Main",
  "North Carolina State University",
  "Jagiellonian University",
  "University of Johannesburg",
  "Universität Stuttgart",
  "Quaid-i-Azam University",
  "Shandong University",
  "RUDN University",
  "University of Notre Dame",
  "Simon Fraser University",
  "University of Colorado Boulder",
  "National Technical University of Athens",
  "L.N. Gumilyov Eurasian National University (ENU)",
  "Universidad Politécnica de Madrid (UPM)",
  "University of Southern Denmark (SDU)",
  "University of Miami",
  "Daegu Gyeongbuk Institute of Science and Technology (DGIST)",
  "Istanbul Technical University",
  "University of Delhi",
  "Kyung Hee University",
  "Rutgers University–New Brunswick",
  "Sun Yat-sen University",
  "American University of Sharjah",
  "University of East Anglia (UEA)",
  "Université Grenoble Alpes",
  "Indian Institute of Technology Roorkee (IITR)",
  "Flinders University",
  "Sichuan University",
  "Lappeenranta-Lahti University of Technology LUT",
  "University of the Philippines",
  "University of South Australia",
  "University of Saskatchewan",
  "Sharif University of Technology",
  "Brunel University London",
  "University of Oulu",
  "Indian Institute of Technology Guwahati (IITG)",
  "Tufts University",
  "University of Naples - Federico II",
  "Tilburg University",
  "University of Victoria (UVic)",
  "Cairo University",
  "Sciences Po ",
  "City, University of London",
  "National University of Sciences And Technology (NUST) Islamabad",
  "University of Pretoria",
  "University of Luxembourg",
  "University of Coimbra",
  "Indiana University Bloomington",
  "University of Tartu",
  "Pontificia Universidad Católica del Perú",
  "University of Aveiro",
  "Gwangju Institute of Science and Technology (GIST)",
  "York University",
  "Xiamen University",
  "Sultan Qaboos University",
  "Saint Petersburg State University",
  "University of Illinois at Chicago (UIC)",
  "Westfälische Wilhelms-Universität Münster ",
  "University of Tehran",
  "University of Jordan",
  "Mahidol University",
  "University of Turin",
  "Lincoln University ",
  "Universitat Politècnica de Catalunya · BarcelonaTech (UPC)",
  "George Washington University",
  "University of Turku",
  "University of Florence",
  "Nankai University",
  "Pontificia Universidad Javeriana",
  "University of Tsukuba",
  "Technische Universität Bergakademie Freiberg",
  "University of Kent",
  "University of Pisa",
  "Anna University",
  " Western Sydney University",
  "Universiti Brunei Darussalam (UBD)",
  "South China University of Technology",
  "Belarusian State University",
  "Universidade Nova de Lisboa",
  "University of Cyprus (UCY)",
  "Università Vita-Salute San Raffaele",
  "Virginia Polytechnic Institute and State University",
  "National Taiwan University of Science and Technology (Taiwan Tech)",
  "National and Kapodistrian University of Athens",
  "University of Rome Tor Vergata",
  "University of California, Santa Cruz",
  "University of Southern Queensland ",
  "Université de Liège",
  "Ruhr-Universität Bochum",
  "Sejong University ",
  "Northeastern University",
  "Kazan (Volga region) Federal University",
  "Koç University",
  "University of Canberra ",
  "Amirkabir University of Technology",
  "Institut National des Sciences Appliquées de Lyon (INSA)",
  "Satbayev University",
  "USI - Università della Svizzera italiana",
  "Masaryk University",
  "Birkbeck, University of London",
  "The American University in Cairo",
  "HSE University",
  "Auckland University of Technology (AUT)",
  "Graz University of Technology",
  "Yeshiva University",
  "Concordia University",
  "Technion - Israel Institute of Technology",
  "Oxford Brookes University",
  "Bogaziçi University",
  " University of Dundee",
  "Czech Technical University in Prague",
  "Dublin City University",
  "University of Limerick",
  "Université Laval",
  "Aston University",
  "National Taipei University of Technology",
  "Leibniz University Hannover",
  "IPB University (a.k.a. Bogor Agricultural University)",
  "Southeast University",
  "Julius-Maximilians-Universität Würzburg",
  "University of Science and Technology Beijing",
  "Central South University",
  "Tomsk State University",
  "University of Granada",
  "University of Sharjah",
  "IE University",
  "Murdoch University",
  "Iran University of Science and Technology",
  "Universitat Politècnica de València",
  "Vilnius University ",
  "Università degli Studi di Pavia",
  "Singapore University of Technology and Design",
  "Università Cattolica del Sacro Cuore",
  "Novosibirsk State University",
  "Colorado State University",
  "James Cook University",
  "Justus-Liebig-University Giessen",
  "Universitat de Valencia",
  "Dalian University of Technology",
  "Hunan University ",
  "Université de Montpellier",
  "University of Electronic Science and Technology of China",
  "Beihang University (former BUAA)",
  "National Taiwan Normal University",
  "University of Stirling",
  "University of Kansas",
  "University of Guelph",
  "Université de Strasbourg",
  "Moscow Institute of Physics and Technology (MIPT / Moscow Phystech)",
  "Umea University",
  "Stony Brook University, State University of New York",
  "Universidad de Santiago de Chile (USACH)",
  "Tampere University",
  "Universidad de Sevilla",
  "Macau University of Science and Technology",
  "Kobe University",
  "Universität Konstanz",
  "Universitat Ramon Llull",
  "University at Buffalo SUNY",
  "Washington State University",
  "Iowa State University",
  "Ben-Gurion University of The Negev",
  "Johannes Kepler University Linz",
  "Essex, University of",
  "Universität Potsdam",
  "Hiroshima University",
  "Bangor University",
  "Indian Institute of Technology Indore (IIT Indore)",
  "Bilkent University",
  "Ajman University",
  "Royal Holloway University of London",
  "Pontificia Universidad Católica Argentina",
  "Aix-Marseille University",
  "The University of Tennessee, Knoxville",
  "China Agricultural University ",
  "National Sun Yat-sen University",
  "American University of Ras Al Khaimah ( AURAK )",
  "Universität Mannheim",
  "University of Hawai?i at Manoa",
  "UNESP",
  "Chongqing University",
  "Shanghai University",
  "University of Jyväskylä",
  "Isfahan University of Technology",
  "Chung-Ang University (CAU)",
  "Central Queensland University (CQUniversity Australia)",
  "Duy Tan University",
  "Jilin University",
  "Universidad de Costa Rica",
  "National Research Nuclear University MEPhI (Moscow Engineering Physics Institute)",
  "University of California, Riverside",
  "East China Normal University",
  "Johannes Gutenberg Universität Mainz",
  "Abu Dhabi University",
  "University of Huddersfield",
  "Colorado School of Mines",
  "University of Trento",
  "University of Delaware",
  "Shenzhen University",
  "Aristotle University of Thessaloniki",
  "SOAS University of London ",
  "Zhengzhou University",
  "Ewha Womans University",
  "University of Milano-Bicocca ",
  "Imam Abdulrahman Bin Faisal University (IAU) (formerly UNIVERSITY OF DAMMAM)",
  "Prince Mohammad Bin Fahd university",
  "Edith Cowan University ",
  "INTI International University",
  "Ateneo de Manila University",
  "Ural Federal University - UrFU",
  "Kyungpook National University",
  "Sabanci University",
  "University of Hull",
  "Canadian University Dubai",
  "Universidad Austral",
  "Pusan National University",
  "Universität Jena",
  "Coventry University",
  "Universität Leipzig",
  "University of Bayreuth",
  "Warsaw University of Technology",
  "Missouri University of Science and Technology",
  "Indian Institute of Technology (BHU) Varanasi",
  "University of Bradford",
  "University of Utah",
  "Universidad Nacional de La Plata (UNLP)",
  "University of Eastern Finland",
  "University of Bordeaux",
  "Lahore University of Management Sciences (LUMS)",
  "Northumbria University at Newcastle",
  "Applied Science University - Bahrain",
  "Hitotsubashi University",
  "Sunway University",
  "University of Salamanca",
  "Université de Fribourg",
  "Universidad de Zaragoza",
  "Universidad Pontificia Comillas",
  "University of Windsor",
  "Northwestern Polytechnical University",
  "University of Iceland",
  "University of Genoa",
  "Tashkent Institute of Irrigation and Agricultural Mechanization Engineers - National Research University (TIIAME-NRU)",
  "University of Tabriz",
  "Al Ain University",
  "University of Dhaka",
  "Hasselt University ",
  "University Ulm",
  "Jordan University of Science & Technology",
  "Universiti Utara Malaysia (UUM)",
  "Peter the Great St. Petersburg Polytechnic University",
  "Umm Al-Qura University ",
  "Ulster University",
  "Universiti Teknologi Brunei",
  "Université Claude Bernard Lyon 1",
  "Eötvös Loránd University",
  "Nanjing University of Science and Technology",
  "University of Connecticut",
  "Lebanese University",
  "MGIMO University",
  "Chiang Mai University",
  "University of Chemistry and Technology, Prague",
  "University of Szeged",
  "University of the Punjab",
  "Florida State University",
  "University of Debrecen",
  "Florida International University",
  "Southern Cross University ",
  "Universidad Adolfo Ibáñez",
  "National Research Tomsk Polytechnic University",
  "Sogang University",
  "Jinan University (China)",
  "Université Paul Sabatier Toulouse III",
  "Jawaharlal Nehru University",
  "Politecnico di Bari",
  "Management and Science University",
  "Institut Teknologi Sepuluh Nopember (ITS Surabaya)",
  "Singapore Management University",
  "Bond University",
  "Shoolini University of Biotechnology and Management Sciences",
  "Universiti Teknologi MARA - UiTM",
  "University of Kwazulu-Natal",
  "The University of Georgia",
  "Ain Shams University ",
  "Universität Bremen",
  "Nottingham Trent University",
  "ITMO University",
  "Universitas Padjadjaran",
  "University of Ljubljana",
  "Thammasat University",
  "University of Iowa",
  "University of Texas Dallas",
  "Universidad Nacional de Cuyo",
  "Universidad Nacional de San Luis",
  "Universidad Nacional del Centro de la Provincia de Buenos Aires (UNICEN)",
  "Universidad Tecnológica Nacional (UTN)",
  "University of New England Australia",
  "University of the Sunshine Coast ",
  "Azerbaijan State Oil and Industry University",
  "Azerbaijan State University of Economics",
  "BRAC University",
  "University of Sarajevo ",
  "Pontifícia Universidade Católica de São Paulo",
  "Universidade Federal de Pernambuco (UFPE) ",
  "Universidade Federal de São Carlos (UFSCar)",
  "Universidade Federal do Paraná - UFPR",
  "Universidade Federal Fluminense ",
  "Universidad Andrés Bello",
  "Universidad Austral de Chile",
  "Universidad de La Frontera (UFRO)",
  "Universidad de Talca",
  "Universidad Diego Portales (UDP)",
  "Harbin Engineering University",
  "Hohai University",
  "Jiangnan University",
  "Nanjing Normal University",
  "Northwest University (China)",
  "Southwest University",
  "Xi'an Jiaotong Liverpool University",
  "Universidad del Norte ",
  "Universidad del Valle",
  "Universidad ICESI",
  "Tecnológico de Costa Rica -TEC",
  "University of Hradec Kralove",
  "VSB - Technical University of Ostrava",
  "Escuela Superior Politécnica del Litoral (ESPOL)",
  "Al-Azhar University",
  "Arab Academy for Science, Technology and Maritime Transport",
  "Assiut University ",
  "Tallinn University ",
  "Paul Valéry University Montpellier",
  "Université de Caen Normandie",
  "Université de Poitiers",
  "Université de Toulouse II-Le Mirail",
  "Université Lumière Lyon 2",
  "Université Paris-Nanterre",
  "Bielefeld University",
  "Universität Siegen",
  "University of Ioannina",
  "Corvinus University of Budapest",
  "Hungarian University of Agriculture and Life Sciences",
  "Széchenyi István University",
  "Aligarh Muslim University",
  "Amity University",
  "Amrita Vishwa Vidyapeetham",
  "Banaras Hindu University",
  "Guru Gobind Singh Indraprastha University",
  "O.P. Jindal Global University",
  "Panjab University",
  "SRM INSTITUTE OF SCIENCE AND TECHNOLOGY",
  "Telkom University",
  "Universitas Hasanuddin",
  "Universitas Sebelas Maret",
  "Mustansiriyah University",
  "Universita' degli Studi G. d Annunzio Chieti Pescara",
  "Università degli Studi di Udine",
  "Università del Salento",
  "University of Naples Parthenope",
  "Gifu University",
  "Gunma University",
  "International Christian University",
  "Kagoshima University",
  "Kyushu Institute of Technology ",
  "Shinshu University ",
  "Tokushima University",
  "Tokyo Metropolitan University",
  "Yokohama National University",
  "Mutah University",
  "The Hashemite University",
  "Kazakh-British Technical University",
  "Narxoz University",
  "NJSC KIMEP University",
  "Mykolas Romeris University",
  "Multimedia University (MMU)",
  "Universiti Malaysia Sabah (UMS)",
  "Universiti Malaysia Sarawak (UNIMAS)",
  "Universiti Malaysia Terengganu (UMT)",
  "Universiti Tun Hussein Onn University of Malaysia (UTHM)",
  "ITESO, Universidad Jesuita de Guadalajara",
  "Universidad Autónoma Chapingo",
  "Universidad Autónoma de Nuevo León",
  "Universidad Autónoma del Estado de México (UAEMex)",
  "Universidad de Guadalajara (UDG)",
  "Universidad de las Américas Puebla (UDLAP)",
  "Université Mohammed V de Rabat",
  "University of Ibadan",
  "University of Lagos",
  "Sohar University",
  "International Islamic University Islamabad (IIU)",
  "University of Karachi",
  "An-Najah National University",
  "Universidad Tecnológica de Panamá (UTP)",
  "Universidad Nacional de la Asunción",
  "Universidad de Lima",
  "Universidad del Pacífico",
  "Universidad Peruana Cayetano Heredia (UPCH)",
  "Cracow University of Technology (Politechnika Krakowska)",
  "Lodz University of Technology",
  "Poznan University of Technology",
  "Silesian University of Technology",
  "University of Warmia and Mazury in Olsztyn",
  "Warsaw University of Life Sciences – SGGW (WULS-SGGW)",
  "Universidad de Puerto Rico",
  "Lobachevsky University",
  "Russian Presidential Academy of National Economy and Public Administration",
  "Saint Petersburg Electrotechnical University ETU-LETI",
  "Saint-Petersburg Mining University",
  "Samara National Research University (Samara University)",
  "Siberian Federal University, SibFU",
  "Islamic University of Madinah",
  "Taibah University",
  "University of Tabuk",
  "University of Novi Sad",
  "Slovak University of Agriculture in Nitra",
  "Slovak University of Technology in Bratislava",
  "Technical University of Kosice",
  "University of Žilina",
  "University of the Free State",
  "Chungbuk National University",
  "Dankook University ",
  "Kangwon National University ",
  "Soonchunhyang University",
  "Sungshin Women's University",
  "University of Seoul ",
  "Universidad de Castilla-La Mancha",
  "Universidad de Oviedo",
  "Universidad Rey Juan Carlos",
  "Universidade da Coruña ",
  "Universitat de Lleida",
  "University of Deusto",
  "University of Khartoum",
  "Chang Jung Christian University",
  "Chung Yuan Christian University",
  "National Chung Cheng University",
  "Université de Sfax",
  "Université de Sousse",
  "Université de Tunis",
  "EGE UNIVERSITY",
  "Gebze Technical University (GTU)",
  "Marmara University",
  "Lviv Polytechnic National University",
  "National Technical University Kharkiv Polytechnic Institute",
  "National University of Kyiv-Mohyla Academy (NaUKMA)",
  "Sumy State University",
  "Birmingham City University ",
  "Canterbury Christ Church University",
  "Glasgow Caledonian University",
  "Leeds Beckett University",
  "London Metropolitan University",
  "Sheffield Hallam University",
  "The University of Northampton",
  "University of Derby",
  "University of Wolverhampton",
  "Baylor University",
  "Binghamton University SUNY",
  "Brigham Young University",
  "College of William and Mary",
  "Fordham University ",
  "George Mason University",
  "Howard University",
  "Indiana University–Purdue University Indianapolis",
  "Kent State University",
  "Loyola University Chicago",
  "Mississippi State University",
  "Northern Arizona University",
  "Ohio University",
  "Rochester Institute of Technology (RIT)",
  "San Diego State University",
  "Southern Methodist University",
  "The University of Texas at Arlington",
  "University of Arkansas Fayetteville ",
  "University of Idaho",
  "University of Louisville",
  "University of Massachusetts Boston",
  "University of Missouri, Kansas City",
  "University of New Hampshire",
  "University of North Carolina at Charlotte",
  "University of North Texas",
  "University of Rhode Island ",
  "University of Texas at San Antonio",
  "University of Texas El Paso ",
  "University of Toledo",
  "University of Tulsa",
  "University of Vermont",
  "University of Wyoming",
  "Utah State University ",
  "West Virginia University",
  "Universidad de Los Andes - (ULA) Mérida",
  "Universidad Simón Bolívar (USB)",
  "Universidad del Salvador",
  "Universidad Nacional de Mar del Plata",
  "Universidad Nacional de Río Cuarto - UNRC",
  "Universidad Nacional de San Martín (UNSAM)",
  "Universidad Nacional del Litoral",
  "Universidad Nacional del Sur",
  "Chittagong University of Engineering and Technology",
  "Daffodil International University",
  "Jahangirnagar University",
  "Khulna University",
  "Khulna University of Engineering and Technology",
  "Rajshahi University",
  "Shahjalal University of Science and Technology",
  "Belarusian State University of Informatics and Radioelectronics",
  "Universidad Mayor de San Andrés (UMSA)",
  "Pontifícia Universidade Católica do Rio Grande do Sul (PUCRS)",
  "Universidade do Estado do Rio de Janeiro (UERJ)",
  "Universidade Federal da Bahia",
  "Universidade Federal de Juiz de Fora- (UFJF)",
  "Universidade Federal de Pelotas ",
  "Universidade Federal de Santa Maria",
  "Universidade Federal de Viçosa (UFV)",
  "Universidade Federal do Ceará (UFC)",
  "Universidade Federal do Rio Grande Do Norte",
  "Universidade Presbiteriana Mackenzie",
  "Brock University",
  "Vancouver Island University",
  "Universidad Católica del Norte",
  "Universidad de Tarapaca",
  "Universidad de Valparaíso (UV)",
  "Universidad del Bío-Bío",
  "Universidad del Desarrollo (UDD)",
  "Beijing Foreign Studies University",
  "Beijing University of Chinese Medicine",
  "China University of Political Science and Law",
  "Shanghai International Studies University",
  "Shanghai University of Finance and Economics",
  "University of International Business and Economics",
  "Universidad Industrial de Santander - UIS",
  "Universidad Latinoamericana de Ciencia y Tecnología Costa Rica (ULACIT)",
  "Universidad Nacional, Costa Rica",
  "The Josip Juraj Strossmayer University of Osijek",
  "University of Rijeka",
  "University of Split",
  "Universidad de Oriente Santiago de Cuba",
  "Technical University of Liberec",
  "Tomas Bata University in Zlin",
  "University of Ostrava",
  "University of Pardubice",
  "University of West Bohemia ",
  "Instituto Tecnológico de Santo Domingo (INTEC)",
  "Pontificia Universidad Catolica Madre y Maestra",
  "Escuela Politécnica Nacional",
  "Universidad Central del Ecuador",
  "Universidad de Las Américas (UDLA) Ecuador",
  "British University in Egypt",
  "German University in Cairo",
  "Helwan University ",
  "Suez Canal University",
  "Tanta University ",
  "Zagazig University",
  "Université de Limoges",
  "Université Jean Moulin Lyon 3",
  "Technische Universität Kaiserslautern",
  "Kwame Nkrumah University of Science and Technology",
  "Universidad de San Carlos de Guatemala - USAC",
  "Universidad Nacional Autónoma de Honduras (UNAH)",
  "Óbuda University",
  "University of Miskolc ",
  "University of Pannonia",
  "Chitkara University",
  "Jamia Hamdard",
  "OSMANIA UNIVERSITY",
  "Pondicherry University",
  "Sathyabama Institute of Science and Technology (deemed to be university)",
  "Siksha 'O' Anusandhan (Deemed to be University)",
  "Atma Jaya Catholic University Jakarta",
  "Universitas Islam Indonesia",
  "Universitas Muhammadiyah Yogyakarta",
  "Universitas Pendidikan Indonesia",
  "Universitas Sumatera Utara",
  "Yogyakarta State University",
  "University of Babylon",
  "University of Basrah",
  "University of Kufa",
  "Università degli studi di Bergamo",
  "Aoyama Gakuin University",
  "Doshisha University",
  "Kindai University (Kinki University)",
  "Kyoto Institute of Technology",
  "Meiji University",
  "Nagoya Institute of Technology (NIT)",
  "Rikkyo University",
  "Saitama University",
  "Tokai University",
  "Yamaguchi University ",
  "Al-Balqa Applied University",
  "Kazakh Ablai Khan University of International Relations and World Languages",
  "Saken Seifullin Kazakh Agrotechnical University",
  "Suleyman Demirel University, Kazakhstan",
  "Toraighyrov University",
  "Kenyatta University",
  "Kyrgyz State Technical University",
  "Universiti Kuala Lumpur (UniKL)",
  "Universiti Malaysia Perlis",
  "Universiti Teknikal Malaysia Melaka",
  "Benemérita Universidad Autónoma de Puebla",
  "Universidad Autónoma de Guadalajara (UAG)",
  "Universidad Autónoma del Estado de Hidalgo (UAEH)",
  "Universidad de Monterrey (UDEM)",
  "Universidad La Salle (ULSA)",
  "Bahauddin Zakariya University ",
  "Birzeit university",
  "Universidad de Panama",
  "Universidad de Piura",
  "Universidad Nacional Agraria la Molina",
  "Universidad Nacional de Ingeniería Peru",
  "Universidad Peruana de Ciencias Aplicadas",
  "Technical University of Lublin",
  "University of Bialystok",
  "University of Rzeszów",
  "University of Silesia in Katowice",
  "Alexandru Ioan Cuza University",
  "Technical University of Cluj-Napoca",
  "The Gheorghe Asachi Technical University of Iasi",
  "Transilvania University of Brasov",
  "Universitatea de Vest din Timisoara / West University of Timisoara",
  "University POLITEHNICA of Bucharest",
  "Kazan National Research Technological University",
  "Mendeleev University of Chemical Technology",
  "Moscow Pedagogical State University",
  "National Research University Moscow Power Engineering Institute (MPEI)",
  "Novosibirsk State Technical University",
  "Perm State National Research University",
  "The Herzen State Pedagogical University of Russia",
  "The National Research University Belgorod State University",
  "University of Tyumen",
  "Voronezh State University",
  "University of Kragujevac",
  "University of Niš",
  "University of Primorska",
  "Hongik University ",
  "Jeju National University ",
  "Kookmin University ",
  "Pukyong National University ",
  "Seoul National University of Science and Technology",
  "Sookmyung Women's University ",
  "Youngsan University",
  "Fundación CEU San Pablo",
  "University of Peradeniya ",
  "University of Sri Jayewardenepura",
  "Damascus University",
  "Chaoyang University of Technology",
  "Feng Chia University",
  "Fu Jen Catholic University",
  "National Dong Hwa University",
  "National Taipei University",
  "National Taiwan Ocean University",
  "Tamkang University",
  "Tunghai University",
  "Yuan Ze University",
  "King Mongkut's Institute of Technology Ladkrabang",
  "King Mongkut's University of Technology North Bangkok",
  "Suranaree University of Technology ",
  "Anadolu University",
  "Ataturk University",
  "Cukurova University",
  "Dokuz Eylül Üniversitesi",
  "Erciyes Üniversitesi",
  "Istanbul Aydin University  ",
  "Istanbul Bilgi Üniversitesi",
  "Izmir Institute of Technology (IZTECH)",
  "Suleyman Demirel University",
  "Ivan Franko National University of Lviv",
  "Kharkiv National University of Radio Electronics ",
  "Odessa I.I. Mechnikov National University",
  "Harper Adams University",
  "Central Michigan University",
  "Cleveland State University",
  "CUNY The City College of New York",
  "Florida Atlantic University - Boca Raton",
  "Marquette University",
  "Miami University",
  "Portland State University",
  "San Francisco State University",
  "Seattle University",
  "University of Memphis",
  "University of Missouri Saint Louis",
  "University of North Carolina at Greensboro",
  "University of San Diego",
  "University of San Francisco",
  "University of South Alabama (USA)",
  "University of the Pacific",
  "University of Wisconsin Milwaukee ",
  "Western Michigan University",
  "Universidad del Zulia",
  "Universidad Metropolitana",
  "Hanoi University of Science and Technology",
  "Hue University",
  "Católica de Córdoba",
  "Universidad Nacional de Quilmes",
  "Universidad Nacional de Tucumán",
  "Universidad Nacional del Comahue",
  "The University of Notre Dame, Australia",
  "East West University",
  "International University of Business Agriculture and Technology",
  "Stamford University Bangladesh",
  "United International University",
  "Universidad Católica Boliviana San Pablo",
  "Pontifícia Universidade Católica de Minas Gerais – PUC Minas",
  "Pontificia Universidade Católica do Campinas - PUC Campinas",
  "Pontifícia Universidade Católica do Paraná",
  "Universidade do Estado de Santa Catarina",
  "Universidade Estadual de Londrina",
  "Universidade Federal da Paraíba",
  "Universidade Federal de Goiás",
  "Universidade Federal de Uberlândia",
  "Universidade Federal do Estado do Rio de Janeiro - UNIRIO",
  "Universidade Federal do Parà - UFPA",
  "Universidad Alberto Hurtado",
  "Universidad Autónoma de Chile",
  "Universidad Bernardo O'Higgins",
  "Universidad Católica de La Santísima Concepción - UCSC",
  "Universidad Católica de Temuco",
  "Universidad Central de Chile",
  "Universidad de La Serena",
  "Shanghai Normal University",
  "Fundación Universidad De Bogotá-Jorge Tadeo Lozano",
  "Universidad Católica de Colombia ",
  "Universidad de Caldas",
  "Universidad de Cartagena",
  "Universidad de Córdoba - Colombia",
  "Universidad de La Salle",
  "Universidad de Medellín",
  "Universidad de Santander - UDES",
  "Universidad del Cauca",
  "Universidad Tecnológica de Bolívar",
  "Universidad Tecnológica de Pereira",
  "Universidad Tecnológica de la Habana José Antonio Echeverría, Cujae",
  "Universidad Católica de Santiago de Guayaquil",
  "Universidad de Cuenca",
  "Universidad de las Fuerzas Armadas ESPE (Ex - Escuela Politécnica del Ejército)",
  "UNIVERSIDAD POLITECNICA SALESIANA",
  "Universidad Tecnica Particular De Loja (UTPL)",
  "Indian Institute of Information Technology, Allahabad",
  "State University of Malang",
  "Udayana University",
  "Universitas Andalas",
  "Universitas Kristen Petra",
  "Universitas Muhammadiyah Surakarta",
  "University of Lampung",
  "University of Mataram",
  "Kwansei Gakuin University",
  "Shibaura Institute of Technology",
  "Korkyt Ata Kyzylorda University",
  "M.Kh. Dulaty Taraz Regional University",
  "Universiti Sains Islam Malaysia",
  "Universidad Autónoma de Aguascalientes",
  "Universidad Autónoma de Baja California",
  "Universidad Autónoma de Querétaro (UAQ)",
  "Universidad Autónoma de San Luis de Potosí",
  "Universidad Autonoma de Yucatan",
  "Universidad Autónoma del Estado de Morelos (UAEM)",
  "Universidad de Guanajuato",
  "Universidad de Sonora",
  "Universidad del Valle de Mexico (UVM)",
  "Universidad Michoacana de San Nicolás de Hidalgo",
  "Universidad Popular Autónoma del Estado de Puebla (UPAEP)",
  "Universidad Veracruzana",
  "National University of Modern Languages (NUML), Islamabad",
  "Universidad San Ignacio de Loyola",
  "University of San Carlos",
  "Lucian Blaga University of Sibiu",
  "Stefan cel Mare University of Suceava",
  "University of Craiova",
  "University of Oradea",
  "University Politehnica of Timisoara, UPT",
  " Don State Technical University",
  "Irkutsk State University",
  "MIREA - Russian Technological University",
  "Moscow City University",
  "Russian State Agrarian University - Moscow Timiryazev Agricultural Academy",
  "Russian State University for the Humanities ",
  "Soongsil University",
  "Soochow University (Taiwan)",
  "Naresuan University",
  "Silpakorn University",
  "Akdeniz Üniversitesi",
  "Sakarya University",
  "National University of Life and Environmental Sciences of Ukraine",
  "California Polytechnic State University",
  "California State University - Los Angeles",
  "California State University, Long Beach",
  "Humboldt State University",
  "Illinois State University",
  "Indiana State University",
  "Nova Southeastern University",
  "University of Central Oklahoma",
  "University of Montana Missoula ",
  "Western Washington University",
  "Universidad de Carabobo",
  "Paris Lodron University of Salzburg",
  "Universität des Saarlandes",
  "University of Hohenheim",
  "University of Crete",
  "Ca' Foscari University of Venice ",
  "King Khalid University",
  "National Chengchi University",
  "Kingston University, London",
  "Manchester Metropolitan University (MMU)",
  "Illinois Institute of Technology",
  "Rensselaer Polytechnic Institute",
  "University of South Carolina",
  "University of Klagenfurt",
  "Pontifícia Universidade Católica do Rio de Janeiro",
  "Brno University of Technology",
  "Christian-Albrechts-University zu Kiel",
  "American University of the Middle East",
  "Asia Pacific University of Technology and Innovation (APU)",
  "Eastern Mediterranean University",
  "University of Minho",
  "Taipei Medical University (TMU)",
  "Universidad de Palermo (UP)",
  "Charles Darwin University ",
  "Renmin (People's) University of China",
  "Tallinn University of Technology (TalTech)",
  "Abo Akademi University",
  "Auezov South Kazakhstan University (SKU)",
  "University of Tromsø The Arctic University of Norway",
  "Universidad de Alcalá",
  "Istanbul University",
  "Zayed University",
  "American University in Dubai",
  "University of South Florida",
  "Universidad de Concepción",
  "China University of Petroleum",
  "Savitribai Phule Pune University",
  "Far Eastern Federal University",
  "Ajou University ",
  "Inha University",
  "Chang Gung University",
  "University of Portsmouth",
  "Boston College",
  "Tulane University",
  "East China University of Science and Technology",
  "Soochow University",
  "Ritsumeikan University",
  "Beirut Arab University ",
  "Universiti Tenaga Nasional (UNITEN)",
  "De La Salle University",
  "University of the Basque Country",
  "National Central University",
  "Hacettepe University ",
  "Lehigh University",
  "Oregon State University",
  "University of Missouri, Columbia",
  "University of Zagreb",
  "Palacký University Olomouc",
  "HUFS - Hankuk (Korea) University of Foreign Studies ",
  "Universidade de Santiago de Compostela",
  "University of Houston",
  "Sofia University St. Kliment Ohridski",
  "Memorial University of Newfoundland",
  "University of Manitoba",
  "Free University of Bozen-Bolzano",
  "Tokyo Medical and Dental University (TMDU)",
  "International Islamic University Malaysia (IIUM)",
  "ISCTE - Instituto Universitario de Lisboa",
  "Comenius University Bratislava",
  "National Chung Hsing University",
  "Aberystwyth University",
  "University of Plymouth",
  "City University of New York",
  "Universidad de la República (Udelar)",
  "Karl-Franzens-Universitaet Graz",
  "Universidade Federal de Minas Gerais      ",
  "Martin-Luther-Universität Halle-Wittenberg",
  "Bar-Ilan University",
  "University of Haifa",
  "Abai Kazakh National Pedagogical University",
  "Kazakh National Agrarian University KazNAU",
  "University of New Brunswick",
  "China University of Mining and Technology",
  "Indian Institute of Technology (IIT) Hyderabad",
  "University of Agriculture, Faisalabad ",
  "Prince Sultan University",
  "Princess Nourah bint Abdulrahman University",
  "Jeonbuk National University",
  "Goldsmiths, University of London",
  "Temple University",
  "Universidade Federal de São Paulo",
  "Universidade Federal do Rio Grande Do Sul ",
  "Universidad de La Habana",
  "Chandigarh University",
  "Shiraz University",
  "University of Brescia",
  "University of Siena",
  "Universidad Panamericana (UP)",
  "Norwegian University of Life Sciences (UMB)",
  "Pakistan Institute of Engineering and Applied Sciences (PIEAS)",
  "University of Greenwich",
  "Universidad Central de Venezuela",
  "Beijing University of Chemical Technology",
  "National Institute of Technology Tiruchirappalli",
  "Lebanese American University",
  "Dongguk University ",
  "Taras Shevchenko National University of Kyiv",
  "Stevens Institute of Technology",
  "University of Nebraska - Lincoln",
  "University of Oklahoma",
  "Ahlia University",
  "Nanjing Agricultural University",
  "Czech University of Life Sciences in Prague",
  "Université de Franche-Comté",
  "Lingnan University, Hong Kong",
  "Saint Joseph University of Beirut (USJ) ",
  "COMSATS University Islamabad",
  "Plekhanov Russian University of Economics",
  "The National University of Science and Technology MISIS",
  "De Montfort University",
  "American University",
  "Ton Duc Thang University",
  "Lanzhou University",
  "Université de Lille",
  "Université de Rennes 1 (University of Rennes)",
  "Budapest University of Technology and Economics",
  "Diponegoro University",
  "University of Modena and Reggio Emilia",
  "University of Trieste",
  "Riga Technical University",
  "Effat University",
  "Konkuk University ",
  "Middlesex University",
  "Brandeis University",
  "Drexel University",
  "Université Côte d'Azur",
  "University of Belgrade",
  "Universitat Rovira i Virgili",
  "Victoria University ",
  "Carleton University",
  "Universität Regensburg",
  "Vytautas Magnus University",
  "Universiti Malaysia Pahang",
  "Altai State University",
  "V. N. Karazin Kharkiv National University",
  "University of the West of England",
  "University of Westminster",
  "University of Central Florida",
  "Wake Forest University",
  "Universidad de Montevideo (UM)",
  "Universidad Católica Andres Bello",
  "Universidade de Brasília",
  "Technische Universität Braunschweig",
  "University of Calcutta",
  "Universita' Politecnica delle Marche",
  "University of Messina (UniME)",
  "Tokyo University of Science",
  "Kaunas University of Technology",
  "University of Malta",
  "Instituto Tecnológico Autónomo de México (ITAM)",
  "University of Engineering & Technology (UET) Lahore",
  "Bangladesh University of Engineering and Technology",
  "Nanjing University of Aeronautics and Astronautics",
  "University of Patras",
  "Adam Mickiewicz University, Poznan",
  "King Faisal University",
  "University of Cincinnati",
  "University of Colorado, Denver ",
  "China University of Geosciences",
  "Universidad del Rosario",
  "Addis Ababa University",
  "University of Pecs",
  "Verona University",
  "Chiba University",
  "Holy Spirit University of Kaslik ",
  "Colegio de México",
  "Université de Tunis El Manar",
  "Bournemouth University",
  "University of Maryland, Baltimore County",
  "Universidad de Belgrano",
  "University of Mons ",
  "Universidade Federal de Santa Catarina ",
  "University of Latvia",
  "Babes-Bolyai University",
  "Qassim University",
  "Zürcher Hochschule Winterthur",
  "Kasetsart University",
  "Texas Tech University",
  "The New School",
  "National University of Uzbekistan",
  "Universidad de Antioquia",
  "Ivane Javakhishvili Tbilisi State University",
  "Vellore Institute of Technology (VIT)",
  "Sechenov University",
  "Alfaisal University",
  "Keele University ",
  "New Jersey Institute of Technology (NJIT)",
  "University of Kentucky",
  "University of New Mexico",
  "Wayne State University",
  "Belarusian National Technical University (BNTU)",
  "Toronto Metropolitan University",
  "Pontificia Universidad Católica de Valparaíso",
  "Beijing University of Technology",
  "Huazhong Agricultural University",
  "Ocean University of China",
  "Wuhan University of Technology",
  "Universidad San Francisco de Quito (USFQ)",
  "Alexandria University ",
  "Université de Lorraine",
  "Philipps-Universität Marburg ",
  "TU Dortmund University",
  "Universität Duisburg-Essen",
  "Universität Rostock",
  "Birla Institute of Technology and Science, Pilani",
  "University of Hyderabad",
  "University of Petroleum and Energy Studies (UPES)",
  "Universitas Brawijaya ",
  "University of Baghdad",
  "Maynooth University",
  "Università degli Studi di Perugia",
  "University of Bari",
  "University of Salerno",
  "Al-Ahliyya Amman University",
  "Khoja Akhmet Yassawi International Kazakh-Turkish University",
  "Kuwait University",
  "Universiti Tunku Abdul Rahman (UTAR)",
  "Instituto Politécnico Nacional (IPN)",
  "Universidad Anáhuac México",
  "Universidad Iberoamericana IBERO",
  "University of Stavanger",
  "Gdansk University of Technology",
  "University of Bucharest",
  "Immanuel Kant Baltic Federal University",
  "Jouf University",
  "The Catholic University of Korea ",
  "University of Ulsan ",
  "University of Alicante",
  "Asia University Taiwan",
  "Ankara Üniversitesi",
  "National Technical University of Ukraine Igor Sikorsky Kyiv Polytechnic Institute",
  "Edinburgh Napier University",
  "Liverpool John Moores University",
  "London South Bank University",
  "University of Brighton",
  "Rutgers University–Newark",
  "Syracuse University",
  "University of Alaska Fairbanks",
  "University of Oregon",
  "Charles Sturt University ",
  "Université de Sherbrooke",
  "Université du Québec",
  "Donghua University",
  "Northwest Agriculture and Forestry University",
  "Mendel University in Brno",
  "CY Cergy Paris University",
  "Université de Nantes",
  "University Duesseldorf",
  "University of Ghana",
  "Jamia Millia Islamia",
  "Thapar Institute of Engineering & Technology",
  "Shahid Beheshti University (SBU)",
  "Technological University Dublin",
  "Universita' degli Studi di Ferrara",
  "University of Palermo",
  "Nagasaki University",
  "Okayama University",
  "Tokyo University of Agriculture and Technology ",
  "Applied Science Private University - Jordan",
  "Karaganda State Technical University",
  "Gulf University for Science and Technology",
  "Kyrgyz Russian Slavic University",
  "Vilnius Gediminas Technical University",
  "Al-Quds University",
  "University of Santo Tomas",
  "AGH University of Science and Technology",
  "Poznan University of Life Sciences",
  "UNIVERSITY OF GDANSK",
  "University of Wroclaw",
  "Pavol Jozef Šafárik University in Košice",
  "North-West University",
  "University of South Africa",
  "Chonnam National University",
  "Chungnam National University",
  "Universidad de Valladolid",
  "Yildiz Technical University",
  "University of Dubai",
  "University of Hertfordshire",
  "University of Lincoln",
  "Clarkson University",
  "Georgia State University",
  "Kansas State University ",
  "Oklahoma State University ",
  "Swarthmore College",
  "University at Albany SUNY",
  "University of Mississippi",
  "Virginia Commonwealth University",
  "Universidad ORT Uruguay",
  "Vietnam National University, Hanoi ",
  "Universidad Nacional de Córdoba - UNC",
  "Yerevan State University",
  "Australian Catholic University",
  "North South University",
  "Beijing Jiaotong University",
  "Beijing University of Posts and Telecommunications",
  "Universidad EAFIT",
  "Universidad Externado de Colombia ",
  "Universidad Pontificia Bolivariana ",
  "Universidad Central Marta Abreu de Las Villas",
  "Future University in Egypt",
  "University Paris 2 Panthéon-Assas",
  "Georgian Technical University",
  "Manipal Academy of Higher Education, Manipal, Karnataka, India",
  "Catania University",
  "Università degli Studi della Tuscia (University of Tuscia)",
  "Università degli studi Roma Tre",
  "University of Calabria",
  "Kanazawa University",
  "Osaka Metropolitan University",
  "Ritsumeikan Asia Pacific University",
  "Yokohama City University",
  "German Jordanian University",
  "Princess Sumaya University for Technology",
  "Karaganda Buketov University",
  "University of Nairobi",
  "Kyrgyz-Turkish Manas University",
  "University of Peshawar",
  "Universidad Nacional Mayor de San Marcos",
  "Nicolaus Copernicus University ",
  "University of Lodz",
  "Wroclaw University of Science and Technology (WRUST)",
  "Universidade Católica Portuguesa - UCP",
  "National Research Saratov State University",
  "South Ural State University (National Research University)",
  "Imam Mohammad Ibn Saud Islamic University – IMSIU",
  "University of Hail",
  "University of Maribor",
  "Universidade de Vigo",
  "University of Murcia",
  "Gazi Üniversitesi",
  "Makerere University",
  "Robert Gordon University",
  "University of East London",
  "University of Salford",
  "Louisiana State University",
  "Michigan Technological University",
  "University of Alabama, Birmingham",
  "Worcester Polytechnic Institute",
  "Universidad Católica del Uruguay (UCU)",
  "Viet Nam National University Ho Chi Minh City (VNU-HCM)",
  "Instituto Tecnológico de Buenos Aires (ITBA)",
  "Universidad de San Andrés - UdeSA",
  "Universidad Nacional de Rosario (UNR)",
  "Universidad Torcuato Di Tella",
  "Baku State University",
  "University of Bahrain",
  "University of Regina",
  "Universidad de los Andes - Chile",
  "Universidad Técnica Federico Santa María (USM)",
  "Universidad de La Sabana",
  "University of South Bohemia",
  "Pontificia Universidad Católica del Ecuador (PUCE)",
  "Mansoura University",
  "Université Sorbonne Paris Nord",
  "Université Toulouse 1 Capitole",
  "Athens University of Economics and Business",
  "Indian Institute of Technology (IIT) Bhubaneswar",
  "Saveetha Institute of Medical and Technical Sciences (deemed to be university)",
  "Bina Nusantara University (BINUS)",
  "Ferdowsi University of Mashhad",
  "University of Parma",
  "Kumamoto University",
  "Niigata University",
  "Sophia University ",
  "Yarmouk University",
  "Almaty Technological University",
  "D. Serikbayev East Kazakhstan state technical university",
  "S.D. Asfendiyarov Kazakh National Medical University",
  "Riga Stradins University",
  "Universiti Pendidikan Sultan Idris (UPSI)",
  "Universidad Autónoma Metropolitana (UAM)",
  "The University of Lahore",
  "Financial University under the Government of the Russian Federation",
  "Southern Federal University",
  "Northern Borders University",
  "Rhodes University ",
  "University of the Western Cape",
  "Hallym University ",
  "Yeungnam University ",
  "Universidad de Córdoba - España",
  "Universidad de León",
  "University of Colombo",
  "Khon Kaen University",
  "King Mongkut's University of Technology Thonburi ",
  "Prince of Songkla University",
  "Queen Margaret University , Edinburgh",
  "University of Central Lancashire",
  "Auburn University",
  "Clark University",
  "Clemson University",
  "St. Louis University",
  "The University of Alabama",
  "University of Denver",
  "Agriculture University, Jodhpur",
  "Aliah University",
  "All India Institute of Medical Sciences (AIIMS)",
  "All India Institute of Medical Sciences Bhopal",
  "All India Institute of Medical Sciences Bhubaneswar",
  "All India Institute of Medical Sciences Jodhpur",
  "All India Institute of Medical Sciences Raipur",
  "All India Institute of Medical Sciences Rishikesh",
  "All India Institute of Medical Sciences, Bathinda",
  "All India Institute of Medical Sciences, Deoghar",
  "Ashoka University",
  "Amity University, Uttar Pradesh",
  "Apex Professional University",
  "Arunachal Pradesh State Council for Technical Education",
  "Arunachal University of Studies",
  "Arunodaya University",
  "Assam Down Town University",
  "Atal Bihari Vajpayee Indian Institute of Information Technology and Management, Gwalior",
  "B.S. Abdur Rahman Crescent Institute of Science and Technology",
  "Baba Ghulam Shah Badshah University",
  "Baba Mastnath University",
  "Babu Banarasi Das University",
  "Banasthali Vidyapith",
  "Berhampur University",
  "Bhakta Kavi Narsinh Mehta University",
  "Bhupendra Narayan Mandal University",
  "Bidhan Chandra Krishi Vishwavidyalaya",
  "Bihar Agricultural University",
  "Bihar Animal Sciences University",
  "Bilaspur Vishwavidyalaya",
  "Binod Bihar Mahto Koylanchal University",
  "Bir Tikendrajit University",
  "Birla Institute of Technology",
  "Board of Public Examinations, Kerala",
  "Board of School Education Uttarakhand",
  "Board of Secondary Education and Council of Higher Secondary Education",
  "Board of Secondary Education, Madhya Pradesh",
  "Board of Secondary Education, Rajasthan",
  "Board of Technical Education, Goa",
  "Board of Technical Education, Jodhpur",
  "Bodoland University",
  "C. U. Shah University",
  "C.V. Raman Global University",
  "Central Board of Secondary Education (CBSE)",
  "Central University of Jharkhand",
  "Central University of Karnataka",
  "Central University of Kerala",
  "Central University of Orissa",
  "Central University of South Bihar",
  "Centurion University of Technology and Management",
  "Chaitanya (Deemed to be University)",
  "Chanakya National Law University",
  "Chaudhary Bansi Lal University, Bhiwani",
  "Chaudhary Ranbir Singh University, Jind",
  "Chhattisgarh Swami Vivekanand Technical University",
  "CHRIST (Deemed to be University)",
  "Cluster University of Jammu",
  "Cochin University of Science and Technology",
  "Cooch Behar Panchanan Barma University",
  "Council for the Indian School Certificate Examinations (CISCE)",
  "Damodaram Sanjivayya National Law University",
  "Datta Meghe Institute of Medical Sciences",
  "Dayananda Sagar University",
  "Deendayal Upadhyaya Gorakhpur University",
  "Delhi Pharmaceutical Sciences and Research University",
  "Delhi Technological University",
  "Directorate of Technical Education and Training",
  "Dr B R Ambedkar National Institute of Technology Jalandhar",
  "Dr. A.P.J. Abdul Kalam University",
  "Dr. C.V. Raman University",
  "Dr. D. Y. Patil Vidyapeeth, Pune",
  "Dr. Shakuntala Misra National Rehabilitation University, Lucknow",
  "Dr. Shyama Prasad Mukherjee University",
  "Dr. Y.S.R University of Health Sciences",
  "Dravidian University",
  "Era University",
  "Eternal University",
  "Footwear Design & Development Institute",
  "Ganpat University",
  "GD Goenka University",
  "GIET University",
  "Goa Board of Secondary and Higher Secondary Education",
  "Goa University",
  "Government of Karnataka Department of Pre-University Education",
  "Govind Guru Tribal University, Banswara",
  "Gujarat Ayurved University, Jamnagar",
  "Gujarat Biotechnology University",
  "Gujarat Forensic Sciences University",
  "Gujarat National Law University",
  "Gujarat Secondary and Higher Secondary Education Board",
  "Gujarat Technological University",
  "Gujarat University of Transplantation Sciences",
  "Gulbarga University",
  "Guru Jambheshwar University of Science and Technology, Hisar",
  "Guru Nanak Dev University",
  "Guru Ravidas Ayurved University",
  "Gurugram University",
  "Gurukula Kangri Vishwavidyalaya",
  "Harcourt Butler Technical University",
  "Hemchand Yadav Vishwavidyalaya",
  "Hemwati Nandan Bahuguna Uttarakhand Medical Education University, Dehradun",
  "Hidayatullah National Law University",
  "Himachal Pradesh Board of School Education",
  "Himachal Pradesh National Law University",
  "Himachal Pradesh University",
  "Himalayan University",
  "Homoeopathy University",
  "IFTM University",
  "Indian Institute of Engineering Science and Technology Shibpur",
  "Indian Institute of Information Technology, Bhopal",
  "Indian Institute of Information Technology, Kalyani",
  "Indian Institute of Information Technology, Kota",
  "Indian Institute of Information Technology, Kottayam",
  "Indian Institute of Information Technology, Sonepat",
  "Indian Institute of Information Technology, Surat",
  "Indian Institute of Management, Ahmedabad",
  "Indian Institute of Management, Amritsar",
  "Indian Institute of Management, Bangalore",
  "Indian Institute of Management, Jammu",
  "Indian Institute of Management, Kozhikode",
  "Indian Institute of Management, Ranchi",
  "Indian Institute of Management, Sirmaur",
  "Indian Institute of Management, Tiruchirappalli",
  "Indian Institute of Science Education and Research (IISER) Bhopal ",
  "Indian Institute of Science Education and Research (IISER) Kolkata ",
  "Indian Institute of Science Education and Research (IISER)Thiruvananthapuram",
  "Indian Institute of Science Education and Research (IISER) Mohali",
  "Indian Institute of Science Education and Research (IISER) Pune",
  "Indian Institute of Science Education and Research (IISER) Berhampur",
  "Indian Institute of Science Education and Research (IISER) Tirupati",
  "Indian Institute of Technology (Indian School of Mines) Dhanbad",
  "Indian Institute of Technology Bhubaneswar",
  "Indian Institute of Technology Hyderabad",
  "Indian Institute of Technology Jodhpur",
  "Indian Institute of Technology Mandi",
  "Indian Institute of Technology, Delhi",
  "Indian Institute of Technology, Guwahati",
  "Indian Maritime University",
  "Indian School of Mines",
  "Indian Statistical Institute",
  "Indira Gandhi Delhi Technical University for Women",
  "Indira Gandhi Institute of Medical Sciences",
  "Indira Gandhi National Open University (IGNOU)",
  "Indira Gandhi National Tribal University, Amarkantak",
  "Indira Gandhi University",
  "Indira Kala Sangit Vishwavidyalaya, Khairagarh",
  "Indraprastha Institute of Information Technology",
  "Institute of Chartered Financial Analysts of India (ICFAI) University, Gangtok, Sikkim",
  "Institute of Chemical Technology",
  "International Institute for Population Sciences",
  "International Institute of Information Technology",
  "International Institute of Information Technology, Bangalore",
  "International Institute of Information Technology, Bhubaneswar",
  "Invertis University",
  "Islamic University of Science and Technology",
  "J.S. University, Shikohabad",
  "Jadavpur University",
  "Jagadguru Ramanandacharya Rajasthan Sanskrit University",
  "Jagadguru Rambhadracharya Divyanga University",
  "Jagan Nath University",
  "Jain University",
  "Jain Vishva Bharati Institute",
  "Jammu and Kashmir State Board of School Education",
  "Jananayak Chandrashekhar University",
  "Jawaharlal Institute of Postgraduate Medical Education and Research",
  "Jawaharlal Nehru Architecture and Fine Arts University",
  "Jawaharlal Nehru Krishi Vishwavidyalaya",
  "Jawaharlal Nehru Technological University Anantapur",
  "Jawaharlal Nehru Technological University Kakinada",
  "Jiwaji University, Gwalior",
  "Jodhpur National University",
  "Junagadh Agricultural University",
  "K.K. University",
  "Kadi Sarva Vishwavidyalaya",
  "Kakatiya University",
  "Kalinga University",
  "Kaloji Narayana Rao University of Health Sciences",
  "Kamdhenu University",
  "Karnatak University",
  "Karnataka Samskrit University",
  "Karnataka Secondary Education Examination Board",
  "Karnataka State Law University",
  "Karnataka State Open University",
  "Karnataka State Rural Development and PanchayatRaj University",
  "Kavayitri Bahinabai Chaudhari North Maharashtra University Jalgaon",
  "Kerala Agricultural University",
  "Kerala Kalamandalam (Deemed University for Art and Culture)",
  "Kerala University of Health Sciences",
  "Kolhan University",
  "Krantiguru Shyamji Krishna Verma Kachchh University",
  "Krishna University",
  "Kurukshetra University",
  "Kushabhau Thakre Patrakarita Avam Jansanchar Vishwavidyalaya",
  "Kuvempu University",
  "Lakshmibai National Institute of Physical Education",
  "Lalit Narayan Mithila University",
  "Lords University",
  "Lovely Professional University",
  "M.S. Ramaiah University of Applied Sciences",
  "Madhya Pradesh Bhoj Open University",
  "Madhya Pradesh Board of Technical Education, Bhopal",
  "Madhyanchal Professional University",
  "Madurai Kamaraj University",
  "Maharaja Agrasen University",
  "Maharaja Bir Bikram University",
  "Maharaja Chhatrasal Bundelkhand Vishwavidyalaya Chhatarpur",
  "Maharaja Ganga Singh University",
  "Maharaja Krishnakumarsinhji Bhavnagar University",
  "Maharaja Surajmal Brij University, Bharatpur",
  "Maharashtra Animal & Fishery Sciences University",
  "Maharashtra State Board of Technical Education",
  "Maharashtra University of Health Sciences",
  "Maharishi Markandeshwar University, Solan",
  "Maharishi University of Information Technology",
  "Maharishi University of Management and Technology",
  "Maharshi Dayanand University, Rohtak",
  "Mahatma Gandhi Central University, Motihari",
  "Mahatma Gandhi Chitrakoot Gramodaya Vishwavidyalaya",
  "Mahatma Gandhi University",
  "Mahatma Gandhi University, Kottayam",
  "Mahatma Gandhi University, Nalgonda",
  "Mahatma Jyotiba Phule Rohilkhand University, Bareilly",
  "Makhanlal Chaturvedi National University of Journalism",
  "Malaviya National Institute of Technology Jaipur",
  "Mangalore University",
  "Manipur Board of Secondary and Council of Higher Secondary Education",
  "Manipur University",
  "Manonmaniam Sundaranar University",
  "Maulana Azad National Urdu University",
  "Maulana Mazharul Haque Arabic and Persian University",
  "Meghalaya Board of School Education",
  "Meghalaya State Council for Technical Education",
  "Mizoram Board of School Education",
  "Mizoram University",
  "Mody University of Science and Technology",
  "Mohammad Ali Jauhar University",
  "Mohanlal Sukhadia University",
  "Mother Teresa Women's University",
  "Munger University",
  "Nagaland Board of School Education",
  "Nagaland University",
  "National Institute of Design, Madhya Pradesh",
  "National Institute of Open Schooling (NIOS)",
  "National Institute of Pharmaceutical Education and Research,Ahmedabad",
  "National Institute of Pharmaceutical Education and Research,Guwahati",
  "National Institute of Pharmaceutical Education and Research,Mohali",
  "National Institute of Pharmaceutical Education and Research,Raebareli",
  "National Institute of Technology Delhi",
  "National Institute of Technology Meghalaya",
  "National Institute of Technology, Durgapur",
  "National Institute of Technology, Karnataka",
  "National Institute of Technology, Kurukshetra",
  "National Institute of Technology, Nagaland",
  "National Institute of Technology, Raipur",
  "National Institute of Technology, Silchar",
  "National Institute of Technology, Tiruchirappalli",
  "National Institute of Technology, Warangal",
  "National Law Institute University, Bhopal",
  "National Law University Odisha",
  "National Law University, Jodhpur",
  "National University of Study and Research in Law, Ranchi",
  "Navsari Agricultural University",
  "Nehru Gram Bharati (Deemed to be University)",
  "Netaji Subhas Open University",
  "NIILM University",
  "Nilamber-Pitamber University",
  "Odisha State Open University",
  "P.P. Savani University",
  "Palamuru University",
  "Pandit Bhagwat Dayal Sharma University of Health Sciences,Rohtak",
  "Pandit Deendayal Upadhyaya Shekhawati University",
  "Pandit Lakhmi Chand State University of Performing and Visual Arts",
  "Pandit Shambunath Shukla University",
  "Patliputra University",
  "PDM University",
  "People's University",
  "Poornima University",
  "Pratap University",
  "Pravara Institute of Medical Sciences",
  "Presidency University",
  "Prof. Rajendra Singh (Rajju Bhaiya) University",
  "Pt. Ravishankar Shukla University, Raipur",
  "Punjab School Education Board",
  "Punjabi University",
  "Punyashlok Ahilyadevi Holkar Solapur University",
  "Purnea University",
  "Rabindranath Tagore University",
  "Radha Govind University",
  "Raj Rishi Bhartrihari Matsya University, Alwar",
  "Raja Mansingh Tomar Music and Arts University",
  "Rajasthan University of Health Sciences",
  "Rajasthan University of Veterinary and Animal Sciences,Bikaner",
  "Rajiv Gandhi Proudyogiki Vishwavidyalaya, Bhopal",
  "Rajiv Gandhi University of Health Sciences",
  "Rajiv Gandhi University of Knowledge Technologies",
  "Rajmata Vijayaraje Scindia Krishi Vishwa Vidyalaya",
  "Ranchi University			",
  "Rani Lakshmi Bai Central Agricultural University",
  "Rashtrasant Tukadoji Maharaj Nagpur University",
  "Rashtriya Sanskrit Sansthan",
  "Rayalaseema University",
  "Rayat-Bahra University",
  "RKDF University",
  "Sanchi University of Buddhist-Indic Studies",
  "Sant Gadge Baba Amravati University",
  "Santosh Deemed to be University",
  "Sardar Bhagwan Singh University",
  "Sardar Patel University",
  "Sardar Vallabhbhai National Institute of Technology Surat",
  "Satavahana University",
  "Sathyabama Institute of Science and Technology",
  "School of Planning and Architecture, Bhopal",
  "School of Planning and Architecture, Vijayawada",
  "Sharda University",
  "Sher-e-Kashmir University of Agricultural Sciences and Technology of Kashmir",
  "Sher-i-Kashmir Institute of Medical Sciences",
  "Shivaji University",
  "Shreemati Nathibai Damodar Thackersey Women's University, Mumbai",
  "Shri Govind Guru University",
  "Shri Guru Ram Rai University",
  "Shri Krishna University",
  "Shri Venkateshwara University",
  "Shri Vishwakarma Skill University",
  "Shridhar University",
  "Siddharth University",
  "Sido Kanhu Murmu University",
  "Sree Sankaracharya University of Sanskrit",
  "Sri Dev Suman Uttarakhand University",
  "Sri Padmavati Mahila Visvavidyalayam, Tirupati",
  "Sri Sai University",
  "Sri Satya Sai University of Technology and Medical Sciences",
  "Sri Venkateswara Institute of Medical Sciences",
  "Srimanta Sankaradeva University of Health Sciences",
  "SRM Institute of Science and Technology",
  "State Board of School Examinations, Tamil Nadu",
  "State Board of Technical Education and Training, Telangana",
  "SunRise University",
  "Sushant University",
  "SVKM's Narsee Monjee Institute of Management Studies",
  "Swami Ramanand Teerth Marathwada University",
  "Symbiosis International (Deemed University)",
  "Institution",
  "Tamil Nadu Dr. Ambedkar Law University",
  "Tamil Nadu Dr. M.G.R. Medical University",
  "Tamil Nadu Open University",
  "Tantia University",
  "Technical Examinations Board, Gujarat State, Gandhinagar",
  "Telangana State Board of Secondary Education : Hyderabad",
  "Telangana University",
  "Tezpur University",
  "The Birla Institute of Technology & Science",
  "The Global Open University, Nagaland",
  "The IIS University",
  "The Neotia University (TNU)",
  "The Punjab State Board of Technical Education and Industrial Training, Chandigarh",
  "The Sanskrit College and University",
  "The Tamil Nadu Dr. J Jayalalithaa Music and Fine Arts University",
  "The West Bengal University of Teachers' Training, Education",
  "Planning and Administration",
  "Thiruvalluvar University",
  "Tilka Manjhi Bhagalpur University",
  "Tripura University",
  "Tumkur University",
  "U.P. King George's University of Dental Science, Lucknow",
  "UKA Tarsadia University",
  "University of Agricultural Sciences, Dharwad",
  "University of Allahabad",
  "University of Calicut",
  "Raffles University",
  "University of Jammu",
  "Jawaharlal Nehru Technological University Hyderabad",
  "University of Kashmir",
  "University of Kerala",
  "University of Kota",
  "University of Madras",
  "University of Mumbai",
  "University of Patanjali",
  "University of Rajasthan",
  "University of Science and Technology, Meghalaya",
  "University of Technology and Management",
  "Utkal University",
  "Utkal University of Culture",
  "Uttar Pradesh Board of Technical Education",
  "Uttarakhand Open University",
  "Vasantrao Naik Marathwada Krishi Vidyapeeth, Parbhani",
  "Veer Bahadur Singh Purvanchal University",
  "Veer Narmad South Gujarat University",
  "Veer Surendra Sai Institute of Medical Sciences and Research",
  "Vinayaka Missions Sikkim University",
  "Vinoba Bhave University, Hazaribag",
  "Vishwakarma University",
  "West Bengal State Council of Technical and Vocational",
  "Education and Skill Development",
  "West Bengal University of Health Sciences",
  "William Carey University",
  "YBN University",
  "Yogi Vemana University",
];
