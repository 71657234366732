import React, { useState } from "react";
import Unicam from "../../assets/images/Uni_Cam_crop.jpg";
import supabase from "../../config/supabaseClient";
import PersonalInfo from "../../components/SignupFormSteps/PersonalInfo";
import ProfessionalBackground from "../../components/SignupFormSteps/ProfessionalBackground";
import MentorshipPreferences from "../../components/SignupFormSteps/MentorshipPreferences";
import { countries } from "../../data/Countries";
import { languages } from "../../data/Languages";
import { Degrees } from "../../data/Degrees";
import { Universitylist } from "../../data/Universitylist";
import { Courses } from "../../data/Courses";
import Resizer from "react-image-file-resizer";
import swal from "sweetalert2";

const initialFormData = {
  Name: "",
  email: "",
  nationality: "",
  countryOfResidence: "",
  languagesSpoken: [],
  About_yourself: "",
  profile_pic: null,
  education: [
    {
      region: "",
      school: "",
      degree: "",
      fieldOfStudy: "",
      topic: "",
      funding: "",
      funding_name: "",
      currentlyStudying: null,
      startDate: null,
      endDate: null,
      discipline: "",
    },
  ],
  experience: [
    {
      jobTitle: "",
      company: "",
      country: "",
      areaOfWork: "",
      currentPosition: null,
      workperiod: null,
    },
  ],
  websites: {
    linkedin: "",
    researchurl: "",
    website: "",
  },
  mentorshipPreferences: {
    menteeTypes: [],
    studyRegions: [],
    disciplines: [],
    formats: [],
    hoursPerMonth: "",
    Addtionalinformation: "",
  },
};

const MentorForm = () => {
  const [step, setStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mentorshipOptions = {
    menteeTypes: [
      { id: 1, name: "Students aspiring to pursue Bachelor's" },
      { id: 2, name: "Students aspiring to pursue Master's" },
      { id: 3, name: "Students aspiring to pursue PhD" },
      {
        id: 4,
        name: "Researchers aspiring to pursue postdoctoral/academic roles",
      },
      {
        id: 5,
        name: "Students or researchers aspiring to become professionals",
      },
    ],
    studyRegions: [
      { id: 1, name: "UK" },
      { id: 2, name: "US" },
      { id: 3, name: "Canada" },
      { id: 4, name: "Europe" },
      { id: 5, name: "Australia & NZ" },
      { id: 6, name: "Japan" },
      { id: 7, name: "South Korea" },
      { id: 8, name: "Singapore" },
    ],
    disciplines: [
      { id: 1, name: "STEM" },
      { id: 2, name: "Art & Humanities" },
      { id: 3, name: "Business & Management" },
      { id: 4, name: "Social sciences" },
      { id: 5, name: "Law" },
    ],
    formats: [
      { id: 1, name: "One-on-one mentoring" },
      { id: 2, name: "Group mentoring" },
      { id: 3, name: "In-person meetings (if applicable)" },
      { id: 4, name: "Workshop or seminar leader" },
    ],
  };

  // Step-specific validation function
  const validateStep = () => {
    let isValid = true;
    let message = "";

    switch (step) {
      case 1:
        if (formData.Name.trim() === "") {
          isValid = false;
          message = "Name is required.";
          break;
        }
        if (formData.email.trim() === "") {
          isValid = false;
          message = "Email is required.";
          break;
        }

        if (formData.languagesSpoken.length === 0) {
          isValid = false;
          message = "At least one language must be selected.";
          break;
        }
        break;

      case 2:
        if (formData.mentorshipPreferences.menteeTypes.length === 0) {
          isValid = false;
          message = "At least one mentee type must be selected.";
          break;
        }
        if (formData.mentorshipPreferences.studyRegions.length === 0) {
          isValid = false;
          message = "At least one study region must be selected.";
          break;
        }
        if (formData.mentorshipPreferences.disciplines.length === 0) {
          isValid = false;
          message = "At least one discipline must be selected.";
          break;
        }
        if (formData.mentorshipPreferences.hoursPerMonth.trim() === "") {
          isValid = false;
          message = "Hours per month commitment is required.";
          break;
        }
        break;

      /*case 3:
        if (formData.education[0].school.trim() === "") {
          isValid = false;
          message = "School name is required.";
          break;
        }
        if (formData.education[0].degree.trim() === "") {
          isValid = false;
          message = "Degree name is required.";
          break;
        }
        break;*/
      default:
        isValid = false;
        message = "Invalid step.";
    }

    if (!isValid) {
      alert(message);
    }

    return isValid;
  };

  const handleNext = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevious = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "profile_pic" && files && files[0]) {
      const file = files[0];

      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image file.");
        return;
      }

      setError(""); // Clear previous errors

      if (file.size > 100 * 1024) {
        // If file size > 100KB
        Resizer.imageFileResizer(
          file,
          400, // maxWidth
          400, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          (uri) => {
            // Update formData with resized image
            setFormData((prevData) => ({
              ...prevData,
              profile_pic: uri,
            }));
          },
          "base64" // outputType
        );
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            profile_pic: reader.result,
          }));
        };
        reader.readAsDataURL(file);
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleAddLanguage = () => {
    if (
      languages.includes(inputValue) &&
      !formData.languagesSpoken.includes(inputValue)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        languagesSpoken: [...prevData.languagesSpoken, inputValue],
      }));
      setInputValue("");
    }
  };

  const handleRemoveLanguage = (languageToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      languagesSpoken: prevData.languagesSpoken.filter(
        (language) => language !== languageToRemove
      ),
    }));
  };

  const handleArrayChange = (index, field, value, section) => {
    if (section === "websites") {
      setFormData((prevData) => ({
        ...prevData,
        websites: { ...prevData.websites, [field]: value },
      }));
    } else {
      const updatedArray = [...formData[section]];
      if (index !== null && index !== undefined) {
        updatedArray[index][field] = value;
      }
      setFormData((prevData) => ({ ...prevData, [section]: updatedArray }));
    }
  };

  const handleAddField = (section, emptyField) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], emptyField],
    }));
  };

  const handleDeleteField = (index, section) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: prevData[section].filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);
    // Comprehensive validation across all steps
    const allStepsValid =
      formData.Name.trim() !== "" &&
      formData.email.trim() !== "" &&
      formData.languagesSpoken.length > 0 &&
      formData.mentorshipPreferences.menteeTypes.length > 0 &&
      formData.mentorshipPreferences.studyRegions.length > 0 &&
      formData.mentorshipPreferences.disciplines.length > 0 &&
      formData.mentorshipPreferences.hoursPerMonth.trim() !== "";

    if (!allStepsValid) {
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please ensure all required fields are filled out before submitting.",
      });
      setIsSubmitting(false); // Reset loading state
      return;
    }

    try {
      // Example: Insert or update data using supabase
      // Replace the following with your actual submission logic

      // Check if user exists
      const { data: existingUser, error: fetchError } = await supabase
        .from("users")
        .select("user_id, type, name")
        .eq("email", formData.email)
        .single();

      if (fetchError) {
        if (fetchError.details === "The result contains 0 rows") {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No user found with this email.",
          });
        } else if (fetchError.status === 406) {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email does not match any existing user.",
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An unexpected error occurred while fetching user data.",
          });
        }
        return;
      }

      if (existingUser.type && existingUser.name) {
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: "The email you entered is already associated with a user.",
        });
        return;
      }

      const userId = existingUser.user_id;

      // Update user information
      const { error: userError } = await supabase
        .from("users")
        .update({
          name: formData.Name,
          nationality: formData.nationality,
          country_of_residence: formData.countryOfResidence,
          about_yourself: formData.About_yourself,
          profile_pic: formData.profile_pic,
          type: "Mentor",
        })
        .eq("email", formData.email);

      if (userError) {
        console.error("Error updating user:", userError);
        alert("An error occurred while updating user information.");
        return;
      }

      const languageInserts = formData.languagesSpoken.map((language) => ({
        user_id: userId,
        language_name: language,
      }));

      const { error: languageError } = await supabase
        .from("user_languages")
        .insert(languageInserts);

      if (languageError) {
        console.error("Error inserting languages:", languageError);
      }

      const educationInserts = formData.education.map((edu) => ({
        user_id: userId,
        school: edu.school,
        degree: edu.degree,
        field_of_study: edu.fieldOfStudy,
        start_date: edu.startDate,
        end_date: edu.endDate,
        region: edu.region,
        topic: edu.topic,
        funding: edu.funding,
        funding_name: edu.funding_name,
        currently_studying: edu.currentlyStudying || false,
      }));

      if (educationInserts[0].school !== "") {
        const { error: educationError } = await supabase
          .from("user_education")
          .insert(educationInserts);

        if (educationError) {
          console.error("Error inserting education records:", educationError);
        }
      }

      const experienceInserts = formData.experience.map((exp) => ({
        user_id: userId,
        job_title: exp.jobTitle,
        company: exp.company ,
        country: exp.country ,
        area_of_work: exp.areaOfWork ,
        current_position: exp.currentPosition ,
        work_period: exp.workperiod,
      }));

      if (experienceInserts[0].job_title !== "") {
        const { error: experienceError } = await supabase
          .from("user_experience")
          .insert(experienceInserts);

        if (experienceError) {
          console.error("Error inserting experience records:", experienceError);
        }
      }

      const websiteInsert = {
        user_id: userId,
        linkedin: formData.websites.linkedin,
        research_url: formData.websites.researchurl,
        website_url: formData.websites.website,
      };

      const { error: websiteError } = await supabase
        .from("user_website")
        .insert([websiteInsert]);

      if (websiteError) {
        console.error("Error inserting websites:", websiteError);
      }

      const menteeTypeInserts = formData.mentorshipPreferences.menteeTypes.map(
        (type) => ({
          user_id: userId,
          type_id: type,
        })
      );

      const { error: menteeTypeError } = await supabase
        .from("user_mentee_types")
        .insert(menteeTypeInserts);

      if (menteeTypeError) {
        console.error("Error inserting mentee types:", menteeTypeError);
      }

      const studyRegionInserts =
        formData.mentorshipPreferences.studyRegions.map((region) => ({
          user_id: userId,
          region_id: region,
        }));

      const { error: studyRegionError } = await supabase
        .from("user_preferred_region")
        .insert(studyRegionInserts);

      if (studyRegionError) {
        console.error("Error inserting study regions:", studyRegionError);
      }

      const disciplineInserts = formData.mentorshipPreferences.disciplines.map(
        (discipline) => ({
          user_id: userId,
          discipline_id: discipline,
        })
      );

      const { error: disciplineError } = await supabase
        .from("user_disciplines")
        .insert(disciplineInserts);

      if (disciplineError) {
        console.error("Error inserting disciplines:", disciplineError);
      }

      const formatInserts = formData.mentorshipPreferences.formats.map(
        (format) => ({
          user_id: userId,
          format_id: format,
        })
      );

      const { error: formatError } = await supabase
        .from("user_mentorship_formats")
        .insert(formatInserts);

      if (formatError) {
        console.error("Error inserting mentorship formats:", formatError);
      }

      const { error: timegivenError } = await supabase
        .from("user_mentorpreferences")
        .insert([
          {
            user_id: userId,
            hours_per_month: formData.mentorshipPreferences.hoursPerMonth,
            additional_information:
              formData.mentorshipPreferences.Addtionalinformation,
          },
        ]);

      if (timegivenError) {
        console.error("Error inserting mentor preferences:", timegivenError);
      }

      setFormData(initialFormData);
      setInputValue("");
      setStep(1);

      console.log("User and related data inserted successfully");
      swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data submitted successfully! Check your profile page to see your progress.",
      });
      // Navigate with a full page reload
      window.location.href = "/your_profile";
    } catch (error) {
      console.error("Error submitting form:", error);
      swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An unexpected error occurred. Please try again.",
      });
    } finally {
      setIsSubmitting(false); // Reset loading state
    }
  };

  return (
    <div className="pt-16 min-h-screen">
      <div
        className="bg-cover bg-center min-h-screen flex w-full items-center justify-center"
        style={{
          backgroundImage: `url(${Unicam})`,
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          backgroundBlendMode: "color",
        }}
      >
        <div className="max-w-3xl my-10">
          <form
            onSubmit={handleSubmit}
            className="p-6 rounded-lg shadow-lg"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              backgroundBlendMode: "color",
            }}
          >
            <h2 className="text-3xl font-bold mb-7 text-center text-secondary-100">
              {step === 1 && "Personal Information"}
              {step === 2 && "Mentorship Preferences"}
              {step === 3 && "Professional and Educational Background"}
            </h2>

            {step === 1 && (
              <PersonalInfo
                formData={formData}
                handleChange={handleChange}
                inputValue={inputValue}
                setInputValue={setInputValue}
                handleAddLanguage={handleAddLanguage}
                handleRemoveLanguage={handleRemoveLanguage}
                languages={languages}
                countries={countries}
              />
            )}
            {step === 2 && (
              <MentorshipPreferences
                formData={formData}
                setFormData={setFormData}
                mentorshipOptions={mentorshipOptions}
              />
            )}

            {step === 3 && (
              <ProfessionalBackground
                formData={formData}
                handleArrayChange={handleArrayChange}
                handleAddField={handleAddField}
                handleDeleteField={handleDeleteField}
                countries={countries}
                universityList={Universitylist}
                degrees={Degrees}
                courses={Courses}
                type="Mentor"
              />
            )}

            <div className="flex justify-between mt-6">
              {step > 1 && (
                <button
                  type="button" // Ensure type is 'button'
                  onClick={handlePrevious}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Previous
                </button>
              )}
              {step < 3 ? (
                <button
                  type="button" // Ensure type is 'button'
                  onClick={handleNext}
                  className="px-4 py-2 bg-secondary-100 text-white rounded hover:scale-105 transition duration-300"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={isSubmitting} // Disable button when submitting
                  className={`px-4 py-2 bg-tertiary-100 text-white rounded hover:bg-tertiary-300 transition duration-300 ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? "Submitting..." : "Submit"} {/* Feedback */}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MentorForm;
