import React, { useState } from "react";
import down_icon from "../../assets/images/ForResources/down_icon.svg";

function Guideline_mentor() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="pt-16">
      <div className=" mx-8 md:mx-20">
        <h1 className="text-3xl md:text-5xl text-primary text-center font-bold my-4 md:my-12">
          Mentor Guidelines
        </h1>
        <div className="flex flex-col w-full   md:flex-row items-center justify-center">
          <div className="md:w-1/5 flex items-center justify-center">
            <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mt-4 md:mt-0 md:mx-4">
              Mentor Onboarding
            </h2>
          </div>
          <div className="md:w-3/5">
            <h3 className="font-bold text-secondary-200 text-lg md:text-xl my-4">
              Setting up your profile on Unibridge
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Please make sure that your profile is updated with all the
              necessary details - field of study, universities studied in,
              current position etc. All these details will be covered in the
              mentor onboarding form. This is important as the mentees will be
              able to select which mentor to connect with based on details
              provided in the profile.
            </p>

            <div className="flex-1">
              <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
                Respond to Mentee Requests
              </h3>
              <p className="text-sm md:text-base text-gray-500">
                <b className="text-black">
                  Please respond to mentoring requests within 72 hours.
                </b>{" "}
                Choose Mentees based on their field of study and desired
                universities. Timely acceptance or decline of requests ensures
                efficient mentor allocation. If not addressed within 72 hours,
                the request will automatically be marked as expired.
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full  md:flex-row items-center justify-center bg-stone-100 rounded-lg my-10 py-10 px-2">
          <div className="md:w-1/5 flex items-center justify-center">
            <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mt-4 md:mx-4 md:mt-0">
              Guiding Mentees
            </h2>
          </div>
          <div className="md:w-3/5">
            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Initial Consultation & Goal Setting
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Understand the mentee’s profile in detail. Have a first check in
              with the mentee to understand his/her goals and career
              aspirations. Based on this understanding, set goals, and develop a
              timeline for the application process, considering application
              deadlines and exam (GRE/GMAT/IELTS/TOEFL) dates.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Academic & Profile Assessment
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Evaluate academic performance, identify strengths and weaknesses,
              and recommend suitable courses and programs at universities where
              the mentee has high chances of becoming successful.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Shortlisting Universities & Programs
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Research and compare universities, create a balanced list of
              "reach," "target," and "safe" options.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Application Process & Document Prep
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Mentor on writing strong SOPs, essays, gathering recommendation
              letters, and tracking submission deadlines. Also assist in writing
              and submitting applications for various scholarships that may be
              applicable. Review these documents and provide necessary feedback
              to increase the chances of acceptance.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Post-Application Guidance
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Help evaluate university offers, assist with visa applications,
              travel arrangements, and pre-departure preparation.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Guidance on Financial Planning
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Provide insights on costs, scholarships, financial aid, and assist
              with loan options if necessary.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Continuous Support & Encouragement
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Maintain regular check-ins, offer continuous mentoring support
              even after admission, and help with the transition to university
              life.
            </p>
          </div>
        </div>

        <h2 className="text-xl md:text-3xl text-secondary-200 font-bold text-center my-4 md:my-12">
          Essential Skills for Effective Mentorship
        </h2>
        <div className="flex  flex-col gap-6 w-full  md:flex-row items-center justify-center text-primary pb-10">
          <div className="flex flex-1 flex-col w-full bg-secondary-700 bg-opacity-50 rounded-lg justify-center items-center text-white">
            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Active Listening
            </h3>
            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Empathy and Patience
            </h3>
          </div>
          <div className="flex flex-1 flex-col w-full bg-secondary-700 bg-opacity-50 rounded-lg justify-center items-center text-white">
            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Effective Communication
            </h3>

            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Problem-Solving and Critical Thinking
            </h3>
          </div>
          <div className="flex flex-1 flex-col w-full bg-secondary-700 bg-opacity-50 rounded-lg justify-center items-center text-white">
            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Feedback
            </h3>

            <h3 className="text-lg md:text-xl text-center font-bold my-4">
              Adaptability
            </h3>
          </div>
        </div>

        <div className=" md:mx-20 my-8">
          <div className="">
            <h1 className="text-3xl md:text-5xl text-secondary-200 flex items-center justify-center text-center font-bold my-4 md:my-16">
              Conversation Checklist
            </h1>
            <div className="space-y-8">
              {[
                {
                  topic: "Introduction & Ice-breaking",
                  details: [
                    "Build rapport with the mentee",
                    "Share brief background of yourself (academic & professional)",
                    "Understand mentee’s current stage and aspirations",
                  ],
                  questions: [
                    "Tell me a bit about yourself and your journey so far.",
                    "What are your main goals in pursuing higher education abroad?",
                  ],
                },
                {
                  topic: "Academic & Career Goals",
                  details: [
                    "Discuss the mentee's long-term academic and career objectives",
                    "Align aspirations with global university options and courses",
                    "Identify fields of interest or specific programs they’re considering",
                  ],
                  questions: [
                    "What is your ultimate academic and career goal?",
                    "Are there specific courses or programs you are particularly interested in? Why?",
                  ],
                },
                {
                  topic: "Academic Background & Achievements",
                  details: [
                    "Explore the mentee’s educational background (grades, courses, extracurriculars)",
                    "Assess strengths, weaknesses, and unique selling points",
                    "Note any standardized tests taken or planned (SAT, GRE, TOEFL, etc.)",
                  ],
                  questions: [
                    "Can you share details about your academic background and any achievements?",
                    "Have you taken any standardized tests, or do you plan to?",
                  ],
                },
                {
                  topic: "University Preferences & Choices",
                  details: [
                    "Discuss potential regions, countries, or universities they are interested in",
                    "Consider the factors influencing their preferences (reputation, course content, location, budget)",
                    "Offer an overview of top universities and how they align with mentee's profile",
                  ],
                  questions: [
                    "What regions or countries are you considering for higher education?",
                    "Are there any universities on your list so far? Why those universities?",
                  ],
                },
                {
                  topic: "Application Process Overview",
                  details: [
                    "Provide a high-level overview of the application process",
                    "Explain the importance of timelines and different components (SOPs, LORs, resumes, essays)",
                    "Share insights on how to strengthen their application profile",
                  ],
                  questions: [
                    "Are you aware of the typical application process and its various components?",
                    "What part of the application process are you most concerned about?",
                  ],
                },
                {
                  topic: "Financial Planning & Scholarships",
                  details: [
                    "Discuss budgeting for tuition, accommodation, and other costs",
                    "Introduce available scholarships, grants, and funding options",
                    "Discuss how to balance financial constraints with university options",
                  ],
                  questions: [
                    "Have you considered the financial aspects of studying abroad?",
                    "Are you interested in exploring scholarship or funding options?",
                  ],
                },
                {
                  topic: "Timeline & Application Strategy",
                  details: [
                    "Plan a tentative timeline for their application journey (research, exams, essays, submission)",
                    "Discuss how to prioritize university applications based on deadlines",
                    "Set milestones for preparation",
                  ],
                  questions: [
                    "When do you plan to start your higher education journey?",
                    "Have you thought about any application timelines or milestones?",
                  ],
                },
                {
                  topic: "Personalized Guidance & Next Steps",
                  details: [
                    "Offer a tailored action plan based on the information gathered",
                    "Highlight resources available on UniBridge for research, preparation, and support",
                    "Schedule next steps and future sessions to track progress",
                  ],
                  questions: [
                    "What support do you think you'll need most during the process?",
                    "How would you like me to support you as your mentor?",
                  ],
                },
              ].map((item, index) => (
                <div key={index} className="space-y-2">
                  <div
                    className="bg-stone-200 rounded-lg p-4 w-full flex justify-between items-center"
                    onClick={() => toggleAnswer(index)}
                  >
                    <h2 className=" text-lg md:text-xl font-semibold text-secondary-200 cursor-pointer ">
                      {index + 1}. {item.topic}{" "}
                    </h2>
                    <img
                      src={down_icon}
                      alt="tick"
                      className="w-6 h-6 text-secondary-200"
                    />
                  </div>
                  {openIndex === index && (
                    <>
                      <h3 className="text-xl font-bold text-secondary-700 ml-2 md:ml-4">
                        Details to Cover:
                      </h3>
                      <ul className="list-disc list-inside text-gray-600 ml-2 md:ml-8">
                        {item.details.map((detail, idx) => (
                          <li key={idx}>{detail}</li>
                        ))}
                      </ul>
                      <h3 className="text-xl font-bold text-secondary-700 ml-2 md:ml-4">
                        Questions to Ask:
                      </h3>
                      <ul className="list-disc list-inside text-gray-600 ml-2 md:ml-8">
                        {item.questions.map((question, idx) => (
                          <li key={idx}>{question}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Guideline_mentor;
