import React from "react";
import career from "../../assets/images/ForAbout/career.svg";
import Connecting_mentor from "../../assets/images/ForAbout/Connecting_mentor.svg";
// import Stepping_hero from "../../assets/images/stepping_hero.svg";

// import Testimonials from "../../components/Testimonials";

function Whatweoffer() {
  return (
    <div className="pt-16 relative">
      <h1 className="text-3xl md:text-5xl text-secondary-100 bg-stone-100 font-bold text-center py-10">
        What We Offer
      </h1>
      <div className="min-h-72 text-secondary-100 bg-stone-100 flex flex-col md:flex-row items-center justify-center md:py-16 px-8 md:px-16 ">
        <p className="text-lg text-center md:text-3xl font-medium mb-8 px-10 md:px-32">
          We strive for a future where a person's passion and talent lead them
          to realize their dreams.
        </p>
        <img src={career} alt="career" className="md:w-1/2" />
      </div>

      <div className=" flex flex-col text-primary md:flex-row items-center justify-center md:py-16 px-8 md:px-16 ">
        <h1 className="text-3xl text-center md:text-5xl font-bold md:pr-20 py-10 md:w-1/2">
          The wider need for genuine mentorship
        </h1>
        <p className="text-lg  mb-8 px-4 md:px-32 md:w-1/2">
          The landscape of global education is rapidly evolving. As of 2023,
          approximately 6 million students have moved from their home countries
          to reputable international universities for higher studies. This
          significant figure reflects a growing trend of students seeking
          education abroad, fuelled by the desire for quality education,
          enhanced career prospects, access to advanced research opportunities,
          and a varied international experience.
        </p>
      </div>
      <div className="min-h-72 text-secondary-100 bg-stone-100 flex flex-col md:flex-row items-center justify-center md:py-16 px-8 md:px-16 ">
        <img
          src={Connecting_mentor}
          alt="Connecting_mentor"
          className="md:w-1/2 md:px-20 py-4"
        />
        <p className="text-lg text-center mb-8 px-10 md:px-20 leading-loose">
          <strong>At Unibridge,</strong>
          we believe that every student deserves access to personalized, expert
          guidance to navigate the complex world of international education. Our
          platform <strong> connects students with mentors </strong> who have
          faced the same challenges and know exactly what it takes to stand out.
        </p>
      </div>
      <div className="text-3xl font-bold text-center text-primary mx-auto my-12 md:text-6xl">
        Mentor Offerings
      </div>

      <div className="mx-8 my-10 md:mx-20 md:mt-10 ">
        <div className="md:flex w-full mb-20">
          <div className="w-full md:mr-12 grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Personalized Guidance
                  </h2>
                </div>
                <div className="flip-card-back bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Share similar experiences</li>
                    <li>Provide tailored advice</li>
                    <li>Help navigate challenges</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    University Selection
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Identify best-fit institutions</li>
                    <li>Evaluate program strengths</li>
                    <li>Assess campus culture</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Application Mastery
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Review and refine essays</li>
                    <li>Prepare for interviews</li>
                    <li>Strengthen overall application</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Scholarship Strategy
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Explore funding opportunities</li>
                    <li>Assist with scholarship applications</li>
                    <li>Advise on financial planning</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:flex w-full mb-10">
          <div className="w-full md:mr-12 grid grid-cols-1 md:grid-cols-2 gap-16">
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Visa Navigation
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Create visa application checklist</li>
                    <li>Offer insights on documentation</li>
                    <li>Share tips for successful applications</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Pre-Departure Prep
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Provide cultural insights</li>
                    <li>Offer packing and planning advice</li>
                    <li>Share tips for a smooth transition</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Campus Life advice
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Guide through initial settling-in</li>
                    <li>Advise on campus resources</li>
                    <li>Help build social networks</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="relative flip-card w-full h-36 rounded-xl shadow-lg shadow-gray-400">
              <div className="flip-card-inner w-full h-full">
                <div className="flip-card-front bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <h2 className="text-xl font-bold text-center">
                    Career Development
                  </h2>
                </div>
                <div className="flip-card-back  bg-zinc-300 bg-opacity-50 text-primary py-4 rounded-xl flex justify-center flex-col px-3 shadow-md">
                  <ul className="ml-5 list-disc">
                    <li>Offer industry insights</li>
                    <li>Guide internship searches</li>
                    <li>Assist with networking strategies</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whatweoffer;
