export const StudyStartOptions = [
  "Select",
  "Not decided",
  "2025 (January)",
  "2025 (Sept-Oct)",
  "2026 (January)",
  "2026 (Sept-Oct)",
  "2027 (January)",
  "2027 (Sept-Oct)",
  "2028 (January)",
  "2028 (Sept-Oct)",
];
