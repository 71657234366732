import React from "react";

function Callinstruction({ onClose }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 pt-28 pb-8">
      <div className="bg-white p-4 rounded shadow-lg md:w-1/2 overflow-y-auto h-[70vh]">
        <button
          onClick={onClose}
          className="bg-secondary-500 text-white px-4 py-2 rounded-md"
        >
          Close
        </button>
        <p className="text-center my-4 italic">
          We are working on integrating video call features, for the moment we
          will suggest to use google meet or zoom
        </p>
        <h2 className="text-lg font-semibold">Using Google Meet</h2>
        <ol className="list-decimal list-inside mt-2">
          <li>
            Go to{" "}
            <a
              href="https://meet.google.com/new"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Google Meet
            </a>
            .
          </li>
          <li>Click on "New Meeting" to generate a meeting link.</li>
          <li>Share the meeting link with the person you want to call.</li>
          <li>Click "Join" to start the call.</li>
        </ol>

        <h2 className="text-lg font-semibold mt-6">Using Zoom</h2>
        <ol className="list-decimal list-inside mt-2">
          <li>
            Go to{" "}
            <a
              href="https://zoom.us/join"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500"
            >
              Zoom
            </a>
            .
          </li>
          <li>Enter the meeting ID provided by your contact.</li>
          <li>Click "Join" to participate in the meeting.</li>
          <li>If prompted, enter the meeting password.</li>
        </ol>
      </div>
    </div>
  );
}

export default Callinstruction;
