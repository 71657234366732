import React, { useState } from "react";
import down_icon from "../../assets/images/ForResources/down_icon.svg";

function Guideline_mentee() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className="pt-16">
      <div className="mx-8 md:mx-20">
        <h1 className="text-3xl md:text-5xl text-primary text-center font-bold my-4 md:my-12">
          Mentee Guidelines
        </h1>
        <div className="flex flex-col w-full md:flex-row items-center justify-center">
          <div className="md:w-1/5 flex items-center justify-center">
            <h2 className="text-2xl md:text-3xl text-secondary-200 font-bold mt-4 md:mt-0 md:mx-4">
              Mentee Onboarding
            </h2>
          </div>
          <div className="md:w-3/5">
            <h3 className="font-bold text-secondary-200 text-lg md:text-xl my-4">
              Setting up your profile on Unibridge
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Please make sure that your profile is updated with all the
              necessary details - Field of Study, grades across school and
              university etc. All these details will be covered in the Mentee
              onboarding form. This is important as the mentors will be able to
              fully understand your areas of interest and your profile so that
              they can guide you in a holistic way.
            </p>

            <div className="flex-1">
              <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
                Reaching out to Mentors
              </h3>
              <p className="text-sm md:text-base text-gray-500">
                You will be able to message upto 3 Mentors at a time. Once a
                Mentor accepts your request, you can directly work with the
                mentor and setup sessions with them to start your mentorship
                journey. If mentors are unable to accept your request within 72
                hours, you will be able to reach out to additional mentors. In
                order to get the right mentor assigned, please make sure you
                select your mentor carefully based on your areas of interest and
                the courses/universities you want to pursue for higher education
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full md:flex-row items-center justify-center bg-stone-100 rounded-lg my-10 py-10 px-2">
          <div className="md:w-1/5 flex items-center justify-center">
            <h2 className="text-2xl md:text-3xl text-secondary-200 font-bold mt-4 md:mx-4 md:mt-0">
              Mentorship Journey
            </h2>
          </div>
          <div className="md:w-3/5">
            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Self-Assessment & Goal Identification
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Reflect on your academic interests, career goals, and motivations
              for studying abroad. Have a clear idea of the field and countries
              you're interested in.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Profile Review & Documentation
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Prepare and share your academic records, resume, and
              extracurricular achievements with your mentor for assessment and
              feedback.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Test Preparation Planning
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Discuss with your mentor about required standardized tests (e.g.,
              SAT, GRE, IELTS), understand the scoring requirements, and create
              a study plan to prepare effectively.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Research Universities & Programs
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Conduct initial research on universities, courses, and their
              requirements. Discuss with your mentor to create a balanced list
              of universities (reach, target, and safe).
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Financial Planning & Scholarship Search
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Work on understanding the costs involved (tuition, living
              expenses), and explore available scholarships and financial aid
              options with your mentor's guidance.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Application Writing & Review
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Begin drafting Statements of Purpose (SOPs), essays, and gather
              recommendation letters. Share drafts with your mentor for feedback
              and revisions to improve quality.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Complete & Submit Applications
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Track deadlines and ensure you meet all submission requirements.
              Double-check all documents before submission and follow the
              mentor's advice on managing application portals.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Interview Preparation & Skills
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Prepare for any university interviews or assessments by practicing
              with your mentor. Seek feedback on your communication skills,
              confidence, and approach to these interviews.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Follow Up & Track Application Status
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Regularly check the status of your applications and update your
              mentor. Seek advice on how to handle offers, rejections, or
              waitlists.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Post-Admission Support & Preparation
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Once offers are received, consult with your mentor to evaluate
              options and make an informed decision. Get guidance on visa
              applications, travel, accommodation, and transitioning to
              university life.
            </p>

            <h3 className="text-lg md:text-xl text-secondary-200 font-bold my-4">
              Stay Engaged & Connected
            </h3>
            <p className="text-sm md:text-base text-gray-500">
              Keep in regular touch with your mentor for ongoing support and
              advice, even after admission. Stay proactive in seeking help for
              your academic and social life adjustments.
            </p>
          </div>
        </div>

        {/* Conversation Checklist Section */}
        <div className=" md:mx-20 my-8">
          <div className="">
            <h1 className="text-3xl md:text-5xl text-secondary-200 flex items-center justify-center text-center font-bold my-4 md:my-16">
              Conversation Checklist
            </h1>
            <div className="space-y-8">
              {[
                {
                  topic: "Mentor Background & Expertise",
                  details: [
                    "Understand your mentor’s academic and professional journey",
                    "Know their experiences related to your field of interest",
                    "Identify their areas of expertise (programs, universities, admission processes)",
                  ],
                  questions: [
                    "Can you share a bit about your journey and how you navigated your higher education path?",
                    "What universities and courses are you most familiar with?",
                  ],
                },
                {
                  topic: "Higher Education Path & Industry Trends",
                  details: [
                    "Seek advice on the current landscape of your field of interest",
                    "Understand emerging opportunities or changes in global education trends",
                    "Ask for guidance on aligning your goals with industry demands",
                  ],
                  questions: [
                    "What are some trends you see in the field of [desired field of study]?",
                    "How can I align my education path with potential future opportunities?",
                  ],
                },
                {
                  topic: "Application Strategy & Key Components",
                  details: [
                    "Get insights on what makes a strong application",
                    "Ask for advice on structuring your Statement of Purpose (SOP), Letters of Recommendation (LORs), resume, and essays",
                    "Understand how to reflect your strengths and uniqueness effectively",
                  ],
                  questions: [
                    "What key elements should I focus on to make my application stand out?",
                    "How do I effectively communicate my passion and goals in the SOP or essays?",
                  ],
                },
                {
                  topic: "Academic & Extracurricular Profile Enhancement",
                  details: [
                    "Identify gaps or areas to improve in your academic or extracurricular profile",
                    "Ask for suggestions on relevant courses, projects, or experiences to add",
                    "Seek advice on how to demonstrate leadership, creativity, or specific skills in applications",
                  ],
                  questions: [
                    "How can I enhance my profile to increase my chances of admission?",
                    "Are there any courses, projects, or extracurricular activities you recommend?",
                  ],
                },
                {
                  topic: "Shortlisting Universities & Programs",
                  details: [
                    "Understand how to create a balanced university list (ambitious, safe, and target options)",
                    "Learn about factors to consider when choosing universities (course content, faculty, campus life)",
                    "Get recommendations on programs and universities that align with your goals",
                  ],
                  questions: [
                    "How do I shortlist universities and programs to apply to?",
                    "Are there any specific universities or programs you think would be a good fit for me?",
                  ],
                },
                {
                  topic: "Understanding Timelines & Deadlines",
                  details: [
                    "Ask for guidance on key milestones for standardized tests, applications, and deadlines",
                    "Understand when to start different components of your application",
                    "Learn about timelines for scholarships and funding opportunities",
                  ],
                  questions: [
                    "What timelines should I follow for the application process?",
                    "How do I balance my academic schedule with application prep?",
                  ],
                },
                {
                  topic: "Scholarships, Financial Aid & Budget Planning",
                  details: [
                    "Understand various funding options like scholarships, assistantships, and grants",
                    "Get guidance on the financial planning and budgeting process",
                    "Learn about part-time work options and ways to manage expenses",
                  ],
                  questions: [
                    "What scholarship or funding options are available for my field?",
                    "How can I financially plan for studying abroad, and what are the best ways to reduce costs?",
                  ],
                },
                {
                  topic: "Career Goals & Post-Graduation Opportunities",
                  details: [
                    "Align your course selection with your career aspirations",
                    "Learn about job opportunities, internships, and career services available for international students",
                    "Get tips on building a network and exploring opportunities while studying abroad",
                  ],
                  questions: [
                    "How can I choose courses that align with my career goals?",
                    "What steps can I take during my studies to improve job prospects after graduation?",
                  ],
                },
                {
                  topic: "Mentor Support & Communication Expectations",
                  details: [
                    "Clarify how your mentor will support you throughout the application journey",
                    "Set expectations on the frequency of communication and check-ins",
                    "Ask for recommendations on additional resources, materials, and study tips",
                  ],
                  questions: [
                    "How often can we have sessions, and what will the structure look like?",
                    "What resources or materials do you recommend for further preparation?",
                  ],
                },
                {
                  topic: "Defining Next Steps & Action Plan",
                  details: [
                    "Outline a roadmap for your application journey based on mentor’s feedback",
                    "Define immediate next steps and focus areas for improvement",
                    "Schedule follow-up sessions and discuss what to cover in the upcoming meetings",
                  ],
                  questions: [
                    "Based on our conversation, what should be my next steps?",
                    "Can we set a date for our next session to review my progress?",
                  ],
                },
              ].map((item, index) => (
                <div key={index} className="space-y-2">
                  <div
                    className="bg-stone-200 rounded-lg p-4 w-full flex justify-between items-center"
                    onClick={() => toggleAnswer(index)}
                  >
                    <h2 className=" text-lg md:text-xl font-semibold text-secondary-200 cursor-pointer ">
                      {index + 1}. {item.topic}{" "}
                    </h2>
                    <img
                      src={down_icon}
                      alt="tick"
                      className="w-6 h-6 text-secondary-200"
                    />
                  </div>
                  {openIndex === index && (
                    <>
                      <h3 className="text-xl font-bold text-secondary-700 ml-2 md:ml-4">
                        Details to Cover:
                      </h3>
                      <ul className="list-disc list-inside text-gray-600 ml-2 md:ml-8">
                        {item.details.map((detail, idx) => (
                          <li key={idx}>{detail}</li>
                        ))}
                      </ul>
                      <h3 className="text-xl font-bold text-secondary-700 ml-2 md:ml-4">
                        Questions to Ask:
                      </h3>
                      <ul className="list-disc list-inside text-gray-600 ml-2 md:ml-8">
                        {item.questions.map((question, idx) => (
                          <li key={idx}>{question}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End of Conversation Checklist Section */}
      </div>
    </div>
  );
}

export default Guideline_mentee;
