import React from "react";
import Tick from "../../assets/images/Tick.svg";
import PS_sample from "../../assets/images/ForResources/personal_statement.png";
function Personalstatement() {
  return (
    <div className=" pt-20  min-h-screen md:p-6">
      <div className="mx-auto p-8 text-neutral-600">
        <div className="md:min-h-72 md:my-16 bg-primary rounded-xl  shadow-lg shadow-slate-400 flex flex-col items-center justify-center relative">
          <div className="absolute inset-0 bg-gray-700 opacity-40 rounded-xl transform rotate-3 translate-y-2"></div>
          <h1 className="text-2xl mt-6 md:text-5xl font-bold text-white  text-center mb-6 relative z-10">
            Personal statement
          </h1>
          {/* Section 1: Introduction */}
          <section className="my-8 relative z-10">
            <p className="text-white px-8 text-center mx-auto md:text-lg font-medium">
              A personal statement is a written description of your
              achievements, interests, and goals, typically required as part of
              an application for universities or jobs. It provides an
              opportunity to showcase your personality, experiences, and
              motivations, allowing admissions committees or employers to
              understand who you are beyond your grades or resume.
            </p>
            <h2 className="md:text-xl text-stone-200 font-semibold text-center flex mx-4 my-8">
              <i>
                {" "}
                "Don't be tempted to copy or ask AI tools to write your personal
                statement, as universities and employers increasingly use AI
                tools to detect the authenticity and quality of submitted
                documents."{" "}
              </i>
            </h2>
          </section>
        </div>

        {/* <div className="border-t border-gray-300 opacity-40"></div> */}

        <h2 className="text-xl md:text-3xl text-secondary-200 font-bold mt-16 mb-2 md:mx-8">
          Guidelines for Writing a Personal Statement
        </h2>
        <div className="relative md:mx-8">
          <div className="absolute left-0 top-0 w-1 bg-secondary-200 h-full"></div>
          <div className="ml-6">
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Understand the Purpose:
              </h3>
              <p className="">
                Recognize that your personal statement is a chance to showcase
                your individuality, motivations, and suitability for the program
                or position. It should complement your application by providing
                context to your achievements.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Research the Audience:
              </h3>
              <p className="">
                Tailor your statement to the specific university or employer.
                Understand their values, mission, and what they seek in
                candidates. This allows you to align your experiences with their
                expectations.
              </p>

              <p className="mt-4 mx-2 text-center md:mx-12 text-black">
                <i>
                  When drafting your statement, it can be helpful to envision a
                  sceptical reader. Imagine needing to persuade someone who
                  believes you are applying for a master’s degree merely to
                  extend your time at university and delay making a career
                  choice. How will you convince them that your motivations are
                  more compelling and well-founded?
                </i>
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Craft a Strong Opening:
              </h3>
              <p className="">
                Begin with a hook that captures attention. This could be a
                personal story, a relevant quote, or a thought-provoking
                question. The first few sentences should make the reader want to
                continue.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Structure Your Statement:
              </h3>
              <p className="font-semibold text-lg text-secondary-100 mb-2">
                Organize your statement into clear sections:
              </p>

              <ul className="ml-2 md:ml-4">
                <li className="flex items-start mb-2">
                  <div className="text-primary font-bold "></div>
                  <span>
                    <strong className="text-secondary-200 mr-2">
                      {" "}
                      Introduction:
                    </strong>{" "}
                    Clearly state the program you are applying for, provide key
                    points highlighting why you are a strong candidate for this
                    course, and outline the following content structure.
                  </span>
                </li>
                <li className="mb-4">
                  <div className="text-secondary-200 font-bold mr-2 mb-4">
                    {" "}
                    Body:
                  </div>{" "}
                  <ul className="list-inside mb-4 ml-2 md:ml-12 space-y-3">
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        What motivates you to pursue this particular program at
                        this specific university?
                      </div>
                    </li>
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        In what ways does this program align with your current
                        skills and competencies?
                      </div>
                    </li>
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        What distinguishes you from other candidates? For
                        example, relevant work experience, involvement in
                        academic or professional societies, published or
                        presented research, honors, or awards.
                      </div>
                    </li>
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        Who or what have been the most significant influences on
                        your academic development, and for what reasons?
                      </div>
                    </li>
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        How does this program align with your long-term career
                        objectives?
                      </div>
                    </li>
                    <li className="flex items-start justify-start mb-2">
                      <img
                        src={Tick}
                        alt="Tick"
                        className="w-4 h-4 me-2 mt-1"
                      />
                      <div>
                        What additional contributions can you make to the
                        department or university community?
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="flex items-start mb-2">
                  <span>
                    <strong className="text-secondary-200 mr-2">
                      {" "}
                      Conclusion:
                    </strong>{" "}
                    Summarise your key arguments and restate your reasons for
                    wanting to pursue this specific course at this particular
                    university.
                  </span>
                </li>
              </ul>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Be Specific and Concrete:
              </h3>
              <p className="">
                Avoid vague statements. Provide specific examples of experiences
                that shaped your interests and skills. Use metrics or outcomes
                when possible to quantify your achievements.
              </p>
            </section>

            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Maintain a Professional Tone:
              </h3>
              <p className="">
                While it’s important to be authentic, maintain a professional
                tone throughout. Avoid colloquialisms and overly casual
                language. Your statement should reflect maturity and
                seriousness.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Edit and Revise:
              </h3>
              <p className="">
                After drafting your statement, take a break before revising.
                Look for clarity, coherence, and conciseness. Ensure each
                sentence serves a purpose and contributes to your overall
                narrative.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Seek Feedback:
              </h3>
              <p className="">
                Share your statement with trusted mentors, professors, or peers.
                Constructive feedback can provide new perspectives and help you
                identify areas for improvement.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Proofread Thoroughly:
              </h3>
              <p className="">
                Check for grammatical errors, typos, and formatting issues. A
                polished statement reflects your attention to detail and
                professionalism.
              </p>
            </section>
            <section className="relative my-8 mr-10 ">
              <div className="absolute -left-10 top-0  bg-primary rounded-lg shadow-lg shadow-stone-500 w-8 h-8"></div>
              <h3 className="text-lg md:text-2xl text-secondary-200 font-bold mb-2">
                Stay Within Word Limits:
              </h3>
              <p className="">
                Adhere to any specified word count or page limits. Brevity is
                key; make every word count while ensuring you convey your
                message effectively.
              </p>
            </section>
          </div>
        </div>
        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold mt-6 mb-2">
          Personal statement Sample
        </h2>

        <img src={PS_sample} alt="personal_statement_sample" className="px-6" />

        <h2 className="text-secondary-200 text-xl md:text-3xl font-bold mt-6 mb-2 md:mx-8">
          Additional Personal statement Links
        </h2>
        <ul className="list-disc list-inside text-sm md:text-lg md:mx-8">
          <li>
            <a
              href="https://www.kcl.ac.uk/study-at-kings/hyperlinks/personal-statement-examples.pdf"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              PS Example from Kings College London
            </a>
          </li>
          <li>
            <a
              href="https://www.exeter.ac.uk/media/universityofexeter/careersandemployability/pdfs/pgstudy/pg_application_personal_statement_example.pdf"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              PS Example from University of Exeter
            </a>
          </li>
          <li>
            <a
              href="https://www.york.ac.uk/media/study-new/undergraduate/applying/personal-statement-guide.pdf"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide to PS from University of York
            </a>
          </li>
          <li>
            <a
              href="https://youtu.be/7Li0XSffwGE"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Video Tips by York
            </a>
          </li>
          <li>
            <a
              href="https://admin.wadham.ox.ac.uk/media/documents/Personal_Statements_Open_Day.pdf"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guide to PS (Wadham College Oxford Uni)
            </a>
          </li>
          <li>
            <a
              href="https://www.ucas.com/sites/default/files/ucas-personal-statement-worksheet.pdf"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              UCAS Guide to PS
            </a>
          </li>
          <li>
            <a
              href="https://youtu.be/7Li0XSffwGE"
              className="text-blue-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              Video Tips by University of York
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Personalstatement;
