import React from "react";
import { MdOutlineEdit } from "react-icons/md";

function AboutSection({ about, onEdit }) {
  return (
    <div className="my-10 md:mx-10">
      <h3 className="font-bold text-primary bg-stone-100 p-4 rounded-xl min-h-48">
        ABOUT ME:
        <button onClick={onEdit} className="hover:bg-gray-200 ml-2">
          <MdOutlineEdit className="w-6 h-6" />
        </button>
        <div className="mt-4 text-base font-normal">{about}</div>
      </h3>
    </div>
  );
}

export default AboutSection;
