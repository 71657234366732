import React, { useEffect, useState } from "react";
import supabase from "../../config/supabaseClient";
import default_profile from "../../assets/images/default_profile.svg";
import EducationSection from "../../components/MentorProfile/EducationSection";
import ExperienceSection from "../../components/MentorProfile/ExperienceSection";
import WebsiteSection from "../../components/MentorProfile/WebsiteSection";
import AboutSection from "../../components/MentorProfile/AboutSection";
import EditEducationModal from "../../components/MentorProfile/EditEducationModal";
import EditExperienceModal from "../../components/MentorProfile/EditExperienceModal";
import EditWebsiteModal from "../../components/MentorProfile/EditWebsiteModal";
import EditAboutModal from "../../components/MentorProfile/EditAboutModal";
import Resizer from "react-image-file-resizer";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Link } from "react-router-dom";

function Your_profile() {
  const [mentor, setMentor] = useState(null);
  const [showSignupMessage, setShowSignupMessage] = useState(false);
  const [formData, setFormData] = useState({
    Name: "",
    about_yourself: "",
    profile_pic: "",
    education: [
      {
        region: "",
        school: "",
        degree: "",
        fieldOfStudy: "",
        topic: "",
        funding: "",
        funding_name: "",
        cgpa: "",
        currentlyStudying: false,
        startDate: "",
        endDate: "",
        discipline: "",
      },
    ],
    experience: [
      {
        jobTitle: "",
        company: "",
        country: "",
        areaOfWork: "",
        currentPosition: false,
        workperiod: "",
      },
    ],
    websites: {
      linkedin: "",
      researchurl: "",
      website: "",
    },
  });
  const [isEditing, setIsEditing] = useState({
    education: null, // null means not editing any entry
    experience: null,
    website: false,
    about: false,
    profilePic: false,
  });
  const [error, setError] = useState(""); // Error state
  const [profilePicFile, setProfilePicFile] = useState(null); // State for selected file
  const [loading, setLoading] = useState(true); // Loading state
  const [isEditingHeadline, setIsEditingHeadline] = useState(false); // Headline editing state
  const [headline, setHeadline] = useState(""); // Headline input state
  const [isEditingStatus, setIsEditingStatus] = useState(false); // Mentorship status editing state
  const [MentorStatus, setMentorStatus] = useState("Active");

  useEffect(() => {
    const fetchMentorProfile = async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();

        const mentorDataPromise = supabase
          .from("users")
          .select(
            "user_id, name, type, profile_pic, about_yourself, headline, mentorship_status"
          )
          .eq("email", user.email)
          .single();

        const educationDataPromise = supabase
          .from("user_education")
          .select("*")
          .eq("user_id", user.id);

        const experienceDataPromise = supabase
          .from("user_experience")
          .select("*")
          .eq("user_id", user.id);

        const disciplinesDataPromise = supabase
          .from("user_disciplines")
          .select("*")
          .eq("user_id", user.id);

        const websiteDataPromise = supabase
          .from("user_website")
          .select("*")
          .eq("user_id", user.id);

        const [
          { data: mentorData, error: mentorError },
          { data: educationData, error: educationError },
          { data: experienceData, error: experienceError },
          { data: disciplinesData, error: disciplinesError },
          { data: websiteData, error: websiteError },
        ] = await Promise.all([
          mentorDataPromise,
          educationDataPromise,
          experienceDataPromise,
          disciplinesDataPromise,
          websiteDataPromise,
        ]);

        if (mentorError) throw mentorError;
        if (educationError) throw educationError;
        if (experienceError) throw experienceError;
        if (disciplinesError) throw disciplinesError;
        if (websiteError) throw websiteError;

        setMentor({
          ...mentorData,
          userdata: mentorData,
          education: educationData,
          experience: experienceData,
          disciplines: disciplinesData,
          website: websiteData,
        });

        setFormData({
          Name: mentorData.name || "",
          about_yourself: mentorData.about_yourself || "",
          profile_pic: mentorData.profile_pic || "",
          mentorship_status: mentorData.mentorship_status || "",
          education: educationData.length
            ? educationData.map((edu) => ({
                education_id: edu.education_id || "",
                region: edu.region || "",
                school: edu.school || "",
                degree: edu.degree || "",
                fieldOfStudy: edu.field_of_study || "",
                topic: edu.topic || "",
                funding: edu.funding || "",
                funding_name: edu.funding_name || "",
                cgpa: edu.cgpa || null,
                currentlyStudying: edu.currently_studying || null,
                startDate: edu.start_date || null,
                endDate: edu.end_date || null,
                discipline: edu.discipline || "",
              }))
            : [
                {
                  region: "",
                  school: "",
                  degree: "",
                  fieldOfStudy: "",
                  topic: "",
                  funding: "",
                  funding_name: "",
                  cgpa: null,
                  currentlyStudying: false,
                  startDate: null,
                  endDate: null,
                  discipline: "",
                },
              ],
          experience: experienceData.length
            ? experienceData.map((exp) => ({
                experience_id: exp.experience_id || "",
                jobTitle: exp.job_title || "",
                company: exp.company || "",
                country: exp.country || "",
                areaOfWork: exp.area_of_work || "",
                currentPosition: exp.current_position || false,
                workperiod: exp.work_period || null,
              }))
            : [
                {
                  jobTitle: "",
                  company: "",
                  country: "",
                  areaOfWork: "",
                  currentPosition: false,
                  workperiod: null,
                },
              ],
          websites: {
            linkedin: websiteData[0]?.linkedin || "",
            researchurl: websiteData[0]?.researchurl || "",
            website: websiteData[0]?.website || "",
          },
        });

        setHeadline(mentorData.headline || "");

        // Check if profile is complete
        if (!mentorData.name || !mentorData.type) {
          setShowSignupMessage(true);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (name === "profile_pic" && files && files[0]) {
      const file = files[0];

      // Validate file type
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image file.");
        return;
      }

      setError(""); // Clear previous errors

      if (file.size > 100 * 1024) {
        // If file size > 100KB
        Resizer.imageFileResizer(
          file,
          400, // maxWidth
          400, // maxHeight
          "JPEG", // compressFormat
          80, // quality
          0, // rotation
          (uri) => {
            // Update formData with resized image
            setFormData((prevData) => ({
              ...prevData,
              profile_pic: uri,
            }));
            setProfilePicFile(uri);
          },
          "base64" // outputType
        );
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData((prevData) => ({
            ...prevData,
            profile_pic: reader.result,
          }));
          setProfilePicFile(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveProfilePic = async () => {
    if (!profilePicFile) {
      setError("No image selected.");
      return;
    }

    const { error: updateError } = await supabase
      .from("users")
      .update({ profile_pic: profilePicFile })
      .eq("user_id", mentor.user_id);

    if (updateError) {
      console.error("Error updating profile picture:", updateError);
      setError("Failed to update profile picture.");
    } else {
      setMentor({ ...mentor, profile_pic: profilePicFile });
      setIsEditing({ ...isEditing, profilePic: false });
      setError("");
    }
  };

  const handleHeadlineChange = async () => {
    const { error: updateError } = await supabase
      .from("users")
      .update({ headline })
      .eq("user_id", mentor.user_id);

    if (updateError) {
      console.error("Error updating headline:", updateError);
      setError("Failed to update headline.");
    } else {
      setMentor({ ...mentor, headline });
      setIsEditingHeadline(false);
      setError("");
    }
  };

  const handleMentorStatusChange = async () => {
    const { error: updateError } = await supabase
      .from("users")
      .update({ mentorship_status: MentorStatus })
      .eq("user_id", mentor.user_id);

    if (updateError) {
      console.error("Error updating mentorship status:", updateError);
      setError("Failed to update mentorship status.");
    } else {
      setMentor({ ...mentor, mentorship_status: MentorStatus });
      setIsEditingStatus(false);
      setError("");
    }
  };

  if (loading)
    return (
      <div className="flex flex-col justify-center items-center min-h-screen">
        <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-400 h-12 w-12"></div>
        <p className="text-primary mt-2">Loading...</p>
      </div>
    );

  if (!mentor) {
    return (
      <div className="pt-16 min-h-lvh">
        <div className="mx-6 md:mx-20">
          <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
            User Details
          </h1>
          <p>No mentor found.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-16 min-h-lvh">
      {/* Signup Message */}
      {showSignupMessage ? (
        <div
          className=" text-red-700 p-4 flex flex-col items-center justify-center my-auto"
          role="alert"
        >
          <p className="font-bold">Welcome to Unibridge</p>
          <p>
            Please click below to complete your profile to access all the
            features.
          </p>
          <Link to="/signupfilter">
            <button
              className="mt-2 bg-primary text-white px-4 py-2 rounded hover:bg-primary-dark"
              onClick={() => setShowSignupMessage(false)}
            >
              Create Profile
            </button>
          </Link>
        </div>
      ) : (
        <div className="mx-6 md:mx-20">
          <h1 className="text-xl md:text-3xl font-bold text-center text-primary my-6">
            {mentor.type} Details
          </h1>
          <div className="flex flex-col">
            <div className="mx-2 md:mx-10 flex flex-col md:flex-row">
              <div className="flex flex-col md:w-1/2 md:max-md">
                {/* Profile Picture Section */}
                <div className="relative group flex justify-center">
                  <img
                    src={mentor.profile_pic || default_profile}
                    alt={mentor.name}
                    className="object-cover rounded-full h-52 w-52  border-2"
                  />
                  <button
                    className="absolute bottom-2 right-20 p-2 bg-gray-800 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                    onClick={() => setIsEditing({ ...isEditing, profilePic: true })}
                  >
                    <MdOutlineEdit className="w-5 h-5" />
                  </button>
                </div>
                <h2 className="text-center font-bold text-primary mt-4">
                  {mentor.name}
                </h2>
                <div className="text-center">
                  <h2 className="font-bold text-primary mt-4 flex items-center justify-center">
                    {mentor.headline || "Add a headline"}
                    <button
                      onClick={() => setIsEditingHeadline(true)}
                      className="hover:bg-gray-200 p-1 rounded-full ml-2"
                    >
                      <MdOutlineEdit className="w-6 h-6" />
                    </button>
                  </h2>
                </div>
                {/* Headline Edit Section */}
                {isEditingHeadline && (
                  <div className="text-center mt-2">
                    <input
                      type="text"
                      value={headline}
                      placeholder="Enter your headline"
                      onChange={(e) => setHeadline(e.target.value)}
                      className="border rounded p-2 w-full max-w-md mx-auto"
                    />
                    <div className="mt-2">
                      <button
                        className="px-4 py-2 bg-primary text-white rounded mr-2"
                        onClick={handleHeadlineChange}
                      >
                        Save
                      </button>
                      <button
                        className="px-4 py-2 bg-gray-300 rounded"
                        onClick={() => setIsEditingHeadline(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {mentor.type === "Mentor" && (
                  <div className=" my-2 text-center flex items-center justify-center relative">
                    <span className="group">
                      <IoIosInformationCircleOutline className="w-4 h-4 mr-2 cursor-pointer" />

                      <span className="absolute top-8 left-10 p-2 text-sm bg-white text-gray-800 rounded shadow-lg opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        <p>
                          <strong>Active:</strong> I am available for mentoring.
                        </p>
                        <p>
                          <strong>Busy:</strong> I may have limited
                          availability.
                        </p>
                        <p>
                          <strong>Inactive:</strong> I am not available for
                          mentoring.
                        </p>
                      </span>
                    </span>

                    <h2 className="font-bold text-primary flex items-center justify-center">
                      Mentorship Status:{" "}
                      <span className="font-normal text-rose-400 ml-2">
                        {mentor.mentorship_status || "Active"}
                      </span>
                      <button
                        onClick={() => setIsEditingStatus(true)}
                        className="hover:bg-gray-200 p-1 rounded-full ml-2"
                      >
                        <MdOutlineEdit className="w-6 h-6" />
                      </button>
                    </h2>
                  </div>
                )}
                {/* Mentorship Status Edit Section */}
                {isEditingStatus && (
                  <div className="text-center mt-2">
                    <select
                      value={MentorStatus}
                      onChange={(e) => setMentorStatus(e.target.value)}
                      className="border rounded p-2 w-full max-w-md mx-auto"
                    >
                      <option value="Active">Active</option>
                      <option value="Busy">Busy</option>
                      <option value="Inactive">Inactive</option>
                    </select>

                    <div className="mt-2">
                      <button
                        className="px-4 py-2 bg-primary text-white rounded mr-2"
                        onClick={handleMentorStatusChange}
                      >
                        Save
                      </button>
                      <button
                        className="px-4 py-2 bg-gray-300 rounded"
                        onClick={() => setIsEditingStatus(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>

              <div className="md:mx-6 w-full">
                <EducationSection
                  education={mentor.education}
                  type={mentor.type}
                  onEdit={() => setIsEditing({ ...isEditing, education: true })}
                  onAdd={() => setIsEditing({ ...isEditing, education: true })}
                />

                <ExperienceSection
                  experience={mentor.experience}
                  onEdit={() =>
                    setIsEditing({ ...isEditing, experience: true })
                  }
                  onAdd={() => setIsEditing({ ...isEditing, experience: true })}
                />

                <WebsiteSection
                  website={mentor.website}
                  onEdit={() => setIsEditing({ ...isEditing, website: true })}
                />
              </div>
            </div>

            <AboutSection
              about={mentor.about_yourself}
              onEdit={() => setIsEditing({ ...isEditing, about: true })}
            />

            {isEditing.education && (
              <EditEducationModal
                userId={mentor.user_id}
                type={mentor.type}
                formData={formData}
                setFormData={setFormData}
                onClose={() => setIsEditing({ ...isEditing, education: null })}
                onSave={() => setIsEditing({ ...isEditing, education: null })}
              />
            )}
            {isEditing.experience && (
              <EditExperienceModal
                userId={mentor.user_id}
                formData={formData}
                setFormData={setFormData}
                onClose={() => setIsEditing({ ...isEditing, experience: null })}
                onSave={() => setIsEditing({ ...isEditing, experience: null })}
              />
            )}
            {isEditing.website && (
              <EditWebsiteModal
                userId={mentor.user_id}
                websites={mentor.website[0]}
                formData={formData}
                setFormData={setFormData}
                onClose={() => setIsEditing({ ...isEditing, website: false })}
                onSave={() => setIsEditing({ ...isEditing, website: false })}
              />
            )}
            {isEditing.about && (
              <EditAboutModal
                userId={mentor.user_id}
                formData={formData}
                setFormData={setFormData}
                handleChange={handleChange}
                onClose={() => setIsEditing({ ...isEditing, about: false })}
                onSave={() => setIsEditing({ ...isEditing, about: false })}
              />
            )}

            {/* Edit Profile Picture Modal */}
            {isEditing.profilePic && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/3">
                  <h2 className="text-2xl mb-4">Change Profile Picture</h2>
                  {error && <p className="text-red-500 mb-2">{error}</p>}
                  <input
                    type="file"
                    name="profile_pic"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="mb-4"
                  />
                  {formData.profile_pic && (
                    <img
                      src={formData.profile_pic}
                      alt="Preview"
                      className="object-contain rounded-xl max-h-40 max-w-40 mb-4"
                    />
                  )}
                  <div className="flex justify-end">
                    <button
                      className="px-4 py-2 bg-gray-300 rounded mr-2"
                      onClick={() =>
                        setIsEditing({ ...isEditing, profilePic: false })
                      }
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-primary text-white rounded"
                      onClick={handleSaveProfilePic}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Your_profile;
